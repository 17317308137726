import React from 'react';
import { Row, Col, Empty } from 'antd';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg';
import AddAssignmentButton from './AddAssignmentButton';

interface IActiveAssignmentsRow {
  loading: boolean;
  assignmentData: any[];
  courses: any[];
}

const GActiveAssignmentsRow: React.FC<IActiveAssignmentsRow> = ({ loading, assignmentData, courses }) => {
  const renderAssignments = () => {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (assignmentData.length === 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {assignmentData.map((assignment) => {
            const course = courses.find((c) => c.id === assignment.courseId);
            const className = course ? course.name : '';
            const classColor = course ? course.color : '';
            const dueDate = assignment.dueDate 
              ? new Date(
                  assignment.dueDate.year,
                  assignment.dueDate.month - 1,
                  assignment.dueDate.day
                ).getTime()
              : null;

            return (
              <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={assignment.id}>
                <Item
                  date={formatDateTime(assignment.creationTime)}
                  assignmentName={assignment.title}
                  assignmentId={assignment.id}
                  courseId={assignment.courseId}
                  timeLeft={dueDate}
                  timeWarning={dueDate ? Date.now() > dueDate : false}
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" />}
                  className={className}
                  classColor={classColor}
                  workType={assignment.workType}
                  description={assignment.description} // Pass description to Item component
                />
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h3 style={{ fontWeight: 700, fontSize: '1.4em' }}>Active Assignments</h3>
        <AddAssignmentButton />
      </div>
      {renderAssignments()}
    </div>
  );
};

export default GActiveAssignmentsRow;
