import { Row } from 'antd';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import CommentActions from '../../../shared/CommentActions';
import CommentBox from '../../../shared/CommentBox';
import Sider from '../../../shared/Sider';
import { IReflectionComment } from '../../../types/investigation';
import { formatCommentText } from '../../../utils/investigation';

interface ICommentsSider {
  comments: IReflectionComment[];
  investigationId: string;
  loading?: boolean;
  highlightedCommentId?: string;
  submissionVersion: number;
}

const CommentsSider = (props: ICommentsSider) => {
  const history = useHistory();
  const { search } = useLocation();
  const { isStudent } = useAuth();
  const { comments, loading, highlightedCommentId, submissionVersion } = props;
  const [stateComments, setStateComments] = React.useState<IReflectionComment[]>(comments);

  const backToInvestigation = useCallback(() => {
    if (isStudent) history.goBack();
    const backUrl = `/teacher-dashboard/investigation-details/${props.investigationId}${search}`;

    history.push(backUrl);
  }, [history, props.investigationId, search, isStudent]);

  return (
    <Sider loading={loading} title="Investigation Comments">
      <Row justify="center">
        {stateComments.map((comment) => (
          <CommentBox
            data-cy="components-comments-sider-comment-box"
            highlighted={comment.id === highlightedCommentId}
            key={comment.id}
            actions={
              <CommentActions
                submissionVersion={submissionVersion}
                commentId={comment.id}
                showComment={false}
                showLike={false}
                flags={comment.flags}
                onFlag={() => {
                  setStateComments(
                    stateComments.map((c) =>
                      c.id === comment.id
                        ? {
                            ...c,
                            flags: {
                              flagged: !c.flags.flagged,
                              count: c.flags.flagged ? c.flags.count - 1 : c.flags.count + 1,
                            },
                          }
                        : c,
                    ),
                  );
                }}
                compact
              />
            }
            style={{ marginBottom: 15 }}
            author={`Student`}
          >
            {formatCommentText({
              text: comment.text,
              timestamp: comment.coordinates?.ts,
            })}
          </CommentBox>
        ))}
        <div style={{ height: 30, width: '100%' }} />
        <Button
          text="Back to Investigation"
          onClick={backToInvestigation}
          data-cy="components-comments-sider-button-back"
        />
        <div style={{ height: 64, width: '100%' }} />
      </Row>
    </Sider>
  );
};

export default CommentsSider;
