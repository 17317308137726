import { Col, Row } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { CreateUserFlowStep } from '../../types/user';
import UserType from './UserType';
import AdiAdmin from './GenericUser';
import FreelanceTeacher from './FreelanceTeacher';
import OrganizationAdmin from './OrganizationAdmin';
import BulkGenericUser from './BulkGenericUser';
import { useAuth } from '../../hooks/useAuth';
import Facilitator from './Facilitator';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const steps: CreateUserFlowStep[] = [
  {
    title: 'Add User',
    step: 'selectUserType',
    infoLine1: '',
    infoLine2: '',
  },
  {
    title: 'Add ADI Super Admin User',
    step: 'adiSuperAdmin',
    infoLine1: 'Create a new ADI Super Admin to manage users',
    infoLine2: 'and critical data',
  },
  {
    title: 'Add ADI Admin User',
    step: 'adiAdmin',
    infoLine1: 'Create a new ADI Admin to support',
    infoLine2: 'Super Admin common tasks ',
  },
  {
    title: 'Create a New Organization',
    step: 'orgAdmin',
    infoLine1: 'Create a new Organization to manage',
    infoLine2: 'teachers for Schools or a District',
  },
  {
    title: 'Add Freelance Teacher',
    step: 'freelanceTeacher',
    infoLine1: 'Create a new Freelance Teacher who will have',
    infoLine2: 'just one subscription for a limited time',
  },
  {
    title: 'Add Teacher',
    step: 'teacher',
    infoLine1: 'Create a new teacher for your organization',
    infoLine2: '',
  },
  {
    title: 'Add Writer',
    step: 'writer',
    infoLine1: 'Create a new writer to contribute ',
    infoLine2: 'to your custom investigation library',
  },
  {
    title: 'Add Facilitator',
    step: 'facilitator',
    infoLine1: 'Create a new facilitator to present ',
    infoLine2: 'ADI Workshops',
  },
  {
    title: 'Add Google Teacher',
    step: 'google_teacher',
    infoLine1: 'Create a new google teacher to contribute ',
    infoLine2: 'to your custom google classroom',
  },
];


const AddUser: React.FC<Props> = (props) => {
  const { visible, setVisible } = props;
  const [step, setStep] = useState(steps[0]);
  const { user } = useAuth();
  console.log("SDas", visible);
  const onGoBack = useCallback(() => {
    setStep(steps[0]);
  }, []);

  const titleInfo = useMemo(
    () => (
      <Row justify="center">
        {step !== steps[0] && (
          <Col span={1}>
            <S.BackButton data-cy="shared-adduser-goback-button" size={24} onClick={onGoBack} />
          </Col>
        )}
        <Col span={23}>
          <S.Title>{step.title}</S.Title>
        </Col>
        {step === steps[0] && (
          <Col span={24}>
            <S.Info>{step.infoLine1}</S.Info>
            <S.Info>{step.infoLine2}</S.Info>
          </Col>
        )}
      </Row>
    ),
    [step, onGoBack],
  );

  const onCloseModal = useCallback(() => {
    setVisible(false);
    setStep(steps[0]);
  }, [setVisible]);

  const renderStep = () => {
    switch (step.step) {
      case 'selectUserType':
        return <UserType steps={steps} setStep={setStep} />;
      case 'adiSuperAdmin':
        return (
          <AdiAdmin setVisible={setVisible} role="adi_super_admin" columnWith={21} buttonTitle="ADI Super Admin" />
        );
      case 'adiAdmin':
        return <AdiAdmin setVisible={setVisible} role="adi_admin" columnWith={21} buttonTitle="ADI Admin" />;
      case 'orgAdmin':
        return <OrganizationAdmin setVisible={setVisible} />;
      case 'facilitator':
        return <Facilitator setVisible={setVisible} />;
      case 'freelanceTeacher':
        return <FreelanceTeacher setVisible={setVisible} />;

      default:
        return (
          <BulkGenericUser
            role={step.step}
            buttonTitle={step.step.toUpperCase()}
            organizationId={user.subscription?.organizationId}
          />
        );
    }
  };
  return (
    <S.CModal
      data-cy="shared-adduser-container-modal"
      visible={visible}
      maskClosable={false}
      title={titleInfo}
      onCancel={onCloseModal}
      width={800}
      footer={null}
    >
      {renderStep()}
    </S.CModal>
  );
};

export default React.memo(AddUser);
