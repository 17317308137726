import { Row } from 'antd';
import styled from 'styled-components';
import realStoriesBackground from '../../assets/landing/real-stories-background.png';

export const HomePageContentContainer = styled(Row)`
  margin-top: 80px;
  /* height: calc(100vh - 80px); */
  overflow: auto;
  background: #faf8f9;
  font-family: Roboto;
  padding-bottom: 48px;
`;

export const FeatureImage = styled.img`
  height: 64px;
  margin-bottom: 8px;
`;

export const LandingImageContainer = styled.div`
  height: 100%;
  float: right;
  position: relative;
  display: inline-block;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }

  img {
    height: 100%;

    @media (max-width: 768px) {
      position: absolute;
      right: -90px;
    }

    @media (max-width: 1024px) and (min-width: 769px) {
      margin-right: -120px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(90deg, #faf8f9 0%, #faf8f9 2%, #faf8f900 8%, #faf8f900 100%);
  }
`;

export const LandingGradientContainer = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(110deg, #faf8f9 0%, #faf8f9 40%, #faf8f9cc 50%, #faf8f900 60%, #faf8f900 100%);
  position: absolute;
  padding: 0px 64px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    background: linear-gradient(100deg, #faf8f9 0%, #faf8f9 55%, #faf8f9cc 65%, #faf8f900 75%, #faf8f900 100%);
  }

  @media (max-width: 1200px) {
    padding: 0px 32px;
  }

  .landing-gradient-text {
    width: 60%;
    max-width: 500px;

    b {
      font-weight: 500;
    }

    @media (max-width: 768px) {
      width: 75%;
    }

    & > h2 {
      font-size: 1rem;
      font-weight: 500;
      color: #4367E9;
      margin-bottom: 0px;
      letter-spacing: 0.200rem;

      @media (max-width: 768px) {
        font-size: 0.9rem;
        letter-spacing: 0.24rem;
      }
    }

    & > h1 {
      font-size: 2.75rem;
      font-weight: 500;
      color: #000000;
      margin-bottom: 0px;
      line-height: 1.2;
      font-family: Roboto;

      @media (max-width: 768px) {
        font-size: 1.7rem;
        line-height: 1.1;
      }
    }

    & > p {
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.4;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }

    .landing-gradient-text-button-container {
      display: flex;

      @media (max-width: 768px) {
        #homepage-login-button {
          display: none;
        }
      }
    }
  }
`;

export const LandingContainer = styled.div`
  width: 100%;
  height: 80vh;
  position: relative;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

export const FeaturedContainer = styled.div`
  width: 100%;
  padding: 48px 64px;

  @media (max-width: 1200px) {
    padding: 48px 32px;
  }
`;

export const FeaturedItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-width: 250px;
  text-align: center;
  margin: auto;

  h3 {
    margin-bottom: 0;
    font-size:1rem
  }

  p {
    font-size: 0.7rem;
    line-height: 1.3;
    font-weight: 350;
  }

  h1 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0;
    line-height: 1.1;
  }
`;

export const ScheduleDemoContainer = styled.div`
  width: 100%;
  padding: 32px 64px;

  @media (max-width: 1200px) {
    padding: 32px;
  }

  .video-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      position: absolute;
      left: -120px;
      max-height: 480px;
      width: auto;

      @media (max-width: 768px) {
        max-height: 380px;
        max-width: 100%;
      }
    }

    & > div {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 100%;
      width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 600px;
        max-height: 325px;
        top: calc((100% - 325px) / 2);
        z-index: 1;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.4));

        @media (max-width: 592px) {
          top: 0;
        }
      }
    }
  }

  .schedule-demo-text {
    width: 100%;
    height: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    & > h2 {
      font-size: 1rem;
      font-weight: 500;
      
      color: #aaaaaa;
      margin-bottom: 0px;
      letter-spacing: 0.200rem;
    }

    & > h1 {
      font-size: 1.5rem;
      font-weight: 500;
      color: #000000;
      margin-bottom: 0px;
      line-height: 1.3;
      font-family: Roboto;
    }

    & > p {
      font-weight: 300;
      font-size: 1rem;
    }
  }
`;

export const RealStoriesContainer = styled.div`
  box-shadow: inset 0px 50px 40px #faf8f9, inset 0px -50px 40px #faf8f9;
  background-image: url(${realStoriesBackground});
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 32px 64px;

  @media (max-width: 1200px) {
    padding: 32px;
  }

  @media (min-width: 992px) and (max-width: 1600px) {
    margin-top: 64px;
  }

  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h3 {
    font-size: 1rem;
    font-weight: 500;

    color: #aaaaaa;
    letter-spacing: 0.200rem;
    text-align: center;
  }

  & > h2 {
    font-size: 2rem;
    font-weight: 500;
    font-family: Roboto;
  }
`;

export const RealStoryCardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const RealStoryCard = styled.div<{ personaBgSrc: string }>`
  margin-top: 8px;
  margin-bottom: 32px;
  width: 90%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  background: #faf8f9;
  box-shadow: -8px 10px 22px rgba(0, 0, 0, 0.25);
  border-radius: 42px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 576px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .person-container {
    min-height: 148px;
    min-width: 148px;
    height: 148px;
    width: 148px;
    background: url(${(props) => props.personaBgSrc}) center bottom no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 75%;
      width: 75%;
      border-radius: 20px;
    }
  }

  .opening-quote {
    margin-bottom: auto;
    margin-top: 16px;
    margin-right: 8px;
    @media (max-width: 576px) {
      margin-right: auto;
    }
  }

  .closing-quote {
    margin-top: auto;
    margin-bottom: 16px;
    margin-left: 8px;
    @media (max-width: 576px) {
      margin-left: auto;
    }
  }

  p {
    font-size: 1.1rem;
    font-weight: 300;
    color: #000000;
    line-height: 1.2;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  h2 {
    font-size: 1.2rem;
    margin-bottom: 0px;
    line-height: 1.2;
    margin-bottom: 8px;
  }
`;

export const SuppliesContainer = styled.div`
  width: 100%;
  padding: 32px 64px;
  padding-right: 0;
  padding-top: 0;

  @media (max-width: 1200px) {
    padding: 32px;
    padding-right: 0;
    padding-top: 0;
  }

  img {
    float: right;
    width: 100%;
    max-width: 620px;
  }

  h3 {
    font-size: 1rem;
    font-weight: 500;
  
    color: #aaaaaa;
    letter-spacing: 0.200rem;
    margin-bottom: 0px;
  }

  h1 {
    font-size: 1.75rem;
    font-weight: 500;
    color: #000000;
    margin-bottom: 0px;
    font-family: Roboto;
  }

  p {
    font-weight: 300;
    font-size: 1rem;
    max-width: 400px;
  }
`;

export const ContactUsContainer = styled.div`
  height: 140px;
  background: rgba(67, 103, 233, 0.1);
  width: 100%;
  display: flex;
  flex-direction:column;
 
  justify-content: center;
  align-items: center;
  margin:auto;

  @media (max-width: 576px) {
    flex-direction: column;
    text-align: center;
    padding: 16px 0;
  }

  h2 {
    color: black;
   
    letter-spacing: 0.10rem;
    margin-bottom: 22px;
    font-size: 1.25rem;

    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }
`;

export const LinksContainer = styled.div`
  width: 100%;
  padding: 32px 64px;
  font-family: Roboto;

  @media (max-width: 1200px) {
    padding: 32px;
  }

  .ant-col {
    display: flex;
    flex-direction: column;

    h4 {
      font-style: italic;
      font-family: Roboto;
    }

    h3 {
      font-size: 1.3rem;
      font-weight: 500;
      font-family: Roboto;
    }
  }

  a {
    color: #000000;
    font-weight: 500;
    line-height: 1.8;
  }

  .logo-image {
    width: 100%;
    max-width: 240px;
  }

  .divider {
    height: 1px;
    width: 100%;
    background: #aaaaaa;
    margin-top: 48px;
    margin-bottom: 16px;
  }

  .clickable-image {
    cursor: pointer;
  }
`;

export const CopyrightText = styled.h4`
color: #000000; /* Adjust the text color for optimal contrast */
font-size: 1.1rem;
font-weight: 300;
`;
