import { differenceInDays, differenceInHours, differenceInMinutes, format, formatDistanceToNow } from 'date-fns';

/**
 * Expects two epoch times and return an object with
 * days, hours and minutes of difference between first and second
 */
export const getDateDiff = (
  firstDate: number,
  secondDate: number,
): {
  days: number;
  hours: number;
  minutes: number;
} => {
  return {
    days: differenceInDays(firstDate, secondDate),
    hours: differenceInHours(firstDate, secondDate),
    minutes: differenceInMinutes(firstDate, secondDate),
  };
};

/**
 * Expects a string in format MM.DD.YYYY
 * Returns a string with format YYYY-MM-DD
 */
export const toDateFormat = (date?: string) => {
  if (!date) return '';
  const parts = date.split('.');

  if (parts.length !== 3) return date;

  return `${parts[0]}/${parts[1]}/${parts[2]}`;
};

/**
 * Expects a string in format YYYY-MM-DD
 * Returns a string with format MM.DD.YYYY
 */
export const formatDate = (date?: string) => {
  if (!date) return '';
  const parts = date.split('-');

  if (parts.length !== 3) return date;

  return `${parts[1]}.${parts[2]}.${parts[0]}`;
};

/**
 * Expects a date in string format YYYY-MM-DDTHH:mm:SSZ or a number date and optionaly a date format to return
 * Returns a string with format MM.dd.yyyy by default or the date  format provided
 */
export const formatDateTime = (date?: string | number, formatDate = 'MM.dd.yyyy') => {
  if (!date) return '';

  try {
    return format(new Date(date), formatDate);
  } catch (err) {
    return '';
  }
};

/**
 * Expects a date in string format YYYY-MM-DDTHH:mm:SSZ or a number date
 * Returns the distance between the given date and now in words.
 */
export const formatDistance = (date?: string | number) => {
  if (!date) return '';
  try {
    return formatDistanceToNow(new Date(date));
  } catch {
    return '';
  }
};
