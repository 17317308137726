import { Col, Row } from 'antd';
import React, { ReactElement, useCallback } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
interface Props {
  title?: React.ReactNode;
  backPageUrl?: string;
  locationState?: any;
  extra?: ReactElement | null;
  fullHeight?: boolean;
  padding?: number;
  hideBack?: boolean;
  'data-cy'?: string;
}
const PageWithTitle: React.FC<Props> = (props) => {
  const { backPageUrl, title, locationState, extra, fullHeight, padding = 4, hideBack } = props;
  const history = useHistory();

  const goBack = useCallback(() => {
    if (hideBack) return;

    if (backPageUrl) history.push(backPageUrl, locationState);
    else history.goBack();
  }, [history, backPageUrl, locationState, hideBack]);

  return (
    <S.Container fullHeight={fullHeight} padding={padding} data-cy={props['data-cy']}>
      <Row gutter={[24, 24]} style={{ height: '100%' }}>
        {(backPageUrl || title) && (
          <>
            <Col xxl={{ span: 18, offset: 3 }} sm={24}>
              <S.BackButtonContainer onClick={goBack} data-cy="component-page-title-title">
                {!hideBack && <FiArrowLeft size={24} />}
                <S.Title>{title}</S.Title>
              </S.BackButtonContainer>
            </Col>
            {extra && (
              <Col xxl={21} sm={24}>
                {extra}
              </Col>
            )}
          </>
        )}
        <Col
          style={{ paddingTop: 0, height: '100%' }}
          xxl={{ span: 18, offset: 3 }}
          xl={24}
          lg={24}
          md={24}
          sm={24}
          xs={24}
        >
          {props.children}
        </Col>
      </Row>
    </S.Container>
  );
};

export default PageWithTitle;
