import { Col, Layout, Row } from 'antd';
import React, { useEffect,
  //  useMemo,
    useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import * as S from './styles';
import LandingPageHeader from '../../shared/LandingPageHeader';
import landingContainerUrl from '../../assets/landing/landing-page-hero-image.png';
import landingMobileContainerUrl from '../../assets/landing/landing-page-hero-image-mobile.png';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import Spacer from '../../shared/Spacer';
import institutionSrc from '../../assets/landing/icons/institution.png';
import satisfactionSrc from '../../assets/landing/icons/satisfaction.png';
import involvementSrc from '../../assets/landing/icons/involvement.png';
// import contactSrc from '../../assets/landing/icons/contact.png';
// import youtubeSrc from '../../assets/landing/icons/youtube.png';
// import facebookSrc from '../../assets/landing/icons/facebook.png';
// import twitterSrc from '../../assets/landing/icons/twitter.png';
import orangeBgSrc from '../../assets/landing/orange-background.png';
import yellowBgSrc from '../../assets/landing/yellow-watercolor.png';
import persona1Src from '../../assets/landing/persona1.png';
import quote1Src from '../../assets/landing/quote1.png';
import quote2Src from '../../assets/landing/quote2.png';
import suppliesSrc from '../../assets/landing/supplies-new.png';
import learningHubLogo from '../../assets/adi-learning-hub.svg';
import { gqlSchema } from '../../gql/schema';
import FreeTeacher from '../../shared/FreeTeacher';
import Carousel from '../../shared/Carousel';

import mathIcon from '../../assets/landing/feature-icons/math.png';
import atomIcon from '../../assets/landing/feature-icons/atom.png';
import scienceIcon from '../../assets/landing/feature-icons/science.png';
import checklistIcon from '../../assets/landing/feature-icons/checklist.png';
import timeIcon from '../../assets/landing/feature-icons/time.png';
import dollarIcon from '../../assets/landing/feature-icons/dollar.png';
import earthIcon from '../../assets/landing/feature-icons/earth.png';
import thoughtIcon from '../../assets/landing/feature-icons/thought.png';
import guidanceIcon from '../../assets/landing/feature-icons/guidance.png';
import growthIcon from '../../assets/landing/feature-icons/growth.png';
import securityIcon from '../../assets/landing/feature-icons/security.png';
import toolsIcon from '../../assets/landing/feature-icons/tools.png';

const realStoriesData = [
  {
    name: 'Deanna Smith, Teacher',
    description: 'Winnipeg School Division',
    image: persona1Src,
    quote:
      'I think the organization of the investigations is the ADI Learning Hub’s best feature. Each investigation is thoroughly laid out and the materials are easy to follow. I appreciate that there are clear instructions for the students for each section and activity. The format allows the teacher to guide students through the steps of inquiry and they cannot jump ahead before they are ready.\nHaving a library of investigations will be a huge time-saver and will also help teachers who are learning to implement ADI-type lessons.',
  },
  {
    name: 'Derik Shirley, High School Science Department Lead',
    description: 'Switzerland of Ohio Local Schools',
    image: persona1Src,
    quote:
      'I love the flexibility of being able to assign tasks as either in-person or hybrid. This allows flexibility to the teacher as far as classroom management and avoids unnecessary delays due to interruptions such as snow days, assemblies, etc.',
  },
  {
    name: 'Cedric Jacobson, Science Department Chair',
    description: 'Brooke High School',
    image: persona1Src,
    quote:
      'I’ve used the paper-based ADIs in years prior, and used the online version during remote instruction during the pandemic. This platform allows for seamless transition between the best of both worlds, enabling me to choose specific phases to work on during class-time and others for students to work on at their own pace. The progress dashboard is a great way for me to track where kids are in the process and identify who may need additional support.',
  },
  {
    name: 'Paige Lubianski, 7th Grade GT Science Teacher',
    description: 'Carroll ISD',
    image: persona1Src,
    quote:
      'The best feature is the peer reviews. It allows students to find mistakes in others work, or to learn something from others that they didn’t catch before. Students learn from helping other students, so I’m glad that peer reviews are a requirement.',
  },
  {
    name: 'Manuela Nae, Physics Teacher & Compliance Director',
    description: 'Avenor College',
    image: persona1Src,
    quote: 'This is a revolutionary tool that could change education.',
  },
  {
    name: 'Audrey Feitor, High School Science Teacher',
    description: 'Medford Public Schools, MA',
    image: persona1Src,
    quote:
      'I think being able to follow the students’ progress and see their work in real time is going to be a real time saver. Also, having all the materials already set up in the module is great.',
  },
  {
    name: 'Jennifer Pasuit, STEM/Biology Teacher',
    description: 'New Providence School District',
    image: persona1Src,
    quote:
      'I love ADI investigations and appreciate this new digital format. I appreciate the customizable time frames to keep everyone on track. Although it is digital, it still allows for peer-to-peer interaction.',
  },
  {
    name: 'Nakia Cole, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'I love the ease in which I can facilitate ADI. Even more so now with the use of clever to login. I don’t have to worry about students keeping up with their passwords and I don’t have to worry about many materials. I love the convenience of having everything all in one platform to implement ADI from start to finish. The feature I find most useful in the Learning Hub is the remote paperless version because I have access to every student’s submission in one place. I don’t have to worry about keeping up with papers.',
  },
  {
    name: 'Jeevankanth Vaddi, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'The Learning Hub directions and instructions are so specific for teachers and students. Beginning with Phenomena till final report students were completely engaged.',
  },
  {
    name: 'Deborah Harris, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'ADI has helped my students to grow in science by giving them the opportunity to create explanations and collecting evidence. Students also showed growth on their science MAP assessment which is evidenced that the ADI learning design in my opinion, is working to help all scholars to become advance future Scientists. The Learning Hub has helped me improve teaching ADI by having user friendly guided resources to help the teacher.',
  },
  {
    name: 'Kenya Fuller-Wiggins,  Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'ADI gives the students an opportunity to use their thinking skills to solve problems. The learning hub helps students access their ADI without the teacher having to print out a lot of wasted paper.',
  },
  {
    name: 'Shatoria Cooper, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'The Learning Hub has helped tremendously with exposing the students to all of the areas of not only science, but also all of the core subject areas. The students have grown such a great deal! They absolutely love looking forward to watching the ADI videos and completing the experiments.',
  },
  {
    name: 'Michael Pace, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'The Learning Hub has been an invaluable tool for collaborative planning and instructional preparation. Doing the Life in Groups investigation helped my students understand how groups are developed and influence how people and animals are able to adapt and survive in a number of different environments. My students are starting to show an active interest in learning more about science and the manner in which things develop and change in the world we live in.',
  },
  {
    name: 'Jabari Wilder, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'ADI allows the students to have a tailor-made inquisitive experience within the classroom. The students are a lot more enthusiastic about learning in their own way and thinking scientifically.',
  },
  {
    name: 'Trena Thomas, Teacher',
    description: 'Clayton County Public Schools',
    image: persona1Src,
    quote:
      'ADI has helped my scholars to grow, because it gave them the opportunity to discuss the information in person and using technology as well to share with in their groups.',
  },
];

const HomePage = () => {
  const history = useHistory();
  const realStoriesTitleRef = useRef<HTMLHeadingElement>(null);
  const isMobile = window.innerWidth <= 768;
  // const copyrightDate = useMemo(() => new Date().getFullYear(), []);

  const [
    fetchCountStudents,
    { data: studentsCount, loading: studentsCountLoading, error: studentsCountError },
  ] = useLazyQuery<{
    getCountOfStudents: { studentsCount: number };
  }>(gqlSchema.HomeSchema.queries.getCountOfStudents);

  const [
    fetchTeachersCount,
    { data: teachersCount, loading: teachersCountLoading, error: teachersCountError },
  ] = useLazyQuery<{
    getCountOfTeachers: { teachersCount: number };
  }>(gqlSchema.HomeSchema.queries.getCountOfTeachers);

  const [fetchOrgCount, { data: orgCount, loading: orgCountLoading, error: orgCountError }] = useLazyQuery<{
    getCountOfOrganizations: { orgCount: number };
  }>(gqlSchema.HomeSchema.queries.getCountOfOrganizations);

  useEffect(() => {
    // Fetch user data only if user scrolls down enough in the page
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !studentsCount && !studentsCountLoading && !studentsCountError) {
        fetchCountStudents();
      }

      if (entry.isIntersecting && !teachersCount && !teachersCountLoading && !teachersCountError) {
        fetchTeachersCount();
      }

      if (entry.isIntersecting && !orgCount && !orgCountLoading && !orgCountError) {
        fetchOrgCount();
      }
    });

    if (realStoriesTitleRef.current) observer.observe(realStoriesTitleRef.current);

    return () => {
      observer.disconnect();
    };
  }, [
    fetchCountStudents,
    fetchOrgCount,
    fetchTeachersCount,
    orgCount,
    orgCountError,
    orgCountLoading,
    studentsCount,
    studentsCountError,
    studentsCountLoading,
    teachersCount,
    teachersCountError,
    teachersCountLoading,
  ]);

  return (
    <Layout data-cy="components-homepage-layout" style={{ minHeight: '100vh', background: '#F5F5FB' }}>
      <LandingPageHeader />
      <S.HomePageContentContainer>
        <S.LandingContainer>
          <S.LandingImageContainer>
            <img
              src={isMobile ? landingMobileContainerUrl : landingContainerUrl}
              alt="Woman working her home office smiling while staring at her computer"
            />
          </S.LandingImageContainer>
          <S.LandingGradientContainer>
            <main role="main" aria-label="Main Content">
              <div className="landing-gradient-text">
                <h2 style={{ color: '0D1AEA' }}>TEACH DIFFERENT</h2>

                <Spacer />
                <h1>Learning Hub for Teachers of the Future</h1>
                <Spacer />
                <p>
                  The ADI Learning Hub is a browser-based platform that educators can use to provide meaningful, rigorous,
                  and equitable learning experiences in engineering, math, and science for students in grades 3-12.
                </p>
                <Spacer />
                <div className="landing-gradient-text-button-container">
                  <FreeTeacher />
                  <Spacer axis="horizontal" size={24} />
                  <Button
                    id="homepage-login-button"
                    data-cy="components-homepage-button-login"
                    text="Log in"
                    theme={themeConfig.primaryNoColor}
                    size="large"
                    padding="3px 15px 5px 15px;"
                    onClick={() => {
                      history.push('/login');
                    }}
                  />
                </div>
              </div>
            </main>
            <footer role="contentinfo" aria-label="Footer">

            </footer>
          </S.LandingGradientContainer>
        </S.LandingContainer>
        <S.FeaturedContainer>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={checklistIcon} alt='Checklist Icon' />
                <h3>Useful Assessments</h3>
                <p>
                  You can assign meaningful educative and formative assessment tasks that make student thinking visible.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={mathIcon} alt='Math Icon' />
                <h3>Math Investigations</h3>
                <p>
                  Students use mathematical concepts and practices to make sense of phenomena. No more, “when am I ever
                  going to use this?”
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={toolsIcon} alt='Tools Icon' />
                <h3>Engineering Challenges</h3>
                <p>
                  Students solve authentic problems as they learn important ideas and practices. Go beyond straw towers
                  and egg drops.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={atomIcon} alt='Atom Icon' />
                <h3>Science Investigations</h3>
                <p>
                  Phenomenon-based and three-dimensional. Students are "figuring out" things instead of just "learning
                  about" concepts.
                </p>
              </S.FeaturedItemContainer>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={earthIcon} alt='Earth Icon' />
                <h3>Flexible</h3>
                <p>
                  Students can complete lessons using the included handouts or in the platform. They can also complete
                  all or part of a lesson from home.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={securityIcon} alt='Security Icon' />
                <h3>Safe For All Users</h3>
                <p>
                  We take privacy seriously. The ADI Learning Hub is <b>FERPA</b>, <b>COPPA</b>, <b>CSPC</b>, and{' '}
                  <b>GPDR</b> compliant.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={scienceIcon} alt='Science Icon' />
                <h3>Learning by Doing</h3>
                <p>
                  Students make sense of phenomena using physical materials and/or embedded simulations. Nothing beats
                  lessons that are hands-on and minds-on.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={thoughtIcon} alt='Thought Bubble Icon' />
                <h3>Supports for Learners</h3>
                <p>
                  Lessons include built-in scaffolds for students such as text-to-speech, text translation, and help
                  videos. Students also provide and receive feedback.
                </p>
              </S.FeaturedItemContainer>
            </Col>
          </Row>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={timeIcon} alt='Time Icon' />
                <h3>Save Time</h3>
                <p>
                  No need to spend hours looking for high quality instructional materials or creating detailed lesson
                  plans from scratch.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={growthIcon} alt='Growth Icon' />
                <h3>Focus on Growth</h3>
                <p>
                  You can see how student thinking and performance on tasks changes over time using the insights tool.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={dollarIcon} alt='Dollar Sign Icon' />
                <h3>Free for Educators</h3>
                <p>
                  Anyone who works in a public or private school, district, or educational organization can sign up for
                  a free starter plan.
                </p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <S.FeatureImage src={guidanceIcon} alt='Guidance Icon' />
                <h3>More Choice</h3>
                <p>Decide which investigation, design challenge, or assessment to assign, in which order, and when.</p>
              </S.FeaturedItemContainer>
            </Col>
          </Row>
        </S.FeaturedContainer>
        <Spacer size={80} />
        <S.ScheduleDemoContainer>
          <Row gutter={[64, 80]}>
            <Col lg={12} md={24} style={{ width: '100%' }}>
              <div className="video-container">
                <img src={orangeBgSrc} alt="Orange watercolour" />
                <div>
                  <iframe
                    src="https://www.youtube.com/embed/_wE3ynHljOE"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </Col>
            <Col lg={12} md={24}>
              <div className="schedule-demo-text">
                <h2 style={{ color: '#4367E9' }}>TAKE YOUR DISTRICT OR SCHOOL INTO THE FUTURE...</h2>
                <Spacer size={24} />
                <h1 style={{ color: '#333' }}>Say hello to the ADI Learning Hub</h1>
                <Spacer size={24} />
                <p>
                  An <b>organization account</b> unlocks even more tools and customizations such as SSO for teachers and
                  students, student rostering, integration with your current LMS, tailored libraries, and authoring
                  tools for investigations and assessments. Contact us today to learn more or to set up an account for
                  your school or district.
                </p>
                <Spacer size={8} />

                <Button
                  data-cy="components-homepage-scheduledemo-button"
                  text="Request Information about Organization Account Plans and Pricing"
                  theme={themeConfig.primaryOutlined}
                  href="https://meetings.hubspot.com/kristasampson/learninghub"
                  shape="round"
                  size="large"
                  padding="5px 15px 0px 15px"
                />
              </div>
            </Col>
          </Row>
        </S.ScheduleDemoContainer>
        <Spacer size={48} />
        <S.RealStoriesContainer>
          <Spacer size={64} />
          <h3 style={{ color: '#4367E9' }} ref={realStoriesTitleRef}>REAL STORIES FROM OUR COMMUNITY</h3>
          <h2>See what educators have to say</h2>
          <Spacer size={96} />
          <div style={{ width: '100%', height: '100%' }}>
            <Carousel width={18} backgroundColor={'transparent'} autoPlay showDots={false}>
              {realStoriesData.map((story, index) => (
                <S.RealStoryCardContainer key={index}>
                  <S.RealStoryCard personaBgSrc={yellowBgSrc}>
                    <div>
                      <img src={quote1Src} alt="Opening quote marks" className="opening-quote" />
                      <p>{story.quote}</p>
                      <img src={quote2Src} alt="Closing quote marks" className="closing-quote" />
                    </div>
                    <Spacer />
                    <h2>{story.name}</h2>
                    <p style={{ margin: 0, fontSize: '1.1rem' }}>{story.description}</p>
                    <Spacer axis="horizontal" size={24} />
                  </S.RealStoryCard>
                </S.RealStoryCardContainer>
              ))}
            </Carousel>
          </div>
        </S.RealStoriesContainer>
        <S.FeaturedContainer>
          <Row justify="space-between" gutter={[32, 32]}>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <img src={institutionSrc} alt="yellow building" />
                <Spacer size={8} />
                <h2>
                  {orgCount?.getCountOfOrganizations
                    ? Number(orgCount?.getCountOfOrganizations?.orgCount || 0)?.toLocaleString()
                    : '-'}
                </h2>
                <p>satisfied institutions</p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <img src={involvementSrc} alt="icon of a person with a heart" />
                <Spacer size={8} />
                <h2>
                  {studentsCount?.getCountOfStudents
                    ? Number(studentsCount?.getCountOfStudents?.studentsCount || 0)?.toLocaleString()
                    : '-'}
                </h2>
                <p>engaged students</p>
              </S.FeaturedItemContainer>
            </Col>
            <Col flex={1}>
              <S.FeaturedItemContainer>
                <img src={satisfactionSrc} alt="drawing of a blue smiling face" />
                <Spacer size={8} />
                <h2>
                  {teachersCount?.getCountOfTeachers
                    ? Number(teachersCount?.getCountOfTeachers?.teachersCount || 0)?.toLocaleString()
                    : '-'}
                </h2>
                <p>proud teachers</p>
              </S.FeaturedItemContainer>
            </Col>
          </Row>
        </S.FeaturedContainer>
        <S.SuppliesContainer>
          <Row align="middle">
            <Col sm={24} md={12}>
              <h3 style={{ color: '#4367E9' }}>KITS AND CLASSROOM SUPPLIES</h3>
              <Spacer />
              <h2>Need resources for your district, school, or classroom?</h2>
              <Spacer />
              <p>
                Discover hands-on kits for ADI math and science investigations as well as engineering design challenges
                in the ADI Store. Make teaching effortless by saving time with added resources, such as dry erase boards
                and easels. Elevate classroom engagement with these helpful materials.
              </p>
              <div style={{ display: 'flex' }}>
                <Button
                  data-cy="components-homepage-shopnow-button"
                  text="Shop Now"
                  theme={themeConfig.primaryOutlined}
                  shape="round"
                  size="large"
                  padding="5px 15px 0px 15px"
                  href="https://shop.argumentdriveninquiry.com/"
                />
              </div>
            </Col>
            <Col sm={24} md={12}>
              <img src={suppliesSrc} alt="ADI Supplies" />
            </Col>
          </Row>
        </S.SuppliesContainer>
        <S.ContactUsContainer>
          <h2 style={{ color: '#333' }}>Need Help Or Have Questions?</h2>

          <Button
            data-cy="components-homepage-contactus-button"
            text="Contact Us"
            theme={themeConfig.primaryColor}
            shape="round"
            size="large"
            href="https://www.argumentdriveninquiry.com/connect-with-us"
            padding="5px 15px 0px 15px"
          />

        </S.ContactUsContainer>
        <S.LinksContainer>
          <Row gutter={[32, 32]}>
            <Col flex={1}>
              <img className="logo-image" src={learningHubLogo} alt="ADI Learning Hub" />
              <h4>Education of the future</h4>
            </Col>

            <Col flex={1}>
              <h3>Overview</h3>
              <a data-cy="components-homepage-overview-programs" href="https://www.argumentdriveninquiry.com/programs">
                Programs
              </a>
              <a
                data-cy="components-homepage-overview-adilearninghub"
                href="https://www.argumentdriveninquiry.com/learning-hub"
              >
                ADI Learning Hub
              </a>
              <a
                data-cy="components-homepage-overview-professionallearning"
                href="https://www.argumentdriveninquiry.com/professional-learning"
              >
                Professional Learning
              </a>
              <a
                data-cy="components-homepage-overview-adiinstructionalmodel"
                href="https://www.argumentdriveninquiry.com/programs/adi-instructional-model"
              >
                ADI Instructional Model
              </a>
              <a
                data-cy="components-homepage-overview-research"
                href="https://www.argumentdriveninquiry.com/programs/research"
              >
                Research
              </a>
            </Col>

            <Col flex={1}>
              <h3>Resources</h3>
              <a data-cy="components-homepage-resources-kb" href="https://help.adilearninghub.com/knowledge">
                Knowledge Base
              </a>
              <a
                data-cy="components-homepage-resources-videos"
                href="https://www.youtube.com/c/ArgumentDrivenInquiry/featured"
              >
                Videos
              </a>
            </Col>

            <Col flex={1}>
              <h3>About Us</h3>
              <a data-cy="components-homepage-aboutus-our-story" href="https://www.argumentdriveninquiry.com/our-story">
                Our Story
              </a>
              <a data-cy="components-homepage-aboutus-careers" href="https://www.argumentdriveninquiry.com/careers">
                Careers
              </a>
              <a
                data-cy="components-homepage-aboutus-purchase-order"
                href="https://www.argumentdriveninquiry.com/inquiries/purchase-order-information"
              >
                Purchase Orders
              </a>
            </Col>
          </Row>
          <div className="divider" />
          <Spacer />
          {/* <Row gutter={[32, 32]}>
            <Col flex={1}>
              <Row>
                <a
                  data-cy="components-homepage-bottom-termscondition"
                  href="/documents/terms-of-use.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Opens in a new tab: Terms &amp; conditions"
                >
                  Terms & conditions
                </a>
                <Spacer axis="horizontal" size={24} />
                <a
                  data-cy="components-homepage-bottom-privacy"
                  target="_blank"
                  href="/documents/privacy-policy.pdf"
                  rel="noopener noreferrer"
                  aria-label="Opens in a new tab: Privacy policy"
                >
                  Privacy policy
                </a>
                <Spacer axis="horizontal" size={24} />
                <a
                  data-cy="components-homepage-bottom-contactus"
                  href="https://www.argumentdriveninquiry.com/connect-with-us"
                >
                  Contact us
                </a>
              </Row>
            </Col>
            <Col flex={1}>
              <Row justify="end">
                <a
                  href="https://www.argumentdriveninquiry.com/connect-with-us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="clickable-image"
                  aria-label="Connect with us"
                >
                  <img
                    src={contactSrc}
                    alt="Two conversation balloons"
                  />
                </a>
                <Spacer axis="horizontal" />
                <a
                  href="https://www.youtube.com/c/ArgumentDrivenInquiry/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="clickable-image"
                  aria-label="Open YouTube Channel"
                >
                  <img
                    data-cy="components-homepage-bottom-youtube"
                    src={youtubeSrc}
                    alt="Youtube Logo"
                  />
                </a>
                <Spacer axis="horizontal" />
                <a
                  href="https://www.facebook.com/ArgumentDriven/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="clickable-image"
                  aria-label="Open Facebook Page"
                >
                  <img
                    data-cy="components-homepage-bottom-facebook"
                    src={facebookSrc}
                    alt="Facebook Logo"
                  />
                </a>
                <Spacer axis="horizontal" />
                <a
                  href="https://twitter.com/ArgumentDriven"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="clickable-image"
                  aria-label="Open Twitter Profile"
                >
                  <img
                    data-cy="components-homepage-bottom-twitter"
                    src={twitterSrc}
                    alt="Twitter Logo"
                  />
                </a>
              </Row>
            </Col>
          </Row>
          <Spacer size={32} />
          <S.CopyrightText>©{copyrightDate} Argument-Driven Inquiry Learning Hub</S.CopyrightText> */}
        </S.LinksContainer>
      </S.HomePageContentContainer>
    </Layout>
  );
};

export default HomePage;
