import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Form as AntdForm, Col, Row, Tooltip, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { FiFileText, FiMail } from 'react-icons/fi';
import { gqlSchema } from '../../../gql/schema'; // Ensure this import is correct
import Button from '../../../shared/Button';
import Form from '../../../shared/Form';
import GInviteUsers from '../../../shared/GInviteUsers';
import { GQL_Invitation } from '../../../types/googleClassroom';
import { StudentDataProps } from '../../../types/students';
import { onDownloadStudentTemplate } from '../../../utils/class';
import * as S from './styles';
import { themeConfig } from '../../../utils/theme';

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  openGroupManagment?: Dispatch<SetStateAction<boolean>>;
  updateClassInfo?: (e: GQL_Invitation[]) => void;
  courseId: string;
}

const initialInvite: StudentDataProps[] = [{ firstName: '', lastName: '', email: '' }];

const AddTeachers: React.FC<Props> = ({ visible, setVisible, updateClassInfo, courseId }) => {
  const [activeKey, setActiveKey] = useState('manual');
  const [hasUploadedFile, setHasUploadedFile] = useState(false);
  const [viewStudentsDetails, setViewStudentsDetails] = useState(false);
  const [hasErrorFile, setHasErrorFile] = useState(false);
  const [form] = useForm();

  const handleCancel = () => {
    setVisible(false);
  };

  const [createInvitation, { loading }] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.CreateInviatation, {
    onCompleted: (data) => {
      if (updateClassInfo) {
        const invitations = data.createInvitation as GQL_Invitation[];
        updateClassInfo(invitations);
      }
      message.success('Invites sent successfully');
      setVisible(false); // Close the modal after successful completion
    },
    onError: (e) => {
      message.error(`Error trying to add new students: ${e.message}`);
    },
  });

  const onFinish = useCallback(
    async ({ invites }: { invites: StudentDataProps[] }) => {
      invites.forEach((invite) => {
        createInvitation({
          variables: {
            data: {
              courseId,
              userId: invite.email,
              role: 'TEACHER'
            },
          },
        });
      });
    },
    [createInvitation, courseId]
  );

  const onReimport = useCallback(() => {
    setViewStudentsDetails(false);
    setHasUploadedFile(false);
  }, []);

  const onImportEmails = useCallback(() => {
    setViewStudentsDetails(true);
    setHasUploadedFile(false);
  }, []);

  const titleInfo = useMemo(
    () => (
      <Row style={{ marginTop: 26 }} justify="center">
        <Col span={1}>
          <FiMail color="#106cfa" size={32} />
        </Col>
        <Col span={24}>
          <S.Title>Invite by Email</S.Title>
        </Col>
        {/* <Col span={24}>
          <S.Info>Students will receive a link on their email which</S.Info>
          <S.Info>will guide them to create an account</S.Info>
        </Col> */}
      </Row>
    ),
    []
  );

  const saveButton = useMemo(
    () => (
      <AntdForm.Item>
      <Button
        data-cy="components-classdashboard-addstudents-send-button"
        block
        loading={loading}
        text="Send Invites"
        htmlType="submit"
      />
    </AntdForm.Item>
    ),
    [loading]
  );

  const renderButtons = () => {
    let buttonBar = (
      <Col span={12} offset={6}>
        {saveButton}
      </Col>
    );
    if (activeKey === 'file') {
      if (viewStudentsDetails) {
        buttonBar = (
          <>
            <Col span={12}>
              <Button block text="Import Another File" htmlType="button" onClick={onReimport} />
            </Col>
            <Col span={12}>{saveButton}</Col>
          </>
        );
      } else {
        if (hasUploadedFile) {
          buttonBar = (
            <Col span={12} offset={6} style={{ marginBottom: 24 }}>
              <Button
                block
                disabled={hasErrorFile}
                text="Import Emails"
                htmlType="button"
                onClick={onImportEmails}
              />
            </Col>
          );
        }
      }
    }
    return <Row gutter={16}>{buttonBar}</Row>;
  };

  return (
    <S.CModal
      visible={visible}
      title={titleInfo}
      onCancel={handleCancel}
      footer={null}
      width={800}
    >
      <Form onFinish={onFinish} form={form} initialValues={{ invites: initialInvite }}>
        <Row gutter={24}>
          <Col span={20} offset={2}>
            <GInviteUsers
              form={form}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              setHasUploadedFile={setHasUploadedFile}
              viewUsersDetails={viewStudentsDetails}
              setHasErrorFile={setHasErrorFile}
              hasErrorFile={hasErrorFile}
              initialInvite={initialInvite}
              userType="teacher"
            />
          </Col>
          {activeKey === 'file' && (
            <Col span={1}>
              <S.DownloadTemplate>
                <Tooltip title="Click here to download a template to import your class/data." placement="bottom">
                  <FiFileText
                    size={24}
                    onClick={onDownloadStudentTemplate}
                    style={{ cursor: 'pointer' }}
                    color={themeConfig.secondaryColor.background}
                  />
                </Tooltip>
              </S.DownloadTemplate>
            </Col>
          )}
          <Col span={16} offset={4}>
            {renderButtons()}
          </Col>
        </Row>
      </Form>
    </S.CModal>
  );
};

export default AddTeachers;