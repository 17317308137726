import React from 'react';
import { useHistory } from 'react-router-dom';

import successImage from '../../../assets/success_image.png';
import Button from '../../../shared/Button';
import * as S from './styles';

const FinishedAssessment = () => {
  const history = useHistory();
  const backToDashboard = () => history.push('/student-dashboard');

  return (
    <S.SuccesContainer data-cy="studentinvestigation-finishedinvestigation-success-container">
      <img src={successImage} alt="Success" />
      <h1>
        Congratulations! You have finished the assessment.
        <br />
        Go back to your dashboard to see your grade
      </h1>
      <Button
        data-cy="studentassessment-finishedassessment-back-button"
        text="Back to Dashboard"
        onClick={backToDashboard}
      />
    </S.SuccesContainer>
  );
};

export default FinishedAssessment;
