import React from 'react';
import { Row, Col, Empty } from 'antd';
import startCase from 'lodash/startCase';
import DashboardRow from '../../../shared/DashboardRow';
import Item from './Item';
import { formatDateTime } from '../../../utils/date';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg'; // Import the AssignmentIcon

interface IFinishedAssignmentsRow {
  loading: boolean;
  finishedAssignments: any[];
  courses: any[];
}

const CFinishedAssignmentsRow: React.FC<IFinishedAssignmentsRow> = ({ loading, finishedAssignments, courses }) => {

  return (
    <DashboardRow title={`Finished Assignments`} items={renderAssignments()} />
  );

  function renderAssignments() {
    if (loading) {
      return (
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map((index) => (
            <Col key={index} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      );
    } else if (!finishedAssignments?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Finished Assignments" />;
    } else {
      return (
        <Row gutter={[24, 24]}>
          {finishedAssignments.map((assignment, index) => {
            const course = courses.find((c) => c.id === assignment.courseId);
            const className = course ? course.name : '';
            return (
              <Col key={`${assignment.id}-${index}`} xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
                <Item
                  date={formatDateTime(assignment.creationTime)}
                  assignmentName={startCase(assignment.title.toLowerCase())}
                  assignmentId={assignment.id}
                  courseId={assignment.courseId}
                  className={className}
                  icon={<img src={AssignmentIcon} alt="Assignment Icon" style={{ width: 24, height: 24 }} />} // Pass AssignmentIcon as icon prop
                  workType={assignment.workType} courseWorkId={''} courseName={''}                />
              </Col>
            );
          })}
        </Row>
      );
    }
  }
};

export default CFinishedAssignmentsRow;
