import { Col, Row, Tooltip } from 'antd';
import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import Icon from '@ant-design/icons';

import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import * as S from './styles';
import { getInvestigationLogo } from '../../utils/investigationLogos';
import { useAuth } from '../../hooks/useAuth';
import { useEnabledAssessments } from '../../hooks/useEnabledAssessments';
import { SUBJECT_TYPES, SUBJECT_TYPES_ROUTES } from '../../types/subjects';

type Props = RouteComponentProps<{
  classId: string;
}>;

const InvestigationTypePage: React.FC<Props> = (props) => {
  const { classId } = props.match.params;
  const {
    isFacilitator,
    isScienceTexasEditionEnabledWithGrades,
    isSubscriptionUser,
    belongsToOrganization,
  } = useAuth();

  const history = useHistory();
  const { isSubjectEnabled } = useEnabledAssessments();

  const isAssessment = history.location.pathname.includes('assign-assessment');
  const type = isAssessment ? 'Assessment' : 'Investigation';
  const lowerType = isAssessment ? 'assessments' : 'investigations';
  const backUrl = classId ? `/teacher-dashboard/class/${classId}` : undefined;
  const pageTitle = classId ? `Assign a New ${type}` : undefined;

  const TooltipElement = ({ subject }: { subject: SUBJECT_TYPES }) => {
    return (
      <>
        {belongsToOrganization ? (
          <>
            {!isSubjectEnabled(subject) && (
              <Tooltip
                overlayInnerStyle={{ backgroundColor: 'black' }}
                title={
                  <>
                    To gain access to this library, please contact your organization's admin and let them know you would
                    like access to this library.
                  </>
                }
              >
                <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {!isSubscriptionUser && !isSubjectEnabled(subject) && (
              <Tooltip
                overlayInnerStyle={{ backgroundColor: 'black' }}
                title={
                  <>
                    Upgrade your subscription{' '}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://share.hsforms.com/1WIzZgPCwR7Snqj6opPDvTQ5gbsq"
                    >
                      here
                    </a>{' '}
                    to unlock these investigations or design challenges.
                  </>
                }
              >
                <FiHelpCircle size={18} color="#767676" style={{ marginLeft: 4 }} />
              </Tooltip>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <PageWithTitle title={pageTitle} padding={isSubscriptionUser ? 2 : 4} backPageUrl={backUrl}>
        {isFacilitator ? (
          <Row gutter={40} align="stretch">
            <Col lg={12} md={24}>
              <S.CardContainer subject="Workshop">
                <div>
                  <Icon
                    component={getInvestigationLogo({
                      discipline: '',
                      subject: 'Workshop',
                    })}
                  />
                </div>
                <h2>{`Workshop ${type}`}</h2>
                <p>{`See all Workshop ${lowerType} that can be assigned to your Courses or Events.`}</p>
                <Button
                  minHeight={40}
                  text={`Assign a Workshop ${type}`}
                  onClick={() => history.push(history.location.pathname + '/workshop')}
                />
              </S.CardContainer>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={[40, 40]} align="stretch">
              <Col lg={12} md={24}>
                <S.CardContainer subject={SUBJECT_TYPES.MATH}>
                  <div>
                    <Icon
                      component={getInvestigationLogo({
                        discipline: '',
                        subject: 'Math',
                      })}
                    />
                  </div>
                  <h2>{`Math ${type}`}</h2>
                  <p>{`See all Math ${lowerType} that can be assigned to your classes.`}</p>
                  <Row className="button-row" align="middle">
                    <Button
                      disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.MATH)}
                      minHeight={40}
                      data-cy="page-investigation-type-page-assign-math-button"
                      text={`Assign a Math ${type}`}
                      onClick={() => history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.MATH}`)}
                    />
                    <TooltipElement subject={SUBJECT_TYPES.MATH} />
                  </Row>
                </S.CardContainer>
              </Col>
              <Col lg={12} md={24}>
                <S.CardContainer subject={SUBJECT_TYPES.SCIENCE}>
                  <div>
                    <Icon
                      component={getInvestigationLogo({
                        discipline: '',
                        subject: 'Science',
                      })}
                    />
                  </div>
                  <h2>{`Science ${type}`}</h2>
                  <p>{`See all Science ${lowerType} that can be assigned to your classes.`}</p>
                  <Row className="button-row" align="middle">
                    <Button
                      disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.SCIENCE)}
                      minHeight={40}
                      text={`Assign a Science ${type}`}
                      onClick={() => history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.SCIENCE}`)}
                    />
                    <TooltipElement subject={SUBJECT_TYPES.SCIENCE} />
                  </Row>
                </S.CardContainer>
              </Col>
              <Col lg={12} md={24}>
                <S.CardContainer subject={SUBJECT_TYPES.ENGINEERING}>
                  <div>
                    <Icon
                      component={getInvestigationLogo({
                        discipline: 'mechanical',
                        subject: 'Engineering',
                      })}
                    />
                  </div>
                  <h2>{`Engineering ${type === 'Investigation' ? 'Design Challenge' : type}`}</h2>
                  <p>{`See all Engineering ${lowerType} that can be assigned to your classes.`}</p>
                  <Row className="button-row" align="middle">
                    <Button
                      disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.ENGINEERING)}
                      minHeight={40}
                      text={`Assign an Engineering ${type === 'Investigation' ? 'Design Challenge' : type}`}
                      onClick={() => history.push(`${history.location.pathname}/${SUBJECT_TYPES_ROUTES.ENGINEERING}`)}
                    />
                    <TooltipElement subject={SUBJECT_TYPES.ENGINEERING} />
                  </Row>
                </S.CardContainer>
              </Col>
              {isScienceTexasEditionEnabledWithGrades ? (
                <Col lg={12} md={24}>
                  <S.CardContainer subject={SUBJECT_TYPES.SCIENCE_TEXAS_EDITION}>
                    <div>
                      <Icon
                        component={getInvestigationLogo({
                          discipline: '',
                          subject: 'Science',
                        })}
                      />
                    </div>

                    <h2
                      dangerouslySetInnerHTML={{ __html: `Science ${type}: <br /> Texas Edition (2024 Adoption)` }}
                    ></h2>
                    <p>
                      {`See all Science: Texas Edition (2024 Adoption) ${lowerType} that can be assigned to your classes.`}
                    </p>
                    <Row className="button-row" align="middle">
                      <Button
                        disabled={isSubscriptionUser ? false : !isSubjectEnabled(SUBJECT_TYPES.SCIENCE_TEXAS_EDITION)}
                        minHeight={40}
                        text={`Assign a Science: Texas Edition (2024 Adoption) ${type}`}
                        onClick={() => history.push(`${history.location.pathname}/science-texas-edition`)}
                      />
                      <TooltipElement subject={SUBJECT_TYPES.SCIENCE_TEXAS_EDITION} />
                    </Row>
                  </S.CardContainer>
                </Col>
              ) : null}
            </Row>
          </>
        )}
      </PageWithTitle>
    </>
  );
};

export default withRouter(InvestigationTypePage);
