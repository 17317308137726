import { gql } from '@apollo/client';

export const HOME_QUERIES = {
  getImpersonateFeatureAvailability: gql`
    query getImpersonateFeatureAvailability {
      getImpersonateFeatureAvailability {
        available
      }
    }
  `,
  getImpersonateTokenByEmail: gql`
    query getImpersonateTokenByEmail($email: String!) {
      getImpersonateTokenByEmail(email: $email) {
        refresh_token
        access_token
      }
    }
  `,
  getAmountOfUsers: gql`
    query getAmountOfUsers {
      getAmountOfUsers {
        orgCount
        teachersCount
        studentsCount
      }
    }
  `,
  getCountOfOrganizations: gql`
    query getCountOfOrganizations {
      getCountOfOrganizations {
        orgCount
      }
    }
  `,
  getCountOfStudents: gql`
    query getCountOfStudents {
      getCountOfStudents {
        studentsCount
      }
    }
  `,
  getCountOfTeachers: gql`
    query getCountOfTeachers {
      getCountOfTeachers {
        teachersCount
      }
    }
  `,

};
