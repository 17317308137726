import React, { useCallback, useRef, useState } from 'react';
import { Layout, message, Pagination, Row } from 'antd';
import { FiPlus } from 'react-icons/fi';

import * as S from './styles';
import PeerReviewSider from './PeerReviewSider';
import InvestigationContent from '../../shared/InvestigationContent';
import Button from '../../shared/Button';
import DraggableComment from '../../shared/DraggableComment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { GQL_PeerReview } from '../../types/investigation';
import { GQL_SubmitCommentVariables } from '../../types/investigation';
import InvestigationFileDisplay from '../../shared/InvestigationFileDisplay';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<
  {
    investigationId: string;
    stepId: string;
    studentId: string;
    activityId: string;
    submissionVersion?: string;
  },
  any,
  {
    userId?: string;
  }
>;

const PeerReviewInvestigationPage: React.FC<Props> = (props) => {
  const { stepId, studentId, investigationId, activityId } = props.match.params;
  const { userId } = props.location.state || {};
  const { isStudent } = useAuth();
  const pdfRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [addingComment, setAddingComment] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfHeight, setPdfHeight] = useState<number>();
  const [pdfWidth, setPdfWidth] = useState<number>();
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 1;

  const setAddingCommentTrue = useCallback(() => setAddingComment(true), []);
  const setAddingCommentFalse = useCallback(() => setAddingComment(false), []);

  const [submitCommentMutation, { loading }] = useMutation<
    { submitPeerReview: GQL_PeerReview },
    { studentId: string; stepId: string; comment: GQL_SubmitCommentVariables; userId?: string }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitPeerReview, {
    onCompleted: () => {
      setAddingComment(false);
    },
    onError: (err) => {
      message.error('There was an error saving comment: ' + err.message || 'Unexpected Error');
    },
    refetchQueries: [
      { query: gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, variables: { id: investigationId } },
    ],
  });

  const { data, loading: loadingPeerReview } = useQuery<
    { getPeerReviewByStepIdAndStudentId: GQL_PeerReview },
    {
      stepId: string;
      studentId: string;
      reviewerId?: string;
    }
  >(gqlSchema.InvestigationSchema.queries.CORE.getPeerReviewByStepIdAndStudentId, {
    variables: {
      stepId,
      studentId,
      reviewerId: isStudent ? undefined : userId,
    },
    onError: (err) => {
      message.error('There was an error loading the peer-review: ' + err.message || 'Unexpected Error');
    },
  });

  const submitComment = useCallback(
    (
      text: string,
      position: {
        x: number;
        y: number;
      },
    ) => {
      submitCommentMutation({
        variables: {
          stepId,
          studentId,
          userId: isStudent ? undefined : userId,
          comment: {
            text,
            activityId,
            coordinates: {
              page: pageNumber,
              x: position.x / (pdfHeight ?? 1),
              y: position.y / (pdfWidth ?? 1),
              ts: videoRef.current?.currentTime,
            },
          },
        },
      });
    },
    [stepId, studentId, submitCommentMutation, pdfHeight, pdfWidth, activityId, pageNumber, userId, isStudent],
  );

  const onLoadSuccess = (pdf: any) => {
    if (pdf) setNumPages(pdf?.numPages);

    setTimeout(() => {
      setPdfHeight(pdfRef?.current?.clientHeight);
      setPdfWidth(pdfRef?.current?.clientWidth);
    });
  };

  const peerReview = data?.getPeerReviewByStepIdAndStudentId;
  const isFileHidden = !peerReview?.fileUrl;

  return (
    <Layout>
      <PeerReviewSider
        submissionVersion={submissionVersion}
        investigationId={investigationId}
        comments={peerReview?.comments}
        loading={loadingPeerReview}
      />
      <InvestigationContent>
        <Row justify="center">
          <S.Column span={12}>
            <h1>Peer Review Investigation Plan</h1>
            <h2>
              Give people in your group some ideas about what they can do to make their plans better by adding a comment
              to the submission below. Be sure to post at least 2 comments. Comments can be suggestions or something you
              liked about the proposal.
            </h2>
            <Button
              text={addingComment ? 'Adding a comment...' : 'Add a Comment'}
              icon={<FiPlus size={20} />}
              onClick={setAddingCommentTrue}
              disabled={addingComment || isFileHidden || !!peerReview?.reflectionCompletedAt}
              loading={isStudent && !isFileHidden && !pdfHeight}
              data-cy="pages-peer-review-investigation-button-add-comment"
            />
          </S.Column>
        </Row>

        {!isFileHidden && (
          <Row justify="center">
            <Pagination
              style={{
                zIndex: 1,
              }}
              size="small"
              total={numPages}
              pageSize={1}
              current={pageNumber}
              onChange={setPageNumber}
            />
          </Row>
        )}

        {!isFileHidden && !loadingPeerReview && (
          <InvestigationFileDisplay
            file={peerReview?.fileUrl ?? ''}
            page={pageNumber}
            mimeType={peerReview?.fileMIMEtype || ''}
            onLoadSuccess={onLoadSuccess}
            containerRef={pdfRef}
            videoRef={videoRef}
          >
            {addingComment && (
              <DraggableComment
                bounds="parent"
                initialPosition={{ x: 50, y: 50 }}
                onSubmit={submitComment}
                onCancel={setAddingCommentFalse}
                loading={loading}
                disabledOnBlankText
              />
            )}
          </InvestigationFileDisplay>
        )}
        {!loadingPeerReview && isFileHidden && (
          <p style={{ textAlign: 'center' }}>This file was hidden by your teacher and will be reviewed</p>
        )}
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(PeerReviewInvestigationPage);
