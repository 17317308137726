import { Checkbox, Image } from 'antd';
import React from 'react';
import { ITextContentValue } from '../../../types/investigation';
import { ImageAnswerBlock, AnswerHead } from '../InvestigationMultipleChoiceQuestion/styles';
import { useAuth } from '../../../hooks/useAuth';

interface IInvestigationMultipleChoicePictureQuestion {
  value: ITextContentValue;
  index?: number;
  onSelectItem?: (index: number) => void;
  answer?: number;
  readOnly?: boolean;
  presentationMode?: boolean;
}

const InvestigationMultipleChoicePictureQuestion = (props: IInvestigationMultipleChoicePictureQuestion) => {
  const { value, index = 0, onSelectItem = () => null, answer, readOnly } = props;
  const { isTeacher, isTeacherOrFacilitator } = useAuth();
  const showCorrectAnswer = !props.presentationMode && (isTeacherOrFacilitator || isTeacher);
  // If file exists it's because it was not uploaded yet
  const SOURCE = value?.file ? URL.createObjectURL(value?.file) : value.url;

  return (
    <ImageAnswerBlock
      onClick={() => !readOnly && onSelectItem(index)}
      showTargetAnswer={showCorrectAnswer}
      isTargetAnswer={!!value.targetAnswer}
    >
      <AnswerHead>
        <Checkbox checked={answer === index} disabled={readOnly} />
        {value.optionLabel}
      </AnswerHead>
      {SOURCE && <Image src={SOURCE} alt="Answer option" />}
    </ImageAnswerBlock>
  );
};

export default InvestigationMultipleChoicePictureQuestion;
