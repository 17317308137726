import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { Col, Layout, message, Pagination, Row, Steps } from 'antd';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_FinalReportQuestionnaire,
  GQL_GradedFinalReportQuestionnaire,
  GQL_InvestigationActivity,
  GQL_InvestigationDisplay,
} from '../../types/investigation';
import AssessmentQuestionnaire, { QuestionnaireForm } from '../StudentInvestigation/AssessmentQuestionnaire';
import { useAuth } from '../../hooks/useAuth';
import { GQL_InvestigationSummaryResponse } from '../../types/teacher';
import InvestigationContent from '../../shared/InvestigationContent';
import { FiArrowLeft } from 'react-icons/fi';
import Button from '../../shared/Button';
import Spacer from '../../shared/Spacer';
import InvestigationContentBuilder from '../StudentInvestigation/InvestigationContentBuilder';
import { questionnaireFormValidation, isCurrentActivityValid } from './validationHelper';
import successImage from '../../assets/success_image.png';
import CircularSpin from '../../shared/CircularSpin';
import AssessmentGrade from '../../shared/AssessmentGrade';

const { Step } = Steps;

type Props = RouteComponentProps<{
  investigationId: string;
  studentId: string;
  submissionVersion?: string;
}>;

interface GQL_PartialFinalReportQuestionnaireQuestionInput {
  index: number;
  answer: number;
}
interface GQL_OptionalFinalReportQuestionnaireInput {
  index: number;
  feedback: string;
  questions: GQL_PartialFinalReportQuestionnaireQuestionInput[];
}

export const assessmentReasonTextMap = {
  Correct: 2,
  'Partially Correct': 1,
  Incorrect: 0,
};

export const assessmentScoreTextMap = {
  Correct: 1,
  Incorrect: 0,
};

const AssessmentGradingPage: React.FC<Props> = (props) => {
  const { investigationId, studentId } = props.match.params;
  const history = useHistory();
  const { user, isTeacherOrFacilitator } = useAuth();
  const [current, setCurrent] = useState(0);
  const [listenToValueChange, setListenToValueChange] = useState(false);
  const [finalReportAverage, setFinalReportAverage] = useState(-1);
  const [assessmentPart, setAssessmentActivity] = useState(0);
  const [currentContentIndex, setCurrentContentIndex] = useState(0);
  const submissionVersion = props.match.params.submissionVersion ? parseInt(props.match.params.submissionVersion) : 0;

  const [questionnaireForm, setQuestionnaireForm] = useState<QuestionnaireForm>();
  const [formValidation, setValidation] = useState(false);

  const { data: investigationForTeacherData, loading } = useQuery<
    { getInvestigationByIdForTeacher: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher, {
    variables: { id: investigationId },
    onError: (err) => {
      message.error('There was an error loading the investigation: ' + err.message || 'Unexpected Error');
    },
  });

  const assessmentStep = investigationForTeacherData?.getInvestigationByIdForTeacher?.steps[0];
  const assessmentActivities = useMemo(() => assessmentStep?.activities || [], [assessmentStep]);
  const currentActivity = assessmentActivities[assessmentPart];

  const validateFinalReportAnswers = useCallback(() => {
    const isValid = questionnaireFormValidation(questionnaireForm);
    if (isValid !== -1) {
      message.error('Please fill out all the fields to proceed.');
      return false;
    }

    return true;
  }, [questionnaireForm]);

  const onChangeStep = useCallback(
    (current: number) => {
      if (current === assessmentActivities.length) {
        if (!validateFinalReportAnswers()) {
          setValidation(true);
          return;
        }
      }
      setCurrent(current);
      setAssessmentActivity(current);
      setCurrentContentIndex(0);
      setListenToValueChange(true);
      setTimeout(() => {
        setListenToValueChange(false);
      }, 500);
    },
    [validateFinalReportAnswers, setListenToValueChange, assessmentActivities],
  );

  useQuery<{ getFinalReportGrading: GQL_GradedFinalReportQuestionnaire }, { investigationId: string; userId: string }>(
    gqlSchema.InvestigationSchema.queries.GRADING.getFinalReportGrading,
    {
      variables: {
        investigationId,
        userId: studentId,
      },
      onCompleted: (data) => {
        setQuestionnaireForm({
          activities: data.getFinalReportGrading.questionnaire.map((q: GQL_FinalReportQuestionnaire) => {
            return {
              assessmentScore: q.questions[0].answer,
              reason: q.questions[1].answer,
              feedback: q.feedback,
            };
          }),
          scoreFeedback: data.getFinalReportGrading.scoreType,
        });
        if (data.getFinalReportGrading.grade !== null) {
          setFinalReportAverage(data.getFinalReportGrading.grade);
        }
        if (data.getFinalReportGrading.scoreType !== null) {
          const step = data.getFinalReportGrading.questionnaire.length;
          setCurrent(step);
          setAssessmentActivity(step);
          setCurrentContentIndex(0);
        }
        setListenToValueChange(true);
        setTimeout(() => {
          setListenToValueChange(false);
        }, 500);
      },
      onError: (err) => {
        message.error('There was an error loading the grading: ' + err.message || 'Unexpected Error');
      },
    },
  );

  const { data: investigationProgressSummary } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId,
    },
    onError: () => {
      message.error(
        'There was an error loading the investigation details, please check your connection and try again later',
      );
    },
  });

  const nextAvailableForGrading = useMemo(() => {
    const perStudents = investigationProgressSummary?.getInvestigationProgressSummary?.perStudents || [];
    const currentStudent = perStudents?.find((student) => student.userId === studentId);
    const nextStudent = perStudents?.find((student) => student.status === 'READY_FOR_GRADING');

    if (!nextStudent || nextStudent?.userId === currentStudent?.userId) {
      return null;
    }

    return nextStudent;
  }, [investigationProgressSummary, studentId]);

  const onChangeForm = useCallback((e: any) => {
    setQuestionnaireForm(e);
  }, []);

  const steps = useMemo(() => {
    const questionnaireSteps = [];
    if (assessmentActivities.length > 0) {
      assessmentActivities.forEach((activity: GQL_InvestigationActivity, i: number) => {
        questionnaireSteps.push({
          title: `${activity.name}`,
          content: (
            <AssessmentQuestionnaire
              activityBlocks={activity.content[0].blocks}
              studentId={studentId}
              values={questionnaireForm}
              onChangeForm={onChangeForm}
              activityIndex={assessmentPart}
              formValidation={formValidation}
              listenToValueChange={listenToValueChange}
            />
          ),
        });
      });
    }

    questionnaireSteps.push({
      title: 'Done',
      content: (
        <S.SuccesContainer>
          <img src={successImage} alt="Success" />
          <h1>
            You have graded the assessment successfully.
            <br />
            {finalReportAverage > -1 && (
              <AssessmentGrade grade={finalReportAverage} size={'large'} stepCount={assessmentActivities.length} />
            )}
          </h1>
        </S.SuccesContainer>
      ),
    });

    return questionnaireSteps;
  }, [
    onChangeForm,
    questionnaireForm,
    finalReportAverage,
    assessmentPart,
    formValidation,
    assessmentActivities,
    listenToValueChange,
    studentId,
  ]);

  const [submitGradeAssessment, { loading: loadingGradeAssessment }] = useMutation<{ gradeAssessment: number }>(
    gqlSchema.InvestigationSchema.mutations.GRADING.gradeAssessment,
    {
      onCompleted: (data) => {
        setFinalReportAverage(data.gradeAssessment);
        onChangeStep(assessmentPart + 1);
      },
      onError: (err) => {
        message.error('There was an error submiting grade: ' + err.message || 'Unexpected Error');
      },
      refetchQueries: [
        'GetFinalReportGrading',
        'getInvestigationProgressSummary',
        { query: gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationsByTeacher },
      ],
    },
  );

  const gradeReport = useCallback(() => {
    setValidation(true);
    const isValid = validateFinalReportAnswers();
    if (isValid) {
      const finalAnswers: GQL_OptionalFinalReportQuestionnaireInput[] = questionnaireForm
        ? questionnaireForm.activities.map((activity, activityIndex) => {
            return {
              index: activityIndex,
              feedback: activity.feedback,
              questions: [
                {
                  index: 0,
                  answer: activity.assessmentScore!,
                },
                {
                  index: 1,
                  answer: activity.reason!,
                },
              ],
            };
          })
        : [];

      submitGradeAssessment({
        variables: {
          classAssessmentId: investigationId,
          userId: studentId,
          scoreType: questionnaireForm?.scoreFeedback,
          gradingData: finalAnswers,
        },
      });
    }
  }, [questionnaireForm, submitGradeAssessment, validateFinalReportAnswers, investigationId, studentId]);

  const student = useMemo(() => {
    return investigationProgressSummary?.getInvestigationProgressSummary.perStudents.find(
      (s) => s.userId === studentId,
    );
  }, [investigationProgressSummary, studentId]);

  const handleFormNextStep = () => {
    const activity = questionnaireForm?.activities[assessmentPart];
    if (activity && isCurrentActivityValid(questionnaireForm?.scoreFeedback, activity)) {
      onChangeStep(assessmentPart + 1);
    } else {
      message.error('Please fill out all the fields to proceed.');
    }
  };

  const handleNextStudent = () => {
    history.push(`/teacher-dashboard/assessment-details/${investigationId}/grade/${nextAvailableForGrading?.userId}`);

    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  const handleGoToDashboard = () => {
    history.push(`/teacher-dashboard/assessment-summary/${investigationId}`);
  };

  return (
    <Layout>
      <InvestigationContent noMargin>
        {loading ? (
          <S.LoadingContainer>
            <CircularSpin size={48} />
          </S.LoadingContainer>
        ) : (
          <>
            <Row justify="center">
              <S.Column xl={22} md={23} onClick={() => history.goBack()}>
                <div>
                  <FiArrowLeft />
                  <S.TitleContainer>
                    {student && investigationProgressSummary
                      ? `Grading: ${investigationProgressSummary?.getInvestigationProgressSummary.title} for student ${student?.firstName} ${student?.lastName}`
                      : 'Loading...'}
                  </S.TitleContainer>
                </div>
              </S.Column>
            </Row>
            <Row justify="center">
              <Col xl={22} md={23}>
                <Row gutter={10}>
                  <Col span={12}>
                    <Row justify="center">
                      <Pagination
                        style={{
                          zIndex: 1,
                        }}
                        size="small"
                        total={assessmentActivities.length}
                        pageSize={1}
                        current={assessmentPart + 1}
                        onChange={(e) => {
                          setAssessmentActivity(e - 1);
                          setCurrentContentIndex(0);
                        }}
                      />
                    </Row>
                    <Spacer size={15} />
                    <InvestigationContentBuilder
                      // conciseForm
                      submissionVersion={submissionVersion}
                      currentContentIndex={currentContentIndex}
                      buildInPersonContent={assessmentStep?.mode === 'INPERSON'}
                      currentActivity={currentActivity}
                      currentStepId={currentActivity?.stepId || ''}
                      setCurrentContentIndex={setCurrentContentIndex}
                      investigationId={investigationId}
                      selectedStudentId={studentId}
                      classId={investigationForTeacherData?.getInvestigationByIdForTeacher.classId}
                      showNextStepButton={false}
                    />
                  </Col>
                  <Col span={45}>
                    <Steps current={current} onChange={onChangeStep} style={{right: 0}}>
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>

                    <Row justify="center" style={{ margin: '5px 0' }}>
                      <Col span={24}>{steps[current].content}</Col>
                    </Row>

                    <Row justify="end" style={{ margin: '5px 0' }}>
                      <Col span={24}>
                        {(isTeacherOrFacilitator || user.isAllowedGrading) &&
                        current === assessmentActivities.length - 1 ? (
                          <Button
                            block
                            size="large"
                            onClick={gradeReport}
                            loading={loadingGradeAssessment}
                            text="Grade Assessment"
                          />
                        ) : current === assessmentActivities.length && !nextAvailableForGrading ? (
                          <Button block size="large" onClick={() => handleGoToDashboard()} text="Go to Dashboard" />
                        ) : current === assessmentActivities.length && nextAvailableForGrading ? (
                          <Button block size="large" onClick={() => handleNextStudent()} text="Go to Next Student" />
                        ) : (
                          <Button
                            block
                            size="large"
                            onClick={handleFormNextStep}
                            loading={loadingGradeAssessment}
                            text="Next part"
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </InvestigationContent>
    </Layout>
  );
};

export default withRouter(AssessmentGradingPage);
