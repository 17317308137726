import styled from 'styled-components';
import { Button } from 'antd';

export const ClassInfosContainer = styled.div`
  width: 100%; // Ensure container takes the full width
  padding: 24px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1); // Optional: Add some shadow for better visibility
`;

export const Title = styled.h3`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`;

export const CircularButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-size: 18px;
`;
