import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { message, Rate, Row, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import ExpandableCard from '../../../shared/ExpandableCard';
import { IReflectionQuestionnaireContentValue } from '../../../types/investigation';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';
import { formatDateTime } from '../../../utils/date';
import { useStuckStudentButton } from '../../../utils/useStuckStudentButton';
import { ReactComponent as StuckIcon } from '../../../assets/stuck-icon.svg';
import { ReactComponent as StuckIconDisabled } from '../../../assets/stuck-icon-disabled.svg';

import * as S from './styles';
import { GQL_NotificationResponse } from '../../../types/notification';

interface IInvestigationQuestionnaireReflections {
  values: IReflectionQuestionnaireContentValue[];
  activityId: string;
  stepId: string;
  investigationId?: string;
  userId?: string;
  classId?: string;
  previousSubmissionActivityId?: string;
}

const InvestigationQuestionnaireReflections: React.FC<IInvestigationQuestionnaireReflections> = (props) => {
  const { values, activityId, stepId, investigationId, userId } = props;
  const amountOfReflections = values.length;
  const amountOfDoneReflections = values.filter((f) => f.rating).length;
  const history = useHistory();
  const { isStudent, user } = useAuth();
  const { search } = useLocation();
  const [isStuck, setIsStuck] = useStuckStudentButton({
    userId: user.id,
    classId: props.classId!,
    investigationId: investigationId!,
    stepId: stepId!,
    activityId,
  });

  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const [notifyTeacherAboutStuckUser, { loading: loadingNotify }] = useMutation(
    gqlSchema.InvestigationSchema.mutations.REASSIGNMENT.notifyTeacherAboutStuckUser,
    {
      onError: (err) => message.error(err.message),
      onCompleted: () => {
        message.success('The notification was sent to the teacher.');
        setIsStuck('true');
      },
    },
  );

  const [fetchNotifications, { loading: notificationsLoading }] = useLazyQuery<{
    getNotifications: GQL_NotificationResponse[];
  }>(gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const notificationFiltered = data.getNotifications.filter(
        (n) =>
          n.type === 'stuck_student_for_student_reflection_do_nothing' &&
          n.metadata.investigationId === investigationId &&
          n.metadata.classId === props.classId &&
          n.metadata.stepId === props.stepId &&
          n.metadata.activityId === props.previousSubmissionActivityId &&
          !!n.metadata.isValid,
      );
      notificationFiltered.length > 0 && setIsStuck('false');
    },
  });

  useSubscription<{ listenForNotifications: GQL_NotificationResponse }>(
    gqlSchema.NotificationSchema.subscriptions.listenForNotifications,
    {
      onSubscriptionData: ({ subscriptionData: { data } }) => {
        if (data?.listenForNotifications?.metadata) {
          try {
            const parsedMetadata = JSON.parse((data.listenForNotifications.metadata as unknown) as string);
            const constIsDoNothingNotification =
              data?.listenForNotifications.type === 'stuck_student_for_student_reflection_do_nothing' &&
              parsedMetadata.investigationId === investigationId &&
              parsedMetadata.classId === props.classId &&
              parsedMetadata.stepId === props.stepId &&
              parsedMetadata.activityId === props.previousSubmissionActivityId &&
              !!parsedMetadata.isValid;

            constIsDoNothingNotification && setIsStuck(false);
          } catch (err) {
            console.error('Error parsing metadata', err);
          }
        }
      },
    },
  );

  const studentList = useMemo(() => investigationData?.getInvestigationProgressSummary?.perStudents || [], [
    investigationData,
  ]);

  const student = studentList.find((student) => student.userId === userId);

  const findStudentName = useCallback(
    (id?: string) => {
      const student = studentList.find((student) => student.userId === id);

      if (!student) return 'Student 1';

      return student?.firstName + ' ' + student?.lastName;
    },
    [studentList],
  );

  const handleStuckConfirm = (e: React.MouseEvent<HTMLElement> | undefined) => {
    notifyTeacherAboutStuckUser({
      variables: {
        classId: props.classId,
        activityId: props.previousSubmissionActivityId,
        studentId: user.id,
        activityType: 'REFLECTION',
      },
    });
  };

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const name = student ? `${student.firstName} ${student.lastName}` : undefined;

  return (
    <S.Column span={24}>
      <S.TitleRow align="middle" justify="space-between">
        <Row align="middle">
          <h1>{name || 'Your'} Feedback Reflections</h1>
        </Row>

        {amountOfDoneReflections > 0 ? (
          <p>
            {amountOfDoneReflections} / {amountOfReflections} Completed
          </p>
        ) : (
          <S.HelpPopConfirm
            title={
              <S.HelpPopConfirmParagraph>
                Your teacher will receive a notification. <br /> Do you <strong>confirm</strong> that you want to report
                that you are stuck?
              </S.HelpPopConfirmParagraph>
            }
            overlayClassName="help-stuck-popconfirm-overlay"
            trigger={isStuck ? '' : 'click'}
            onConfirm={handleStuckConfirm}
            okText="Confirm"
            cancelText="Cancel"
            placement="left"
            icon={<></>}
          >
            <Tooltip
              title={
                isStuck ? 'The notification was already sent to the teacher. Wait a little bit.' : "Help, I'm stuck."
              }
              placement="left"
            >
              <S.HelpButton
                $disabled={isStuck || notificationsLoading}
                loading={loadingNotify}
                shape="circle"
                icon={isStuck ? <StuckIconDisabled /> : <StuckIcon />}
              />
            </Tooltip>
          </S.HelpPopConfirm>
        )}
      </S.TitleRow>

      {values?.map((value, i) => (
        <ExpandableCard
          key={value.peerReviewId}
          onClick={() =>
            history.push(
              `/student-investigation/${investigationId}/reflect-report/${stepId}/${activityId}/${value.review.studentId}${search}`,
              { userId },
            )
          }
          title={
            <S.TitleContainer>
              Feedback {isStudent ? i + 1 : 'By ' + findStudentName(value.review?.studentId)}
              {!!value.rating && <Rate disabled value={value.rating} />}
            </S.TitleContainer>
          }
          description={
            value.reflectionCompletedAt
              ? `${name || 'You'} completed this Feedback Reflection on ${formatDateTime(
                  value.reflectionCompletedAt,
                  'MM.dd.yyyy HH:mm aa',
                )}`
              : `${name || 'You'} ${name ? 'has' : 'have'} not completed this reflection.`
          }
          completed={!!value.reflectionCompletedAt}
        />
      ))}
    </S.Column>
  );
};

export default InvestigationQuestionnaireReflections;
