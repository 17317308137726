import React, { useEffect, useState } from 'react';
import Spacer from '../../../../shared/Spacer';
import { GQL_InvestigationBlock, ITextContentValue } from '../../../../types/investigation';
import { applySpanToContentValueText } from '../../../../utils/investigation';
import Editor from '../../../../shared/Editor';
import * as S from './styles';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { message } from 'antd';
import { useAuth } from '../../../../hooks/useAuth';

interface IMultipleChoiceQuestionPreview {
  block: GQL_InvestigationBlock;
  stepId?: string;
  activityId?: string;
  canvasIndex?: number;
  userId?: string;
  investigationId?: string;
  onSelected?: (selected: boolean) => void;
  presentationMode?: boolean;
}

const MultipleChoiceQuestionPreview = (props: IMultipleChoiceQuestionPreview) => {
  const { block, activityId, stepId, userId, onSelected } = props;
  const { user, isTeacher, isTeacherOrFacilitator, isStudent } = useAuth();
  const readOnly = userId !== user?.id && userId !== undefined;
  const question = (block.values[0] as ITextContentValue).text;
  const answers = block?.values.slice(1);
  const selectedAnswer = answers?.findIndex((item) => (item as ITextContentValue)?.selectedAnswer);
  const showCorrectAnswer = !props.presentationMode && (isTeacherOrFacilitator || isTeacher);

  const [answer, setAnswer] = useState<number>(selectedAnswer);

  useEffect(() => {
    setAnswer(selectedAnswer);
    if (selectedAnswer !== -1) {
      onSelected?.(false);
    }
  }, [selectedAnswer, onSelected]);

  const [submitMultipleChoice] = useMutation<
    { submitTextSubmission: { id: string } },
    { activityId: string; stepId: string; text: string }
  >(gqlSchema.InvestigationSchema.mutations.MULTIPLE_CHOICE.submitMultipleChoice, {
    onCompleted: () => {
      onSelected?.(true);
    },
    onError: (err) => message.error('There was an error submitting the comment: ' + err.message),
  });

  const handleAnswerSubmit = (number: number) => {
    if (!isStudent) return;
    setAnswer(number);
    if (activityId && stepId) {
      submitMultipleChoice({
        variables: {
          activityId: activityId,
          text: `${number + 1}`,
          stepId: stepId,
        },
      });
    }
  };

  return (
    <>
      <S.QuestionBlock>Questions</S.QuestionBlock>
      <Editor editable={false} listenToValue value={question} key={question} />
      <Spacer />
      {(answers as ITextContentValue[])?.map((value, index) => {
        const text = applySpanToContentValueText(value);
        return (
          <S.RadioContainer
            data-cy={`assessment-multiple-choice-answer`}
            key={index}
            defaultValue={index}
            showTargetAnswer={showCorrectAnswer}
            isTargetAnswer={!!value.targetAnswer}
          >
            <S.RadioButton
              disabled={readOnly}
              value={index}
              checked={answer === index}
              onClick={() => handleAnswerSubmit(index)}
            />
            <Editor editable={false} listenToValue value={text} key={text} />
          </S.RadioContainer>
        );
      })}
    </>
  );
};

export default MultipleChoiceQuestionPreview;
