import { Col, Row } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { BiBuildingHouse } from 'react-icons/bi';
import { GiTeacher } from 'react-icons/gi';
import * as S from './styles';
import { RiAdminFill, RiUserSettingsFill } from 'react-icons/ri';
import { CreateUserFlowStep } from '../../../types/user';
import { useAuth } from '../../../hooks/useAuth';
import { useCallback } from 'react';
import GenericCardButton from '../../../components/GenericCardButton';

interface Props {
  steps: CreateUserFlowStep[];
  setStep: Dispatch<SetStateAction<CreateUserFlowStep>>;
}

const UserType: React.FC<Props> = (props) => {
  const { steps, setStep } = props;
  const { user, isAdiSuperAdmin, isAdiAdmin, isOrganizationAdiAdmin } = useAuth();
  const renderButtonUser = useCallback(
    (stepindex, icon) => (
      <Col span={12} onClick={() => setStep(steps[stepindex])}>
        <GenericCardButton
          icon={icon}
          title={steps[stepindex].title}
          infoLineOne={steps[stepindex].infoLine1}
          infoLineTwo={steps[stepindex].infoLine2}
        />
      </Col>
    ),
    [setStep, steps],
  );
  return (
    <Row justify="center" gutter={[24, 24]} style={{ marginBottom: 20, marginTop: 10 }}>
      {isAdiSuperAdmin && (
        <>
          {renderButtonUser(1, <RiAdminFill />)}
          {renderButtonUser(2, <RiAdminFill />)}
        </>
      )}
      {(isAdiSuperAdmin || isAdiAdmin) && (
        <>
          {renderButtonUser(3, <BiBuildingHouse />)}
          {renderButtonUser(4, <GiTeacher />)}
          {renderButtonUser(7, <S.WorkshopIcon />)}
          {renderButtonUser(8, <GiTeacher />)}
        </>
      )}
      {isOrganizationAdiAdmin && (
        <>
          {renderButtonUser(5, <GiTeacher />)}
          {renderButtonUser(8, <GiTeacher />)}
          {user.subscription?.customLibrary && renderButtonUser(6, <RiUserSettingsFill />)}
        </>
      )}
    </Row>
  );
};

export default UserType;
