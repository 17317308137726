import React, { useState, useEffect } from 'react';
import { Modal, Progress, Button, Input, message, Spin } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_StudentSubmission } from '../../../../types/googleClassroom';

interface GradePopupProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  studentName: string;
  className: string;
  section: string;
  submissionData: GQL_StudentSubmission;
  assignmentName: string;
  assignmentLink: string;
  progress: number;
  token: string;
  courseId: string;
  courseWorkId: string;
  updateGradedSubmission: (submissionId: string, gradedScore: number) => void;
  submission: string; // Add this line
}

const GradePopup: React.FC<GradePopupProps> = ({
  visible,
  setVisible,
  studentName,
  className,
  section,
  submissionData,
  assignmentName,
  assignmentLink,
  progress,
  token,
  courseId,
  courseWorkId,
  updateGradedSubmission,
  submission, // Add this line
}) => {
  const [score, setScore] = useState<string>('');
  const [maxPoints, setMaxPoints] = useState<string>('');
  const [percentage, setPercentage] = useState<number>(0);
  const [grades, setGrades] = useState<{ [key: string]: string }>({});

  // Fetch grades data
  const { loading: gradesLoading, data: gradesData } = useQuery<{
    getCourseWorkSubmissionList: GQL_StudentSubmission[];
  }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: {
        token,
        courseId,
        courseWorkId,
      },
      onError: () => {
        message.error('Error fetching grades data');
      },
      onCompleted: (data) => {
        const gradedScores: { [key: string]: string } = {};
        data?.getCourseWorkSubmissionList.forEach((submission) => {
          gradedScores[submission.id] = (submission.draftGrade || '').toString(); // Convert to string
        });
        setGrades(gradedScores);
      },
    }
  );

  useEffect(() => {
    console.log('Submission Data:', submissionData);
    console.log('Grades Data:', gradesData);
  }, [submissionData, gradesData]);

  // Mutation to grade a submission
  const [gradeSubmission] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.GradeCourseWorkStudentSubmission,
    {
      onCompleted: (data) => {
        const { draftGrade, id } = data.gradeAttachmentCourseWorkStudentSubmission;
        const updatedGrades = { ...grades, [id]: draftGrade.toString() };
        setGrades(updatedGrades);
        updateGradedSubmission(id, draftGrade);
        message.success('Submission graded successfully');
        setVisible(false);
      },
      onError: (error) => {
        message.error('Failed to grade submission');
        console.error('Error grading submission:', error);
      },
    }
  );

  // Update score state when input changes
  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScore(e.target.value);
    // Calculate percentage when score changes
    calculatePercentage(e.target.value, maxPoints);
  };

  // Update percentage when score or maxPoints changes
  useEffect(() => {
    calculatePercentage(score, maxPoints);
  }, [score, maxPoints]);

  const handleSubmission = () => {
    if (score.trim() === '') {
      message.error('Please provide a valid score');
      return;
    }

    const numerator = parseFloat(score);
    const denominator = parseFloat(maxPoints);  
    if (isNaN(numerator) || isNaN(denominator)) {
      message.error('Invalid score or max points');
      return;
    }

    const assignedGrade = Math.floor((numerator / denominator) * 100); // Calculate assignedGrade

    const data = {
      draftGrade: numerator,
      assignedGrade,
    };

    gradeSubmission({
      variables: {
        token,
        courseId,
        courseWorkId,
        id: submissionData.id,
        data,
      },
    });
  };

  useEffect(() => {
    // Set score if it's already graded
    if (grades[submissionData.id]) {
      setScore(grades[submissionData.id]);
    } else {
      // If score is not available, set it to an empty string
      setScore('');
    }
  }, [grades, submissionData.id]);

  useEffect(() => {
    // Update maxPoints state from submissionData
    const maxPoints = submissionData?.submissionHistory?.find(history => history.gradeHistory)?.gradeHistory?.maxPoints || '';
    setMaxPoints(maxPoints.toString()); // Convert to string and provide a fallback value
  }, [submissionData]);

  const calculatePercentage = (score: string, maxPoints: string) => {
    const numerator = parseFloat(score);
    const maxPointsValue = parseFloat(maxPoints);
    if (!isNaN(numerator) && !isNaN(maxPointsValue) && maxPointsValue !== 0) {
        const calculatedPercentage = (numerator / maxPointsValue) * 100;
      setPercentage(calculatedPercentage);
    } else {
      setPercentage(0);
    }
  };

  let progressColor = '#f5222d';
  if (percentage >= 80) {
    progressColor = '#52c41a';
  } else if (percentage >= 50) {
    progressColor = '#faad14';
  }

  if (gradesLoading) {
    return <Spin />;
  }

  // Integrate gradesData into the component
  console.log(gradesData); // You can use gradesData here if needed

  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>Submission Summary</div>}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
      width={600}
      bodyStyle={{ padding: '30px 50px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '60px', width: '180px' }}>
            <b>Student Name:</b>
            <br />
            {studentName}
          </div>
          <div style={{ width: '180px', marginRight: '60px' }}>
            <b>Submission:</b>
            <br />
            {submissionData.state === 'TURNED_IN' ? 'Graded' : 'Not Graded'}
          </div>
          <div style={{ width: '180px' }}>
            <b>Section:</b>
            <br />
            {section}
          </div>
        </div>
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '60px', width: '180px' }}>
            <b>Class Name:</b>
            <br />
            {className}
          </div>
          <div style={{ marginLeft: '-60px' }}>
            <b>Assignment Name:</b>
            <br />
            {assignmentName}
          </div>
          <div style={{ width: '180px', opacity: 0 }}>Placeholder</div>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <b>Assignment Link:</b>
          <br />
          <a href={assignmentLink}>{assignmentLink}</a>
        </div>
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          <b>Score:</b>{' '}
          <Input
            value={score}
            onChange={handleScoreChange}
            style={{ width: '70px', marginRight: '5px' }}
          />
          /
          <Input
            value={maxPoints}
            onChange={(e) => setMaxPoints(e.target.value)}
            style={{ width: '70px', marginLeft: '5px' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Progress
            type="circle"
            percent={percentage}
            width={150}
            format={() => `${percentage}%`}
            strokeColor={progressColor}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Button style={{ marginRight: '20px' }} onClick={() => setVisible(false)}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmission}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default GradePopup;

