import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Route as ReactDOMRoute, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { menuActions } from '../redux/modules';

type RouteType =
  | 'teacherRoute'
  | 'studentRoute'
  | 'teacherAssistantRoute'
  | 'adiRoute'
  | 'organizationRoute'
  | 'authenticatedRoute'
  | 'onlyUnauthenticatedRoute'
  | 'advancedSearchRoute'
  | 'writerRoute'
  | 'allowDisabled'
  | 'googleTeacherRoute'
  | 'googleStudentRoute';

interface IRoute extends RouteProps {
  component?: any;
  routeTypes: RouteType[];
  root?: string;
}

const Route: React.FC<IRoute> = ({ routeTypes = [], component: Component, root = '', ...rest }) => {
  const {
    isLogged,
    isStudent,
    isTeacherOrFacilitator,
    isTeacherAssistant,
    isAdiAdmin,
    isAdiSuperAdmin,
    isOrganizationAdiAdmin,
    isSubscriptionUser,
    isWriter,
    isFacilitator,
    isAdvancedSearch,
    user,
    isUserDisabled,
    isUserSuspended,
    isGoogleTeacher,
    isGoogleStudent,
  } = useAuth();
  const dispatch = useDispatch();
  const onlyUnauthenticated = useMemo(
    () => routeTypes.some((routeType: string) => routeType === 'onlyUnauthenticatedRoute'),
    [routeTypes],
  );

  const userDisabled = useMemo(() => isUserDisabled || isUserSuspended, [isUserDisabled, isUserSuspended]);
  

  const checkPermission = useCallback(
    (routeType: string) => {
      switch (routeType) {
        case 'adiRoute':
          return isAdiSuperAdmin || isAdiAdmin;
        case 'studentRoute':
          return isStudent;
        case 'teacherRoute':
          return (isTeacherOrFacilitator || isFacilitator || isSubscriptionUser) && !userDisabled;
        case 'teacherAssistantRoute':
          return isTeacherAssistant && !userDisabled;
        case 'organizationRoute':
          return isOrganizationAdiAdmin;
        case 'advancedSearchRoute':
          return isAdvancedSearch && !userDisabled;
        case 'writerRoute':
          return isWriter && !userDisabled;
        case 'authenticatedRoute':
          return isLogged && !userDisabled;
        case 'onlyUnauthenticatedRoute':
          return !isLogged;
        case 'allowDisabled':
          return true;
        case 'googleTeacherRoute':
          return isGoogleTeacher && !userDisabled;
        case 'googleStudentRoute':
          return (isGoogleStudent && !userDisabled)||isGoogleTeacher;
        default:
          return true;
      }
    },
    [
      isAdiSuperAdmin,
      isStudent,
      isTeacherOrFacilitator,
      isTeacherAssistant,
      isOrganizationAdiAdmin,
      isAdiAdmin,
      isLogged,
      isWriter,
      isFacilitator,
      isAdvancedSearch,
      isSubscriptionUser,
      userDisabled,
      isGoogleStudent,
      isGoogleTeacher,
    ],
  );

  let hasAccess = useMemo(() => {
    let allow = routeTypes.length === 0 ? true : false;
    routeTypes.forEach((routeType: string) => {
      if (checkPermission(routeType)) allow = true;
    });
    
    return allow;
  }, [checkPermission, routeTypes]);

  let redirectRoute: string;
  if (isAdvancedSearch) redirectRoute = '/advanced-search/home';
  else if (isStudent) redirectRoute = '/student-dashboard';
  else if (isGoogleTeacher) redirectRoute = '/googleclassroom-teacher-dashboard';
  else if (isGoogleStudent && !isGoogleTeacher) redirectRoute = '/googleclassroom-students-dashboard';
  else if (isTeacherOrFacilitator || isTeacherAssistant) redirectRoute = '/teacher-dashboard';
  else if (isAdiSuperAdmin || isAdiAdmin) redirectRoute = '/adi-dashboard';
  else if (isOrganizationAdiAdmin && !isTeacherOrFacilitator)
    redirectRoute = !user.subscription?.reportPermission ? '/organization-users' : '/organization-dashboard';
  else if (isWriter && !isTeacherOrFacilitator) redirectRoute = '/organization-library';

  const defaultDisabledUserRoute = '/billing-information';

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (onlyUnauthenticated) {
          if (isLogged) {
            return <Redirect to={redirectRoute} />;
          } else {
            return <Component />;
          }
        }

        if (hasAccess) {
          dispatch(menuActions.setCurrentMenu(root || location.pathname));
          return <Component />;
        }

        if (userDisabled) {
          return <Redirect to={defaultDisabledUserRoute} />;
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
