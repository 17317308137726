import React, { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { useAuth } from '../../../../hooks/useAuth';
import GCreateClass from '../../../../shared/GCreateClass';
import * as S from './styles';

const AddClassButton = () => {
  const [visible, setVisible] = useState(false);
  const { isFacilitator } = useAuth();
  return (  
    <>
      <S.Card onClick={() => setVisible(true)} data-cy="components-classes-row-create-class-button">
        <S.Icon>
          <BsPlus />
        </S.Icon>
        <S.Title>Create {isFacilitator ? 'Course or Event' : ' Class'}</S.Title>
        {/* <S.Info>
          Create a {isFacilitator ? 'course or event' : ' class'} with {isFacilitator && 'workshop'} Assignments
        </S.Info> */}
        {/* <S.Info>spanning a whole year</S.Info> */}
      </S.Card>
      <GCreateClass visible={visible} setVisible={setVisible} />
    </>
  );
};

export default AddClassButton;
