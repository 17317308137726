import { gql } from '@apollo/client';

export const CLASS = {
  CREATE: {
    createClass: gql`
      mutation CreateClass($data: CreateClassInput!) {
        createClass(data: $data) {
          id
          name
          startDate
          endDate
          numberOfGroups
          students {
            userId
            inviteId
            email
            firstName
            lastName
            group
          }
        }
      }
    `,
  },
  EDIT: {
    editClass: gql`
      mutation editClass($data: EditClassInput!) {
        editClass(data: $data) {
          id
          name
          startDate
          endDate
          numberOfGroups
          students {
            userId
            inviteId
            email
            firstName
            lastName
            group
          }
        }
      }
    `,
    editAssessmentMode: gql`
      mutation editAssessmentMode($data: EditAssessmentMode!) {
        editAssessmentMode(data: $data)
      }
    `,
    editClassInvestigation: gql`
      mutation editClassInvestigation($data: EditClassInvestigationInput!) {
        editClassInvestigation(data: $data) {
          id
          resubmissionsLimit
        }
      }
    `,
    addStudentsToClass: gql`
      mutation AddStudentsToClass($data: AddStudentsToClassInput!) {
        addStudentsToClass(data: $data) {
          id
          name
          startDate
          endDate
          numberOfGroups
          students {
            userId
            inviteId
            email
            firstName
            lastName
            group
          }
        }
      }
    `,
    assignInvestigations: gql`
      mutation assignInvestigationToClasses(
        $classIds: [String]!
        $investigationId: String!
        $duration: InvestigationDuration
        $mode: String
      ) {
        assignInvestigationToClasses(
          classIds: $classIds
          investigationId: $investigationId
          duration: $duration
          mode: $mode
        ) {
          id
          investigationId
          title
          grade
          class
          classId
          completion
          description
          dueDate
        }
      }
    `,
    assignAssessments: gql`
      mutation assignAssessmentToClasses(
        $classIds: [String]!
        $assessmentId: String!
        $duration: AssessmentDuration
        $mode: String
      ) {
        assignAssessmentToClasses(classIds: $classIds, assessmentId: $assessmentId, duration: $duration, mode: $mode) {
          id
          investigationId
          title
          grade
          class
          classId
          completion
          description
          dueDate
        }
      }
    `,
    transferClass: gql`
      mutation TransferClass($classId: String!, $newTeacherId: String!) {
        transferClass(classId: $classId, newTeacherId: $newTeacherId)
      }
    `,
    transferAllClasses: gql`
      mutation TransferAllClasses($teacherId: String!, $newTeacherId: String!) {
        transferAllClasses(teacherId: $teacherId, newTeacherId: $newTeacherId)
      }
    `,
  },
  MANAGE_GROUP: {
    changeStudentGroup: gql`
      mutation ChangeStudentGroup($data: ChangeStudentGroupInput!) {
        changeStudentGroup(data: $data) {
          id
          name
          startDate
          endDate
          numberOfGroups
          students {
            userId
            inviteId
            email
            firstName
            lastName
            group
          }
        }
      }
    `,
    removeStudentFromClass: gql`
      mutation RemoveStudentFromClass($data: RemoveStudentFromClassInput!) {
        removeStudentFromClass(data: $data) {
          id
          name
          startDate
          endDate
          numberOfGroups
          students {
            userId
            inviteId
            email
            firstName
            lastName
            group
          }
        }
      }
    `,
  },
  DELETE: {
    removeClass: gql`
      mutation RemoveClass($data: RemoveClassInput!) {
        removeClass(data: $data)
      }
    `,
  },
  ANNOUNCEMENT: {
    createAnnouncement: gql`
      mutation CreateAnnouncement($data: ClassAnnouncementInput!) {
        createAnnouncement(data: $data) {
          id
          classId
          text
          createdAt
        }
      }
    `,
    editAnnouncement: gql`
      mutation EditAnnouncement($data: ClassAnnouncementEditInput!) {
        editAnnouncement(data: $data) {
          id
          classId
          text
          createdAt
        }
      }
    `,
    deleteAnnouncement: gql`
      mutation DeleteAnnouncement($id: String!) {
        deleteAnnouncement(id: $id)
      }
    `,
  },
};
