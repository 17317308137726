/* eslint-disable max-lines */
import React, { useMemo } from 'react';
import { Switch } from 'react-router-dom';

// Utils
import Route from './utils/Route';
import { useAuth } from './hooks/useAuth';

// First Touch
import App from './App';
import HomePage from './components/HomePage';
import LoginPage from './components/LoginPage';
import LoginSSOPage from './components/LoginSSOPage';
import SignUpPage from './components/SignUpPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import ChangePassword from './components/ChangePasswordPage';
import LogoutPage from './components/LogoutPage';

// Advanced Search
import AdvancedSearchLoginPage from './components/AdvancedSearchLoginPage';
import AdvancedSearchHome from './components/AdvancedSearchHome';
import GradeReviewPage from './components/GradeReview';
import InvestigationDetailsReviewPage from './components/InvestigationDetailsReviewPage';
import GradeDataReviewListPage from './components/GradeDataReviewPage';
import AdvancedSearchUploadCSVPage from './components/AdvancedSearchUploadCSVPage';

// General Routes
import TagDetailsPage from './components/TagDetailsPage';
import ManageTagsPage from './components/ManageTagsPage';
import NotificationList from './components/NotificationList';
import MessageListPage from './components/MessageListPage';

// Teacher Routes
import TeacherUsers from './components/TeacherUsers';
import ManageTeacherTransferPage from './components/ManageTeacherTransferPage';
import TeacherInsights from './components/TeacherInsights';
import InvestigationTypePage from './components/InvestigationTypePage';
import InvestigationWalkthroughPage from './components/InvestigationWalkthroughPage';
import InvestigationSettingsPage from './components/InvestigationSettingsPage';
import AssignInvestigationPage from './components/AssignInvestigationPage';
import InvestigationDetailsPage from './components/InvestigationDetailsPage';
import InvestigationLibraryPage from './components/InvestigationLibraryPage';
import TeacherDashboard from './components/TeacherDashboard';
import GoogleClassroomTeachersDashboardPage from './components/GoogleClassroomTeachersDashboardPage';
// Investigation
import InvestigationSettings from './components/InvestigationSettings';
import InvestigationSummary from './components/InvestigationSummary';
import AssessmentGradingPage from './components/AssessmentGradingPage';
import InvestigationGradingPage from './components/InvestigationGradingPage';
import InvestigationTeacherDetailsPage from './components/InvestigationTeacherDetailsPage';
import InvestigationPresentationPage from './components/InvestigationPresentationPage';
import InvestigationGradeReportPage from './components/InvestigationGradeReportPage';
import InvestigationFilePreview from './components/StudentInvestigation/InvestigationFilePreview';

// Class
import ClassDashboard from './components/ClassDashboard';
import GoogleClassDashboard from './components/GoogleClassDashboard';
// Student Routes
import StudentsDetails from './components/StudentsDetails';
import StudentInvestigationDetails from './components/StudentInvestigationDetails';
import StudentClassDetail from './components/StudentClassDetail';
import StudentDueDates from './components/StudentDueDates';
import StudentDashboard from './components/StudentDashboard';
import StudentInvestigation from './components/StudentInvestigation';

// Peer Review
import PeerReviewInvestigationPage from './components/PeerReviewInvestigationPage';
import PeerReviewReportInvestigationPage from './components/PeerReviewReportInvestigationPage';

// Comments Routes
import CommentsInvestigationPage from './components/CommentsInvestigationPage';
import BestCommentsInvestigationPage from './components/BestCommentsInvestigationPage';

// ADI Admin Routes
import AdiDashboardPage from './components/AdiDashboardPage';
import OrganizationSyncList from './components/OrganizationSyncList';
import SyncList from './components/SyncList';
import SyncDetailsPage from './components/SyncDetails';
import UserList from './components/UserList';
import WalkthroughListPage from './components/WalkthroughListPage';
import WalkthroughDetailsPage from './components/WalkthroughDetailsPage';
import UserDetailsPage from './components/UserDetailsPage';
import OrganizationDetailsPage from './components/OrganizationDetailsPage';
import OrganizationList from './components/OrganizationList';
import AdiAssessmentPage from './components/AdiAssessmentPage';
import AdiAssessmentTemplatesPage from './components/AdiAssessmentTemplatePage';
import AdiAssessmentListPage from './components/AdiAssessmentListPage';
import AdiTemplatesPage from './components/AdiTemplatesPage';
import AdiInvestigationListPage from './components/AdiInvestigationListPage';
import AdiEditInvestigationCorePage from './components/AdiEditInvestigationCorePage';
import AdiEditInvestigationPreviewPage from './components/AdiEditInvestigationPreviewPage';
import AdiEditInvestigationActivitiesPage from './components/AdiEditInvestigationActivitiesPage';
import AdiEditInvestigationStagesPage from './components/AdiEditInvestigationStagesPage';
import AdiInvestigationsPage from './components/AdiInvestigationsPage';

// Others
import ConceptEvaluationInvestigationPage from './components/ConceptEvaluationInvestigationPage';
import BestConceptPeerReviewInvestigationPage from './components/BestConceptPeerReviewInvestigationPage';
import ReflectReportInvestigationPage from './components/ReflectReportInvestigationPage';
import TeacherProfilePage from './components/TeacherProfilePage';
import StudentProfilePage from './components/StudentProfilePage';
import GenericProfilePage from './components/GenericProfilePage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsOfServicePage from './components/TermsOfServicePage/TermsOfServicePage';
import AssessmentTypePage from './components/AssessmentTypePage';

import AssessmentLibraryPage from './components/AssessmentLibraryPage';
import AssessmentSummaryDashboard from './components/AssessmentSummaryDashboard';
import AssessmentResultsSummary from './components/AssessmentSummaryDashboard/AssessmentResultsSummary';
import AssessmentGradeReportPage from './components/AssessmentGradeReportPage';

// Checkout
import CreatePaymentIntentPage from './components/Checkout/PaymentIntent';
import SubscriptionPackageSelectionPage from './components/SubscriptionPackageSelectionPage';
import BillingInformationPage from './components/BillingInformation';
import SubscriptionPackageSelectionPublicPage from './components/SubscriptionPackageSelectionPage/SubscriptionPackageSelectionPublicPage';
import WhitelistDomainPage from './components/WhitelistDomainPage';

//google
import GoogleAssignmentCorePage from './components/GoogleAssignmentCorePage';
import GoogleAssignmentSummaryDasboard from './components/GoogleAssignmentSummaryDasboard';
import GoogleClassroomTeacherAssignmentPage from './components/GoogleClassroomTeacherAssignmentPage'
// import GoogleTeachersAssignmentDashboard from './components/GoogleTeachersAssignmentDashboard';
import GoogleTeacherAssignmentSummary from './components/GoogleTeacherAssignmentSummary';
//googlestudents 
import GoogleClassroomStudentsDashboardPage from './components/GoogleClassroomStudentsDashboardPage';
import GoogleStudentsClassDashboard from './components/GoogleStudentsClassDashboard';
import AssignmentSummaryPopup from './components/GoogleClassroomStudentsDashboardPage/GActiveAssignmentsRow/AssignmentSummaryPopup';
import GoogleStudentsAssignmentDueDate from './components/GoogleStudentsAssignmentDueDate';

export default () => {
  const { isTeacherOrFacilitator, isStudent } = useAuth();
  const ProfileComponent = useMemo(() => {
    if (isTeacherOrFacilitator) return TeacherProfilePage;
    if (isStudent) return StudentProfilePage;
    return GenericProfilePage;
  }, [isTeacherOrFacilitator, isStudent]);

  return (
    <App>
      <Switch>
        {/* First Touch */}
        <Route path="/" component={HomePage} routeTypes={['onlyUnauthenticatedRoute']} exact />
        <Route path="/login" component={LoginPage} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route path="/sso" component={LoginSSOPage} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route path="/session-expired" component={LogoutPage} routeTypes={['authenticatedRoute']} />
        <Route path="/register/:token" component={SignUpPage} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route path="/forgot-password" component={ForgotPasswordPage} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route path="/change-password/:token" component={ChangePassword} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route path="/terms-and-conditions" component={TermsOfServicePage} routeTypes={['onlyUnauthenticatedRoute']} />
        <Route
          path="/pricing"
          component={SubscriptionPackageSelectionPublicPage}
          routeTypes={['onlyUnauthenticatedRoute']}
          exact
        />

        {/* Advanced Search Routes */}
        <Route
          path="/advanced-search/:version/login"
          component={AdvancedSearchLoginPage}
          routeTypes={['onlyUnauthenticatedRoute']}
        />
        <Route
          path="/advanced-search/:version/home"
          component={AdvancedSearchHome}
          routeTypes={['advancedSearchRoute']}
          exact
        />
        <Route
          path="/advanced-search/:grade/contentType/:type/:investigationId"
          component={InvestigationDetailsReviewPage}
          routeTypes={['advancedSearchRoute']}
        />
        <Route
          path="/advanced-search/:version/:grade/grade"
          component={GradeReviewPage}
          routeTypes={['advancedSearchRoute']}
        />
        <Route
          path="/advanced-search/:version/:grade/contentType/:type"
          component={GradeDataReviewListPage}
          routeTypes={['advancedSearchRoute']}
        />
        <Route path="/upload-csv" component={AdvancedSearchUploadCSVPage} exact routeTypes={['adiRoute']} />
        <Route path="/whitelist-domain" component={WhitelistDomainPage} exact routeTypes={['adiRoute']} />

        {/* General Routes */}
        <Route path="/manage-tags/:tagId" component={TagDetailsPage} routeTypes={['authenticatedRoute']} />
        <Route path="/manage-tags" component={ManageTagsPage} routeTypes={['authenticatedRoute']} />
        <Route path="/profile" component={ProfileComponent} routeTypes={['authenticatedRoute']} />
        <Route path="/notifications" component={NotificationList} routeTypes={['authenticatedRoute']} />
        <Route path="/messages" component={MessageListPage} routeTypes={['authenticatedRoute']} />
        <Route path="/transfer-teacher/:token" component={ManageTeacherTransferPage} routeTypes={[]} />

        <Route
          path="/checkout-confirmation/:priceId"
          component={CreatePaymentIntentPage}
          routeTypes={['authenticatedRoute', 'allowDisabled']}
        />

        <Route
          path="/billing-information"
          component={BillingInformationPage}
          routeTypes={['authenticatedRoute', 'allowDisabled']}
        />

        {/* Teacher Routes */}
        <Route path="/teacher-users" component={TeacherUsers} routeTypes={['teacherRoute']} exact />
        <Route path="/teacher-insights/:type" component={TeacherInsights} routeTypes={['teacherRoute']} />
        <Route path="/teacher-investigation" component={InvestigationTypePage} routeTypes={['teacherRoute']} exact />
        <Route
          path="/teacher-investigation/:type/:investigationId/preview"
          component={InvestigationWalkthroughPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-investigation/:type/:investigationId/settings"
          component={InvestigationSettingsPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-investigation/:type/:investigationId/assign"
          component={AssignInvestigationPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-investigation/:type/:investigationId"
          component={InvestigationDetailsPage}
          routeTypes={['teacherRoute']}
        />
        <Route path="/teacher-investigation/:type" component={InvestigationLibraryPage} routeTypes={['teacherRoute']} />

        <Route path="/teacher-assessment" component={AssessmentTypePage} routeTypes={['teacherRoute']} exact />

        <Route path="/teacher-assessment/:type" component={AssessmentLibraryPage} routeTypes={['teacherRoute']} />

        <Route
          path="/teacher-dashboard"
          component={TeacherDashboard}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
          exact
        />
        <Route
          path="/teacher-dashboard/class/:classId/student/:studentId/summary"
          component={StudentInvestigationDetails}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
          strict
        />
        <Route
          path="/teacher-dashboard/class/:classId/student/:studentId"
          component={StudentsDetails}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-summary/:investigationId/settings"
          component={InvestigationSettings}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-summary/:investigationId"
          component={InvestigationSummary}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/assessment-summary/:assessmentId/results"
          component={AssessmentResultsSummary}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
          exact
        />
        <Route
          path="/teacher-dashboard/assessment-summary/:assessmentId/:submissionVersion?"
          component={AssessmentSummaryDashboard}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
          exact
        />
        <Route
          path="/teacher-dashboard/assessment-presentation/:investigationId"
          component={InvestigationPresentationPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/assessment-details/:investigationId/grade/:studentId/:submissionVersion?"
          component={AssessmentGradingPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-details/:investigationId/grade/:studentId"
          component={InvestigationGradingPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-details/:investigationId/students/:studentId/:submissionVersion?"
          component={InvestigationTeacherDetailsPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-details/:investigationId/:studentId/:submissionVersion?"
          component={InvestigationTeacherDetailsPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-details/:investigationId/:submissionVersion?"
          component={InvestigationTeacherDetailsPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/assessment-details/:investigationId/:submissionVersion?"
          component={InvestigationTeacherDetailsPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/teacher-dashboard/investigation-presentation/:investigationId"
          component={InvestigationPresentationPage}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route path="/teacher-dashboard/student/:studentId" component={StudentsDetails} routeTypes={['teacherRoute']} />
        <Route
          path="/teacher-dashboard/class/:classId/assign-investigation/:type/:investigationId/preview"
          component={InvestigationWalkthroughPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-investigation/:type/:investigationId/settings"
          component={InvestigationSettingsPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-investigation/:type/:investigationId/assign"
          component={AssignInvestigationPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-investigation/:type/:investigationId"
          component={InvestigationDetailsPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-investigation/:type"
          component={InvestigationLibraryPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-investigation"
          component={InvestigationTypePage}
          routeTypes={['teacherRoute']}
          strict
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-assessment/:type/:investigationId/assign"
          component={AssignInvestigationPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-assessment/:type"
          component={InvestigationLibraryPage}
          routeTypes={['teacherRoute']}
        />
        <Route
          path="/teacher-dashboard/class/:classId/assign-assessment"
          component={InvestigationTypePage}
          routeTypes={['teacherRoute']}
          strict
        />
        <Route
          path="/teacher-dashboard/class/:id"
          component={ClassDashboard}
          routeTypes={['teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/manage-subscription"
          component={SubscriptionPackageSelectionPage}
          routeTypes={['teacherRoute', 'allowDisabled']}
        />

        {/* Student Routes */}
        <Route path="/student-dashboard/class/:id" component={StudentClassDetail} routeTypes={['studentRoute']} />
        <Route path="/student-dashboard/due-dates" component={StudentDueDates} routeTypes={['studentRoute']} />
        <Route path="/student-dashboard" component={StudentDashboard} routeTypes={['studentRoute']} />
        <Route
          path="/student-investigation/:investigationId/grade"
          component={InvestigationGradeReportPage}
          routeTypes={['studentRoute']}
        />
        <Route
          path="/student-assessment/:assessmentId/grade"
          component={AssessmentGradeReportPage}
          routeTypes={['studentRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/concept-evaluation/:stepId/:activityId/:studentId/:evaluatorId"
          component={ConceptEvaluationInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/peer-review/:stepId/:activityId/:studentId/:submissionVersion?"
          component={PeerReviewInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/peer-review-report/:stepId/:activityId/:studentId"
          component={PeerReviewReportInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/concept-evaluation/:stepId/:activityId/:studentId"
          component={ConceptEvaluationInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/concept-rating/:stepId/:activityId/:studentId"
          component={ConceptEvaluationInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/best-concept-of-group/:stepId/:activityId/:studentId"
          component={ConceptEvaluationInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/peer-review-best-concept/:stepId/:activityId/:studentId/:submissionVersion?"
          component={BestConceptPeerReviewInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/reflect-report/:stepId/:activityId/:studentId"
          component={ReflectReportInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/comments/:activityId"
          component={CommentsInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/best-concept-comments/:activityId/:submissionVersion?"
          component={BestCommentsInvestigationPage}
          routeTypes={['studentRoute', 'teacherRoute', 'teacherAssistantRoute']}
        />
        <Route
          path="/student-investigation/:investigationId/preview-file"
          component={InvestigationFilePreview}
          routeTypes={['studentRoute']}
          exact
        />
        <Route path="/student-investigation/:id" component={StudentInvestigation} routeTypes={['studentRoute']} exact />
        <Route path="/student-assessment/:id" component={StudentInvestigation} routeTypes={['studentRoute']} exact />

        {/* Adi Admin Routes */}
        <Route path="/adi-dashboard" component={AdiDashboardPage} routeTypes={['adiRoute']} exact />
        <Route path="/adi-organization-sync" component={OrganizationSyncList} routeTypes={['adiRoute']} exact />
        <Route
          path="/sync-list"
          root="Sync"
          component={SyncList}
          routeTypes={['adiRoute', 'teacherRoute', 'organizationRoute']}
          exact
        />
        <Route
          path="/sync/:id"
          root="Sync"
          component={SyncDetailsPage}
          routeTypes={['adiRoute', 'teacherRoute', 'organizationRoute']}
          exact
        />
        <Route path="/adi-users" component={UserList} routeTypes={['adiRoute']} exact />
        <Route path="/walkthrough" component={WalkthroughListPage} routeTypes={['adiRoute']} exact />
        <Route
          path="/walkthrough/:id"
          root="/walkthrough"
          component={WalkthroughDetailsPage}
          routeTypes={['adiRoute']}
        />
        <Route path="/adi-users/:userId" root="/adi-users" component={UserDetailsPage} routeTypes={['adiRoute']} />
        <Route
          path="/adi-organizations/:organizationId"
          root="/adi-organizations"
          component={OrganizationDetailsPage}
          routeTypes={['adiRoute']}
        />
        <Route path="/adi-organizations" component={OrganizationList} routeTypes={['adiRoute']} />
        <Route
          path="/adi-assessments/templates"
          root="/adi-assessments"
          component={AdiAssessmentTemplatesPage}
          routeTypes={['adiRoute']}
        />
        <Route
          path="/adi-assessments/assessments-list"
          root="/adi-assessments"
          component={AdiAssessmentListPage}
          routeTypes={['adiRoute']}
        />
        <Route
          path="/adi-assessments/investigation-list"
          component={AdiInvestigationListPage}
          root="/adi-assessments"
          routeTypes={['adiRoute']}
        />
        <Route
          path="/adi-assessments/edit/:investigationId/core"
          root="/adi-assessments"
          component={AdiEditInvestigationCorePage}
          routeTypes={['adiRoute']}
        />
        <Route
          path="/adi-assessments/edit/:investigationId/settings/:stageId/parts"
          root="/adi-assessments"
          component={AdiEditInvestigationActivitiesPage}
          routeTypes={['adiRoute']}
        />
        <Route
          path="/adi-assessments/edit/:investigationId/settings"
          root="/adi-assessments"
          component={AdiEditInvestigationStagesPage}
          routeTypes={['adiRoute']}
        />
        <Route path="/adi-assessments" component={AdiAssessmentPage} routeTypes={['adiRoute']} />
        <Route
          path="/adi-investigations/templates"
          component={AdiTemplatesPage}
          root="/adi-investigations"
          routeTypes={['adiRoute', 'organizationRoute', 'writerRoute']}
        />
        <Route
          path="/adi-investigations/investigation-list"
          component={AdiInvestigationListPage}
          root="/adi-investigations"
          routeTypes={['adiRoute', 'organizationRoute', 'writerRoute']}
        />
        <Route
          path="/adi-investigations/edit/:investigationId/core"
          root="/adi-investigations"
          component={AdiEditInvestigationCorePage}
          routeTypes={['adiRoute', 'organizationRoute', 'writerRoute']}
        />
        <Route
          path="/adi-investigations/edit/:investigationId/preview"
          root="/adi-investigations"
          component={AdiEditInvestigationPreviewPage}
          routeTypes={['adiRoute', 'organizationRoute', 'writerRoute']}
        />
        <Route
          path="/adi-investigations/edit/:investigationId/stages/:stageId/activities"
          root="/adi-investigations"
          component={AdiEditInvestigationActivitiesPage}
          routeTypes={['adiRoute', 'organizationRoute', 'writerRoute']}
        />
        <Route
          path="/adi-investigations/edit/:investigationId/stages"
          root="/adi-investigations"
          component={AdiEditInvestigationStagesPage}
          routeTypes={['adiRoute', 'organizationRoute', 'writerRoute']}
        />
        <Route path="/adi-investigations" component={AdiInvestigationsPage} routeTypes={['adiRoute']} />
        <Route path="/adi-workshop-investigations" component={AdiInvestigationsPage} routeTypes={['adiRoute']} />

        {/* Organization Admin Routes */}
        <Route path="/organization-dashboard" component={AdiDashboardPage} routeTypes={['organizationRoute']} exact />
        <Route path="/organization-kpi" component={AdiDashboardPage} routeTypes={['organizationRoute']} exact />
        <Route path="/organization-users" component={UserList} routeTypes={['organizationRoute']} exact />
        <Route
          path="/organization-details"
          component={OrganizationDetailsPage}
          routeTypes={['organizationRoute']}
          exact
        />
        <Route path="/organization/user/:userId" component={UserDetailsPage} routeTypes={['organizationRoute']} exact />
        <Route
          path="/organization-library"
          component={AdiInvestigationsPage}
          routeTypes={['organizationRoute', 'writerRoute']}
        />
        <Route
          path="/investigation-presentation/:investigationId"
          component={InvestigationPresentationPage}
          routeTypes={['organizationRoute', 'writerRoute', 'teacherRoute']}
        />
        {/* <Route
          path="/googleclassroom-teacher-dashboard/assignments"
          component={GoogleTeachersAssignmentDashboard}
          routeTypes={[]}
        /> */}
         <Route
          path="/googleclassroom-teacher-dashboard/:courseName/:courseId/assignment-summary/:courseWorkId"
          component={GoogleTeacherAssignmentSummary}
          routeTypes={['googleTeacherRoute']}
        />
        <Route
          path="/googleclassroom-teacher-dashboard/class/:courseId/assignment-summary"
          component={GoogleAssignmentSummaryDasboard}
          routeTypes={['googleTeacherRoute']}
        />
        <Route
          path="/googleclassroom-teacher-dashboard/class/:courseId/create-assignment"
          component={GoogleAssignmentCorePage}
          routeTypes={['googleTeacherRoute']}
        />
        <Route
          path="/googleclassroom-teacher-dashboard/class/:id"
          component={GoogleClassDashboard}
          routeTypes={['googleTeacherRoute']}
        />
        <Route
          path="/googleclassroom-teacher-dashboard"
          component={GoogleClassroomTeachersDashboardPage}
          routeTypes={['googleTeacherRoute']}
        />
         <Route
          path="/googleclassroom-students-assignmentduedate"
          component={GoogleStudentsAssignmentDueDate}
          routeTypes={['googleStudentRoute']}
        />
        <Route
          path="/googleclassroom-students-dashboard/class/:courseId/:courseWorkId/:Id"
          component={AssignmentSummaryPopup}
          routeTypes={['googleStudentRoute']}
        />
        <Route
          path="/googleclassroom-students-dashboard/class/:id"
          component={GoogleStudentsClassDashboard}
          routeTypes={['googleStudentRoute']}
        />
        <Route
          path="/googleclassroom-students-dashboard"
          component={GoogleClassroomStudentsDashboardPage}
          routeTypes={['googleStudentRoute']}
        />
        <Route
          path="/googleclassroom-teacher-assignment/class/:courseId/edit-assignment/:courseWorkId"
          component={GoogleAssignmentCorePage}
          routeTypes={['googleTeacherRoute']}
        />
        <Route
          path="/googleclassroom-teacher-assignment"
          component={GoogleClassroomTeacherAssignmentPage}
          routeTypes={['googleTeacherRoute']}
        />
      </Switch>
    </App>
  );
};
