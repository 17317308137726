import React, { useState } from 'react';
import { Col, Row, Tooltip } from 'antd';
import Modal from 'antd/lib/modal';
import { useCallback } from 'react';
import { GiTeacher } from 'react-icons/gi';
import { RiAdminFill, RiUserFill } from 'react-icons/ri';
import GenericCardButton from '../components/GenericCardButton';
import { useAuth } from './useAuth';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../gql/schema';
import { BiBuildingHouse } from 'react-icons/bi';
import client from '../gql/api';
import { GQL_MeResponse } from '../types/login';
import { GrWorkshop } from 'react-icons/gr';

interface useRoleSelectionModalProps {
  setActiveRole?: (role: string) => void;
}

const useRoleSelectionModal = (args?: useRoleSelectionModalProps) => {
  const { setActiveRole } = args || {};
  const { setActiveRole: setActiveRoleAuth, user } = useAuth();
  const [hasOpenModal, setHasOpenModal] = useState(false);

  const [updatePreferredRole] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.updatePreferredRole);
  const updateRoleLocally = setActiveRoleAuth || setActiveRole;

  const openModal = useCallback(
    (payload?: { user: GQL_MeResponse }) => {
      if (hasOpenModal) return;

      const loggedUser = payload?.user || user;
      const notShowOneStepAway = (loggedUser.roles.includes('teacher') && loggedUser.roles.includes('facilitator')) || (loggedUser.roles.includes('google_teacher')) || (loggedUser.roles.includes('google_student'));
      const cardSpanSize = loggedUser.roles.length > 2 || loggedUser.roles.length > 1 ? 8 : 12;
      const modalSize = loggedUser.roles.length > 2 || loggedUser.roles.length > 1 ? 1200 : 800;
             

      setHasOpenModal(true);

      const handleCardClick = async ({ modal, role }: { modal: any; role: string }) => {
        await updatePreferredRole({
          variables: { role },
        });
        await client.resetStore();
        updateRoleLocally(role);
        modal.destroy();
      };

      const m = Modal.info({
        title: <RoleTitle>Choose your Role</RoleTitle>,
        width: modalSize,
        closable: true,
        afterClose: () => setHasOpenModal(false),
        maskClosable: true,
        icon: null,
        zIndex: 1200,
        okButtonProps: {
          style: {
            display: 'none',
          },
        },
        content: (
          <>
            <RoleSubTitle>
              Your account has access to multiple roles in the Learning Hub. By selecting one of the options below, the
              Learning Hub will set that role as the default for your account. You will be able to switch between your
              user roles through your profile later.
            </RoleSubTitle>

            <Row justify="start" align="middle">
              <Col span={24}>
                <h3>Your Available Roles</h3>
              </Col>
            </Row>

            <Row justify="start" gutter={[24, 24]} style={{ marginBottom: 20, marginTop: 10 }}>
              {loggedUser?.roles.includes('teacher') && (
                <Col span={cardSpanSize}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'teacher' })}
                    icon={<GiTeacher />}
                    title="Log In as Teacher"
                    iconColor="#20BC89"
                    infoLineOne="Teacher account allows you to create class, assign investigations, view and grade student work."
                  />
                </Col>
              )}
              {loggedUser?.roles.includes('teacher_assistant') && (
                <Col span={cardSpanSize}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'teacher_assistant' })}
                    icon={<RiUserFill />}
                    title="Log In as Teacher Assistant"
                    iconColor="#CFB452"
                    infoLineOne="Teacher assistants can see classes from a teacher and also student submissions for the class."
                  />
                </Col>
              )}
              {loggedUser?.roles.includes('student') && (
                <Col span={cardSpanSize}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'student' })}
                    icon={<RiUserFill />}
                    title="Log In as Student"
                    iconColor="#CFB452"
                    infoLineOne="Student account allows you to participate in assigned investigations or workshops."
                  />
                </Col>
              )}
              {loggedUser?.roles.includes('organization_admin') && (
                <Col span={cardSpanSize}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'organization_admin' })}
                    icon={<BiBuildingHouse />}
                    iconColor="#2F0DFF"
                    title="Log In as Organization Admin"
                    infoLineOne="Organization Admin account allow you to manage users under your organization."
                  />
                </Col>
              )}
              {loggedUser?.roles.includes('facilitator') && (
                <Col span={cardSpanSize}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'facilitator' })}
                    icon={<FacilitatorWorkshopIcon />}
                    title="Log In as Facilitator"
                    infoLineOne="Facilitator account allows you to create courses and assign workshops."
                  />
                </Col>
              )}
              {loggedUser?.roles.includes('adi_super_admin') && (
                <Col span={cardSpanSize}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'adi_super_admin' })}
                    icon={<RiAdminFill />}
                    iconColor="#c5e934"
                    title="Log In as ADI Super Admin"
                    infoLineOne="ADI Super Admin account allows you to manage everything in the system."
                  />
                </Col>
              )}
              {loggedUser?.roles.includes('adi_admin') && (
                <Col span={8}>
                  <GenericCardButton
                    onClick={() => handleCardClick({ modal: m, role: 'adi_admin' })}
                    icon={<RiAdminFill />}
                    iconColor="#51dfef"
                    title="Log In as ADI Admin"
                    infoLineOne="ADI Admin account allows you to manage subscriptions, invite new users and create investigations."
                  />
                </Col>
              )}
            </Row>

            {!notShowOneStepAway && (
              <>
                <Row justify="start" align="middle">
                  <Col span={24}>
                    <h3>One Step Away to Unlock</h3>
                  </Col>
                </Row>

                <Row justify="start" gutter={[24, 24]} style={{ marginBottom: 0, marginTop: 10 }}>
                  {!loggedUser?.roles.includes('facilitator') && (
                    <Tooltip overlayClassName="modal-only" title="Click the 'Unlock Now' button to unlock this role.">
                      <Col span={cardSpanSize}>
                        <GenericCardButton
                          disabled
                          icon={<DisabledWorkshopIcon />}
                          title="Log In as Facilitator"
                          infoLineOne="Facilitator account allows you to create courses and assign workshops."
                        />
                      </Col>
                    </Tooltip>
                  )}

                  {!loggedUser?.roles.includes('teacher') && (
                    <Tooltip overlayClassName="modal-only" title="Click the 'Unlock Now' button to unlock this role.">
                      <Col span={cardSpanSize}>
                        <GenericCardButton
                          disabled
                          icon={<GiTeacher />}
                          title="Log In as Teacher"
                          infoLineOne="Teacher account allows you to create class, assign investigations, view and grade student work."
                        />
                      </Col>
                    </Tooltip>
                  )}

                  <Col span={cardSpanSize}>
                    <GenericCardButton
                      primary
                      onClick={() =>
                        window.open(
                          'https://share.hsforms.com/1WIzZgPCwR7Snqj6opPDvTQ5gbsq',
                          '_blank',
                          'noreferrer noopener',
                        )
                      }
                      icon={<RiAdminFill />}
                      title="Unlock Now"
                      infoLineOne="By clicking here you will be one-step-away to have all roles you want."
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        ),
      });
    },
    [hasOpenModal, updatePreferredRole, updateRoleLocally, user],
  );

  return { openModal };
};

export default useRoleSelectionModal;

const RoleSubTitle = styled.p`
  color: #767676;
  text-align: center;
`;

const RoleTitle = styled.h3`
  text-align: center;
  margin: 0;
`;

const DisabledWorkshopIcon = styled(GrWorkshop)`
  path {
    stroke: #e6e6e6;
  }
`;

const FacilitatorWorkshopIcon = styled(GrWorkshop)`
  path {
    stroke: #dc365e;
  }
`;
