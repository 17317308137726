/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Row, Dropdown, Table, Menu, Radio, message, Popconfirm } from 'antd';
import { FilterDropdownProps, TablePaginationConfig, ColumnsType } from 'antd/lib/table/interface';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiChevronDown, FiDownload, FiFilter, FiSearch, FiPlus } from 'react-icons/fi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import AddUser from '../../../shared/AddUser';
import Button from '../../../shared/Button';
import TableSearchBox from '../../../shared/TableSearchBox';
import { GQL_UserDetailsResponse } from '../../../types/profile';
import { GQL_UserDetailsResponsePaginated, GQL_UsersListFilterInput } from '../../../types/user';
import { centerAlign, Filter, Breakpoint } from '../../../utils/antd';
import { formatDateTime } from '../../../utils/date';
import { themeConfig } from '../../../utils/theme';
import { roles } from '../../../utils/roles';
import { accTypePackages } from '../../../constants/enums/SubscriptionPackageType';
import * as S from './styles';
import { pageSizeBase } from '../../../types/pagination';
import { IMPERSONATING, AUTH_TOKEN_KEY, useAuth } from '../../../hooks/useAuth';
import { RadioChangeEvent } from 'antd/lib/radio';
import { GQL_SortingInput, SortOptions } from '../../../types/sorting';
import ModalConfirm from '../../../shared/ModalConfirm';
import TagsModal from '../../../shared/TagsModal';
import { TagResponse } from '../../../types/tags';
import { formatDistance } from 'date-fns';
import TableCheckboxGroup from '../../../shared/TableCheckboxGroup/TableCheckboxGroup';
import TagInput from '../../../shared/TagInput';
import { Role } from '../../../constants/enums/Role';

const TableBreakPoint: Breakpoint[] = ['lg'];

type ICurrentUsers = RouteComponentProps<
  {
    investigationId: string;
    stageId: string;
  },
  any,
  {
    initialActivityId?: string;
  }
>;

interface ICurrentUsersProps extends ICurrentUsers {
  setActiveKey: (tab: string) => void;
}

const CurrentUsers: React.FC<ICurrentUsersProps> = (props) => {
  // hooks
  const history = useHistory();
  const user = useAuth();
  const existingParams = new URLSearchParams(history.location.search);
  const refName = useRef<HTMLInputElement>(null);
  const refEmail = useRef<HTMLInputElement>(null);

  // states
  const [hasImpersonateFeatureAvailable, setHasImpersonateFeatureAvailable] = useState(false);

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [deleteUsersVisible, setDeleteUsersVisible] = useState(false);
  const [addTagsVisible, setAddTagsVisible] = useState(false);
  const [removeTagsVisible, setRemoveTagsVisible] = useState(false);
  const [subscriptionFilter, setSubscriptionFilter] = React.useState(existingParams.get('sub') || 'all');

  const [searchEmailVisible, setSearchEmailVisible] = useState(false);
  const [emailToFilter, setEmailToFilter] = useState(existingParams.get('email') || '');
  const [emailToFilterInternal, setEmailToFilterInternal] = useState(emailToFilter);
  const [emailToFilterEmpty, setEmailToFilterEmpty] = useState<boolean>(false);

  const [searchNameVisible, setSearchNameVisible] = useState(false);
  const [nameToFilter, setNameToFilter] = useState(existingParams.get('name') || '');
  const [nameToFilterInternal, setNameToFilterInternal] = useState(nameToFilter);
  const [nameToFilterEmpty, setNameToFilterEmpty] = useState<boolean>(false);

  const [searchRolesVisible, setSearchRolesVisible] = useState(false);
  const [rolesToFilterURL, setRolesToFilterURL] = useState<string[]>(existingParams.getAll('roles'));
  const [rolesToFilter, setRolesToFilter] = useState<string[]>(rolesToFilterURL || []);
  const [rolesToFilterInternal, setRolesToFilterInternal] = useState(rolesToFilter);
  const [rolesToFilterEmpty, setRolesToFilterEmpty] = useState<boolean>(false);
  const [rolesFilters, setRolesFilters] = useState<Filter[]>(roles);

  const [searchAccountTypeVisible, setSearchAccountTypeVisible] = useState(false);
  const [accountTypeToFilterURL, setAccountTypeToFilterURL] = useState<string[]>(existingParams.getAll('accType'));
  const [accountTypeToFilter, setAccountTypeToFilter] = useState<string[]>(accountTypeToFilterURL || []);
  const [accountTypeToFilterInternal, setAccountTypeToFilterInternal] = useState(accountTypeToFilter);
  const [accountTypeToFilterEmpty, setAccountTypeToFilterEmpty] = useState<boolean>(false);
  const [accountTypeFilters] = useState<Filter[]>(accTypePackages);

  const [searchTagsVisible, setSearchTagsVisible] = useState(false);
  const [tagsToFilterURL, setTagsToFilterURL] = useState<string[]>(existingParams.getAll('tags'));
  const [tagsToFilter, setTagsToFilter] = useState<string[]>(tagsToFilterURL || []);
  const [tagsToFilterInternal, setTagsToFilterInternal] = useState(tagsToFilter);
  const [tagsToFilterEmpty, setTagsToFilterEmpty] = useState<boolean>(false);
  const [tagsFilters, setTagsFilters] = useState<Filter[]>([]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.ReactText[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>();
  const [sorting, setSorting] = useState<GQL_SortingInput[]>([]);

  // handlers
  const [fetchUsers, { data, loading }] = useLazyQuery<
    { getAllUsersByFilter: GQL_UserDetailsResponsePaginated },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.getAllUsersByFilter, {
    onCompleted: (data) => {
      setPagination({
        current: data.getAllUsersByFilter.pagination.page,
        pageSize: data.getAllUsersByFilter.pagination.size,
        total: data.getAllUsersByFilter.pagination.totalCount,
      });
    },
    onError: (err) => {
      message.error('There was an error loading users: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchImpersonateFeatureAvailability] = useLazyQuery<{
    getImpersonateFeatureAvailability: { available: boolean };
  }>(gqlSchema.HomeSchema.queries.getImpersonateFeatureAvailability, {
    onCompleted: (data) => setHasImpersonateFeatureAvailable(data.getImpersonateFeatureAvailability.available),
    onError: (err) => message.error('Error fetching impersonate feature: ' + err.message),
  });


  const [fetchImpersonateFeatureToken] = useLazyQuery<{
    getImpersonateTokenByEmail: { refresh_token: string; access_token: string };
  }>(gqlSchema.HomeSchema.queries.getImpersonateTokenByEmail, {
    onCompleted: (data) => {
      if (!data.getImpersonateTokenByEmail) {
        return;
      }
      const token = data.getImpersonateTokenByEmail.access_token;
      sessionStorage.setItem(AUTH_TOKEN_KEY, token);
      sessionStorage.setItem(IMPERSONATING, String(true));

      const url = new URL(window.location.origin);
      url.searchParams.set('reloadTime', Date.now().toString());
      window.location.href = url.toString();
    },
    onError: (err) => message.error('Error fetching impersonate token: ' + err.message),
  });

  const [exportUsersByFilter, { loading: loadingUsersFile }] = useLazyQuery<
    { exportUsersByFilter: string },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.exportUsersByFilter, {
    onCompleted: (data) => {
      if (data.exportUsersByFilter) window.open(data.exportUsersByFilter, '_blank');
    },
    onError: (err) => {
      message.error('There was an error loading users: ' + err.message || 'Unexpected Error');
    },
    fetchPolicy: 'network-only',
  });

  const [deleteUsers, { loading: loadingDeleteUsers }] = useMutation(gqlSchema.UserSchema.mutations.EDIT.deleteUsers, {
    refetchQueries: ['GetAllUsersByFilter'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      message.success('Users deleted successfully');
      setDeleteUsersVisible(false);
      setSelectedRowKeys([]);
    },
    onError: (err) => {
      message.error('Error deleting users: ' + err.message);
    },
  });

  const [addTagToUsers, { loading: loadingAddTagToUsers }] = useMutation(
    gqlSchema.TagsSchema.mutations.addTagsToUsers,
    {
      refetchQueries: ['GetAllUsersByFilter'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success('Tags added to users successfully');
        setAddTagsVisible(false);
        setSelectedRowKeys([]);
      },
      onError: (err) => {
        message.error('Error deleting users: ' + err.message);
      },
    },
  );

  const [removeTagsFromUsers, { loading: loadingRemoveTagsFromUsers }] = useMutation(
    gqlSchema.TagsSchema.mutations.removeTagsFromUsers,
    {
      refetchQueries: ['GetAllUsersByFilter'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        message.success('Tags removed from users successfully');
        setRemoveTagsVisible(false);
        setSelectedRowKeys([]);
      },
      onError: (err) => {
        message.error('Error deleting users: ' + err.message);
      },
    },
  );

  const [fetchTags] = useLazyQuery<{ getTags: TagResponse[] }>(gqlSchema.TagsSchema.queries.getTags, {
    onCompleted: (data) => {
      setTagsFilters(data?.getTags?.map((t) => ({ text: t.tag, value: t.id, color: t.color })));
    },
    onError: (err) => {
      message.error('There was an error loading tags: ' + err.message || 'Unexpected Error');
    },
  });

  const onChangePresetFilter = (e: RadioChangeEvent) => {
    setSubscriptionFilter(e.target.value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleDeleteUsers = () => {
    deleteUsers({
      variables: {
        userIds: selectedRowKeys,
      },
    });
  };

  const handleAddTags = ({ tags }: { tags: TagResponse[] }) => {
    addTagToUsers({
      variables: {
        userIds: selectedRowKeys,
        tagIds: tags.map((t) => t.id),
      },
    });
  };

  const handleRemoveTags = ({ tags }: { tags: TagResponse[] }) => {
    removeTagsFromUsers({
      variables: {
        userIds: selectedRowKeys,
        tagIds: tags.map((t) => t.id),
      },
    });
  };

  const handleImpersonateClick = useCallback(
    async (email: string) => {
      fetchImpersonateFeatureToken({
        variables: {
          email,
        },
      });
    },
    [fetchImpersonateFeatureToken],
  );

  const getFilterPaginationParams = useMemo(() => {
    return {
      page: pagination?.current,
      size: pagination?.pageSize,
    };
  }, [pagination]);

  const getFilterParams = useMemo(() => {
    const data: GQL_UsersListFilterInput = {
      pagination: {
        page: getFilterPaginationParams.page || 1,
        size: getFilterPaginationParams.size || pageSizeBase,
      },
      partOfName: nameToFilter,
      partOfEmail: emailToFilter,
      roles: rolesToFilterURL,
      accountTypes: accountTypeToFilterURL,
      tagIds: tagsToFilterURL,
    };

    if ((user.isAdiSuperAdmin || user.isAdiAdmin) && subscriptionFilter !== 'all') {
      data.isTrial = subscriptionFilter === 'trial';
      data.isExpired = subscriptionFilter === 'expired';
    }

    if (sorting) data.sorting = sorting;

    return data;
  }, [
    getFilterPaginationParams.page,
    getFilterPaginationParams.size,
    nameToFilter,
    emailToFilter,
    rolesToFilterURL,
    tagsToFilterURL,
    user.isAdiSuperAdmin,
    user.isAdiAdmin,
    subscriptionFilter,
    accountTypeToFilterURL,
    sorting,
  ]);

  const manageSortings = useCallback(
    (sort: GQL_SortingInput) => {
      const sorts = sorting.filter((s) => s.field !== sort.field) || [];
      sorts.push(sort);
      setSorting(sorts);
    },
    [sorting],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig, filters, sorter) => {
      const order = sorter.order ? (sorter.order === 'ascend' ? SortOptions.ASC : SortOptions.DESC) : undefined;
      manageSortings({ field: sorter.columnKey, order });
      setPagination(pagination);
      setRolesToFilter(filters.roles || []);
      setTagsToFilter(filters.tags || []);
    },
    [manageSortings],
  );

  const onViewMore = useCallback(
    (userRow: GQL_UserDetailsResponse) => {
      history.push({
        pathname:
          user.isAdiSuperAdmin || user.isAdiAdmin ? `/adi-users/${userRow.id}` : `/organization/user/${userRow.id}`,
        state: {
          name: userRow.name,
        },
        search: history.location.search,
      });
    },
    [history, user],
  );

  const [editUserRoles] = useMutation<any, { data: { userId: string; roles: string[] } }>(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.editUserRoles,
    {
      refetchQueries: ['GetAllUsersByFilter'],
      onCompleted: () => {
        message.success('`STARTER` tag successfully removed from user.');
      },
      onError: () => {
        message.error('An error occurred while removing `STARTER` tag from user.');
      },
    },
  );

  const handleStarterTagRemoval = useCallback(
    (id: string, roles: string[]) => {
      editUserRoles({
        variables: {
          data: {
            roles: roles.filter((r: string) => r !== 'non_enterprise'),
            userId: id,
          },
        },
      });
    },
    [editUserRoles],
  );

  const exportUsers = useCallback(() => {
    exportUsersByFilter({
      variables: {
        data: { ...getFilterParams, pagination: undefined },
      },
    });
  }, [getFilterParams, exportUsersByFilter]);

  // memoized
  const columns: ColumnsType<GQL_UserDetailsResponse> = useMemo(
    () => [
      {
        title: 'User Email',
        className: 'components-userlist-currentusers-useremail-column',
        align: centerAlign,
        dataIndex: 'email',
        key: 'u.email',
        width: '200px',
        responsive: TableBreakPoint,
        sorter: true,
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox
            {...filterProps}
            ref={refEmail}
            onClearFilters={() => {
              setEmailToFilterEmpty(true);
              setEmailToFilterInternal('');
              setEmailToFilter('');
            }}
            updateSearch={() => {
              setEmailToFilterEmpty(!!emailToFilterInternal);
              setEmailToFilter(emailToFilterInternal);
            }}
            selectedKeys={[emailToFilterInternal]}
            setSelectedKeys={(value) => setEmailToFilterInternal(value[0] as string)}
          />
        ),
        filterIcon: (filtered: boolean) => {
          const existingParams = new URLSearchParams(history.location.search);
          return (
            <S.SearchIcon $searchVisible={searchEmailVisible}>
              <FiSearch
                size={16}
                style={{ color: filtered || emailToFilter || existingParams.get('email')! ? '#1890ff' : undefined }}
              />
            </S.SearchIcon>
          );
        },
        onFilter: (value: string | number | boolean) => {
          if (!value) {
            setEmailToFilterEmpty(true);
            setEmailToFilter('');
            setEmailToFilterInternal('');
          } else {
            setEmailToFilterEmpty(false);
            setEmailToFilter(value.toString());
            setEmailToFilterInternal(value.toString());
          }
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchEmailVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refEmail && refEmail.current) {
                refEmail.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Name',
        className: 'components-userlist-currentusers-username-column',
        sorter: true,
        align: centerAlign,
        dataIndex: 'name',
        key: 'u.firstName',
        width: '200px',
        filterDropdown: (filterProps: FilterDropdownProps) => (
          <TableSearchBox
            {...filterProps}
            ref={refName}
            onClearFilters={() => {
              setNameToFilterEmpty(true);
              setNameToFilterInternal('');
              setNameToFilter('');
              setPagination({ ...pagination, current: 1 });
            }}
            updateSearch={() => {
              setNameToFilterEmpty(!!nameToFilterInternal);
              setNameToFilter(nameToFilterInternal);
              setPagination({ ...pagination, current: 1 });
            }}
            selectedKeys={[nameToFilterInternal]}
            setSelectedKeys={(value) => setNameToFilterInternal(value[0] as string)}
          />
        ),
        filterIcon: (filtered: boolean) => {
          const existingParams = new URLSearchParams(history.location.search);
          return (
            <S.SearchIcon $searchVisible={searchNameVisible}>
              <FiSearch
                size={16}
                style={{ color: filtered || nameToFilter || existingParams.get('name')! ? '#1890ff' : undefined }}
              />
            </S.SearchIcon>
          );
        },
        onFilter: (value: string | number | boolean) => {
          if (!value) {
            setNameToFilterEmpty(true);
            setNameToFilter('');
            setNameToFilterInternal('');
            setPagination({ ...pagination, current: 1 });
          } else {
            setNameToFilterEmpty(false);
            setNameToFilter(value.toString());
            setNameToFilterInternal(value.toString());
            setPagination({ ...pagination, current: 1 });
          }
          return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchNameVisible(visible);
          if (visible) {
            setTimeout(() => {
              if (refName && refName.current) {
                refName.current.select();
              }
            }, 100);
          }
        },
      },
      {
        title: 'User Roles',
        className: 'components-userlist-currentusers-useroles-column',
        dataIndex: 'roles',
        align: centerAlign,
        width: '200px',
        responsive: TableBreakPoint,
        filterDropdownVisible: searchRolesVisible,
        filterDropdown: () => (
          <TableCheckboxGroup
            value={rolesToFilterInternal}
            dataset={rolesFilters}
            onCheckboxGroupChange={(values) => {
              const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
              setRolesToFilterEmpty(false);
              setRolesToFilterInternal(unique as string[]);
            }}
            onCheckboxGroupReset={() => {
              setRolesToFilterEmpty(true);
              setRolesToFilterInternal([]);
              setRolesToFilter([]);
              setPagination({ ...pagination, current: 1 });
              setTimeout(() => setSearchRolesVisible(false), 100);
            }}
            onCheckboxGroupOk={() => {
              setRolesToFilterEmpty(rolesToFilterInternal.length === 0);
              setRolesToFilter(rolesToFilterInternal);
              setPagination({ ...pagination, current: 1 });
              setTimeout(() => setSearchRolesVisible(false), 100);
            }}
          />
        ),
        filterIcon: (filtered: boolean) => {
          const existingParams = new URLSearchParams(history.location.search);
          return (
            <S.SearchIcon $searchVisible={searchRolesVisible}>
              <FiFilter
                size={18}
                style={{
                  color: filtered || rolesToFilter.length > 0 || existingParams.get('roles')! ? '#1890ff' : undefined,
                }}
              />
            </S.SearchIcon>
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchRolesVisible(visible);
        },
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return record.roles.map((role: string) => {
            const roleItem = roles.find((r: Filter) => r.value === role);
            return (
              <S.TagButton
                text={roleItem?.text || ''}
                display="inline"
                background={roleItem?.color}
                shape="round"
                key={role}
                minHeight={24}
              />
            );
          });
        },
      },
      {
        title: 'Account Type',
        className: 'components-userlist-currentusers-account-type-column',
        dataIndex: 'accountType',
        align: centerAlign,
        width: '200px',
        responsive: TableBreakPoint,
        filterDropdownVisible: searchAccountTypeVisible,
        filterDropdown: () => (
          <TableCheckboxGroup
            value={accountTypeToFilterInternal}
            dataset={accountTypeFilters}
            onCheckboxGroupChange={(values) => {
              const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
              setAccountTypeToFilterEmpty(false);
              setAccountTypeToFilterInternal(unique as string[]);
            }}
            onCheckboxGroupReset={() => {
              setAccountTypeToFilterEmpty(true);
              setAccountTypeToFilterInternal([]);
              setAccountTypeToFilter([]);
              setPagination({ ...pagination, current: 1 });
              setTimeout(() => setSearchAccountTypeVisible(false), 100);
            }}
            onCheckboxGroupOk={() => {
              setAccountTypeToFilterEmpty(accountTypeToFilterInternal.length === 0);
              setAccountTypeToFilter(accountTypeToFilterInternal);
              setPagination({ ...pagination, current: 1 });
              setTimeout(() => setSearchAccountTypeVisible(false), 100);
            }}
          />
        ),
        filterIcon: (filtered: boolean) => {
          const existingParams = new URLSearchParams(history.location.search);
          return (
            <S.SearchIcon $searchVisible={searchAccountTypeVisible}>
              <FiFilter
                size={18}
                style={{
                  color:
                    filtered || accountTypeToFilter.length > 0 || existingParams.get('accType')!
                      ? '#1890ff'
                      : undefined,
                }}
              />
            </S.SearchIcon>
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchAccountTypeVisible(visible);
        },
        render: (text: string, record: GQL_UserDetailsResponse) => {
          const accTypeItem = accTypePackages.find((acc) => acc.text === record.accountType);
          return (
            <S.TagButton
              text={accTypeItem?.text || ''}
              display="inline"
              background={accTypeItem?.color}
              shape="round"
              key={record.accountType}
              minHeight={24}
            />
          );
        },
      },
      {
        title: 'Tags',
        className: 'components-userlist-currentusers-tags-column',
        dataIndex: 'tags',
        align: centerAlign,
        width: '200px',
        responsive: TableBreakPoint,
        filterDropdownVisible: searchTagsVisible,
        filterDropdown: () => (
          <TableCheckboxGroup
            value={tagsToFilterInternal}
            dataset={tagsFilters}
            onCheckboxGroupChange={(values) => {
              const unique = values?.filter((item, pos, self) => self.indexOf(item) === pos) || [];
              setTagsToFilterEmpty(false);
              setTagsToFilterInternal(unique as string[]);
            }}
            onCheckboxGroupReset={() => {
              setTagsToFilterEmpty(true);
              setTagsToFilterInternal([]);
              setTagsToFilter([]);
              setPagination({ ...pagination, current: 1 });
              setTimeout(() => setSearchTagsVisible(false), 100);
            }}
            onCheckboxGroupOk={() => {
              setTagsToFilterEmpty(tagsToFilterInternal.length === 0);
              setTagsToFilter(tagsToFilterInternal);
              setPagination({ ...pagination, current: 1 });
              setTimeout(() => setSearchTagsVisible(false), 100);
            }}
          />
        ),
        filterIcon: (filtered: boolean) => {
          const existingParams = new URLSearchParams(history.location.search);
          return (
            <S.SearchIcon $searchVisible={searchTagsVisible}>
              <FiFilter
                size={18}
                style={{
                  color: filtered || tagsToFilter.length > 0 || existingParams.get('tags')! ? '#1890ff' : undefined,
                }}
              />
            </S.SearchIcon>
          );
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
          setSearchTagsVisible(visible);
        },
        render: (text: string, record: { tags?: TagResponse[] }) => {
          return <TagInput existingUserTags={record.tags} editable={false} />;
        },
      },
      {
        title: 'SSO',
        align: centerAlign,
        width: '200px',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return record.source;
        },
      },
      {
        title: 'Last Login',
        align: centerAlign,
        width: '200px',
        responsive: TableBreakPoint,
        render: (text: string, record: GQL_UserDetailsResponse) => {
          return formatDateTime(record.lastLogin, 'MM.dd.yyyy HH:mm:ss');
        },
      },
      {
        title: 'Subscription End Date',
        className: 'components-userlist-currentusers-enddate-column',
        align: centerAlign,
        width: '200px',
        responsive: TableBreakPoint,
        sorter: true,
        key: 'sub.endDate',
        dataIndex: 'subscription',
        render: (text: string, record: GQL_UserDetailsResponse) => {
          if (!record?.subscription?.endDate) return <p style={{ marginBottom: 0 }}>No Subscription End Date</p>;
          const originalDate = formatDateTime(record?.subscription?.endDate, 'MM.dd.yyyy');
          const distanceDate = formatDistance(record?.subscription?.endDate, new Date(), { addSuffix: true });
          return (
            <>
              <p style={{ marginBottom: 0 }}>{originalDate}</p>
              <p style={{ marginBottom: 0 }}>{distanceDate}</p>
            </>
          );
        },
      },
      {
        title: 'User Details',
        align: centerAlign,
        fixed: 'right',
        width: hasImpersonateFeatureAvailable ? '300px' : '200px',
        render: (text: string, record: GQL_UserDetailsResponse) => {
          const isInvalidStarterAccount = !!record.organization && record.roles.includes('non_enterprise');
          return (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Button
                  data-cy={`components-userlist-currentuser-view-details-button-${record?.email?.split?.('@')?.[0]}`}
                  text="View Details"
                  onClick={() => onViewMore(record)}
                  block
                />
              </Col>
              {/* BUTTON TO IMPERSONATE USER */}
              {hasImpersonateFeatureAvailable ? (
                <Col span={24}>
                  <Popconfirm
                    placement="topRight"
                    title={
                      <div style={{ textAlign: 'center' }}>
                        <S.Info>
                          By confirming you will be querying and mutating {record.firstName} {record.lastName} this
                          user's data as you were him/her.
                        </S.Info>
                      </div>
                    }
                    onConfirm={() => handleImpersonateClick(record.email)}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Button theme={themeConfig.error} text="Impersonate" block />
                  </Popconfirm>
                </Col>
              ) : null}
              {/* BUTTON TO REMOVE STARTER TAG FROM USER */}
              {(user.activeRole === Role.ADI_ADMIN || user.activeRole === Role.ADI_SUPER_ADMIN) &&
                isInvalidStarterAccount ? (
                <Col span={24}>
                  <Popconfirm
                    placement="topRight"
                    title={
                      <div style={{ textAlign: 'center' }}>
                        <S.Info>Please confirm that you want to remove the starter tag from this user</S.Info>
                      </div>
                    }
                    onConfirm={() => handleStarterTagRemoval(record.id, record.roles)}
                    okText="Confirm"
                    cancelText="Cancel"
                  >
                    <Button theme={themeConfig.orangePrimary} text="Remove Starter Tag" block />
                  </Popconfirm>
                </Col>
              ) : null}
            </Row>
          );
        },
      },
    ],
    [
      searchRolesVisible,
      searchTagsVisible,
      hasImpersonateFeatureAvailable,
      emailToFilterInternal,
      history.location.search,
      searchEmailVisible,
      emailToFilter,
      nameToFilterInternal,
      pagination,
      searchNameVisible,
      nameToFilter,
      searchAccountTypeVisible,
      accountTypeFilters,
      accountTypeToFilterInternal,
      accountTypeToFilter.length,
      rolesToFilterInternal,
      rolesFilters,
      rolesToFilter.length,
      tagsToFilterInternal,
      tagsFilters,
      tagsToFilter.length,
      onViewMore,
      handleImpersonateClick,
      user.activeRole,
      handleStarterTagRemoval,
    ],
  );

  const exportMenu = useMemo(
    () => (
      <Menu>
        <Menu.Item key="1" onClick={exportUsers}>
          .csv
        </Menu.Item>
      </Menu>
    ),
    [exportUsers],
  );

  const actionsMenu = useMemo(
    () => (
      <Menu>
        {user.isAdiSuperAdmin && (
          <Menu.Item key="1" onClick={() => setDeleteUsersVisible(true)}>
            Delete Users
          </Menu.Item>
        )}
        <Menu.Item key="2" onClick={() => setAddTagsVisible(true)}>
          Add Tags
        </Menu.Item>
        <Menu.Item key="2" onClick={() => setRemoveTagsVisible(true)}>
          Remove Tags
        </Menu.Item>
      </Menu>
    ),
    [user.isAdiSuperAdmin],
  );

  const options = useMemo(
    () => [
      { label: 'All Subscriptions', value: 'all' },
      { label: 'Trial Subscriptions', value: 'trial' },
      { label: 'Paid Subscriptions', value: 'paid' },
      { label: 'Expired Subscriptions', value: 'expired' },
    ],
    [],
  );

  // effects
  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  useEffect(() => {
    fetchImpersonateFeatureAvailability();
  }, [fetchImpersonateFeatureAvailability]);

  useEffect(() => {
    if (user.isOrganizationAdiAdmin) {
      setRolesFilters(roles.filter((a) => a.value !== 'adi_super_admin' && a.value !== 'adi_admin'));
    }
  }, [user]);

  useEffect(() => {
    fetchUsers({
      variables: {
        data: getFilterParams,
      },
    });
  }, [fetchUsers, getFilterParams]);

  useEffect(() => {
    const existingParams = new URLSearchParams(history.location.search);

    if (emailToFilter) {
      existingParams.set('email', emailToFilter || existingParams.get('email')!);
    } else if (emailToFilterEmpty) {
      existingParams.delete('email');
    }

    if (nameToFilter) {
      existingParams.set('name', nameToFilter || existingParams.get('name')!);
    } else if (nameToFilterEmpty) {
      existingParams.delete('name');
    }

    if (rolesToFilter.length > 0) {
      existingParams.set('roles', rolesToFilter.toString() || existingParams.get('roles')!);
      setRolesToFilterURL(rolesToFilter || existingParams.getAll('roles')!);
    } else if (rolesToFilterEmpty) {
      existingParams.delete('roles');
      setRolesToFilterURL([]);
    }

    if (accountTypeToFilter.length > 0) {
      existingParams.set('accType', accountTypeToFilter.toString() || existingParams.get('accType')!);
      setAccountTypeToFilterURL(accountTypeToFilter || existingParams.getAll('accType')!);
    } else if (accountTypeToFilterEmpty) {
      existingParams.delete('accType');
      setAccountTypeToFilterURL([]);
    }

    if (tagsToFilter.length > 0) {
      existingParams.set('tags', tagsToFilter.toString() || existingParams.get('tags')!);
      setTagsToFilterURL(tagsToFilter || existingParams.getAll('tags')!);
    } else if (tagsToFilterEmpty) {
      existingParams.delete('tags');
      setTagsToFilterURL([]);
    }

    if (subscriptionFilter) {
      existingParams.set('sub', subscriptionFilter || existingParams.get('sub')!);
    }

    history.replace({ search: existingParams.toString() });
  }, [
    emailToFilter,
    emailToFilterEmpty,
    history,
    accountTypeToFilter,
    accountTypeToFilterEmpty,
    nameToFilter,
    nameToFilterEmpty,
    rolesToFilter,
    rolesToFilterEmpty,
    subscriptionFilter,
    tagsToFilter,
    tagsToFilterEmpty,
  ]);

  // render
  return (
    <>
      <Row gutter={[24, 24]} justify="center">
        <Col xxl={3} xl={3} lg={3} md={24} sm={24}>
          <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
            <Button
              data-cy="components-userlist-currentusers-exportas-button"
              padding="4px 5px"
              loading={loadingUsersFile}
              text={
                <>
                  Export as
                  <FiChevronDown style={{ marginLeft: 3 }} />
                </>
              }
              theme={themeConfig.noColor}
              icon={<FiDownload />}
              block
            />
          </Dropdown>
        </Col>
        <Col xxl={15} xl={15} lg={15}>
          {(user.isAdiSuperAdmin || user.isAdiAdmin) && (
            <Radio.Group
              data-cy="components-userlist-current-users-radio-group"
              onChange={onChangePresetFilter}
              optionType="button"
              options={options}
              value={subscriptionFilter}
            />
          )}
        </Col>
        <Col xxl={3} xl={3} lg={3} md={24} sm={24} xs={24}>
          {!!selectedRowKeys?.length && (
            <Dropdown overlay={actionsMenu} arrow placement="bottomRight" trigger={['click']}>
              <Button text="Actions" theme={themeConfig.primaryOutlined} block icon={<FiChevronDown />} />
            </Dropdown>
          )}
        </Col>
        <Col xxl={3} xl={3} lg={3} md={24} sm={24} xs={24}>
          <Button
            data-cy="components-userlist-currentuser-add-button"
            text="Add User"
            onClick={() => setAddUserVisible(true)}
            block
            icon={<FiPlus />}
          />
        </Col>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
          <S.TableWrapper>
            <Table
              className={
                data?.getAllUsersByFilter.users.length === 0 ? 'current-users-table-remove-horizontal-scroll' : ''
              }
              columns={columns}
              loading={loading}
              rowKey={(record: GQL_UserDetailsResponse) => record.id}
              rowSelection={{
                selectedRowKeys,
                onChange: (k) => setSelectedRowKeys(k),
              }}
              scroll={{ x: 'max-content' }}
              pagination={{ ...pagination, hideOnSinglePage: true }}
              bordered
              dataSource={data?.getAllUsersByFilter.users}
              onChange={handleTableChange}
            />
          </S.TableWrapper>
        </Col>
      </Row>
      <AddUser visible={addUserVisible} setVisible={setAddUserVisible} />
      <ModalConfirm
        visible={deleteUsersVisible}
        title={`Delete selected users`}
        lineInfo1={`Are you sure you want to delete ${selectedRowKeys.length} users? All users data`}
        lineInfo2={`will be permanently deleted. This action is irreversible`}
        deleteButtonTitle="Delete Users"
        nameToCheck={`delete ${selectedRowKeys.length} users`}
        errorMessage="Provided text is wrong"
        setVisible={setDeleteUsersVisible}
        loading={loadingDeleteUsers}
        deleteFn={handleDeleteUsers}
      ></ModalConfirm>
      <TagsModal
        visible={addTagsVisible}
        title={`Add Tags to Users`}
        lineInfo1={`Select the tags you want to add to the ${selectedRowKeys.length} selected users`}
        deleteButtonTitle="Add Tags"
        inputTitle="Select Tags"
        setVisible={setAddTagsVisible}
        loading={loadingAddTagToUsers}
        actionFn={handleAddTags}
      ></TagsModal>
      <TagsModal
        visible={removeTagsVisible}
        title={`Remove Tags from Users`}
        lineInfo1={`Select the tags you want to remove from the ${selectedRowKeys.length} selected users`}
        deleteButtonTitle="Remove Tags"
        inputTitle="Select Tags"
        setVisible={setRemoveTagsVisible}
        loading={loadingRemoveTagsFromUsers}
        actionFn={handleRemoveTags}
      ></TagsModal>
    </>
  );
};

export default withRouter(CurrentUsers);
