import React, { useMemo, useRef, useState } from 'react';
import * as S from './styles';
import PageWithTitle from '../../shared/PageWithTitle';
import Spacer from '../../shared/Spacer';
import Button from '../../shared/Button';
import Input from '../../shared/Input';
import { FiHelpCircle, FiPlus, FiSearch } from 'react-icons/fi';
import { Popconfirm, Table, Input as AntdInput, Popover, Row, Col, Tooltip } from 'antd';
import RoundedTag from '../../shared/RoundedTag';
import { centerAlign } from '../../utils/antd';
import { SketchPicker } from 'react-color';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import { TagInput, TagResponse } from '../../types/tags';
import TableSearchBox from '../../shared/TableSearchBox';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import Switch from '../../shared/Switch';
import { useAuth } from '../../hooks/useAuth';

type Props = RouteComponentProps<null, any, { creating?: boolean }>;

export const generateRandomColor = () => '#' + ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0');

const ManageTagsPage = (props: Props) => {
  const { creating } = props.location.state || {};
  const [tagsSearchText, setTagsSearchText] = useState<string>();
  const searchRef = useRef<AntdInput>(null);
  const [selectedColor, setSelectedColor] = useState<string>(generateRandomColor());
  const [selectingColor, setSelectingColor] = useState(false);
  const [newLabelVisible, setNewLabelVisible] = useState(!!creating);
  const [newLabelPrivate, setNewLabelPrivate] = useState(false);
  const [newTagName, setNewTagName] = useState<string>();
  const [searchTagVisible, setSearchTagVisible] = useState(false);
  const { isTeacher, isOrganizationAdiAdmin } = useAuth();
  const history = useHistory();

  const { data, loading: loadingFetch } = useQuery<{ getTags: TagResponse[] }>(gqlSchema.TagsSchema.queries.getTags);

  const [createTag, { loading: loadingCreation }] = useMutation<{ createTag: TagResponse }, { data: TagInput }>(
    gqlSchema.TagsSchema.mutations.createTag,
    {
      onCompleted: () => {
        setNewTagName('');
        setSelectedColor(generateRandomColor());
        setNewLabelVisible(false);
      },
      update: (cache, { data }) => {
        const response = cache.readQuery<{ getTags: TagResponse[] }>({ query: gqlSchema.TagsSchema.queries.getTags });
        if (data?.createTag) {
          cache.writeQuery({
            query: gqlSchema.TagsSchema.queries.getTags,
            data: { getTags: [data.createTag, ...(response?.getTags || [])] },
          });
        }
      },
    },
  );

  const [removeTag, { loading: loadingDeletion }] = useMutation<{ removeTag: string }, { tagId: string }>(
    gqlSchema.TagsSchema.mutations.removeTag,
    {
      update: (cache, { data }) => {
        const response = cache.readQuery<{ getTags: TagResponse[] }>({ query: gqlSchema.TagsSchema.queries.getTags });
        if (data?.removeTag) {
          cache.writeQuery({
            query: gqlSchema.TagsSchema.queries.getTags,
            data: { getTags: response?.getTags?.filter((tag) => tag.id !== data.removeTag) },
          });
        }
      },
    },
  );

  const isValidNewColor = useMemo(() => {
    if (selectedColor[0] !== '#') return false;
    else if (selectedColor.length !== 4 && selectedColor.length !== 7) return false;
    else if (selectedColor.match(/[^a-f0-9#]/gi)?.length) return false;
    else return true;
  }, [selectedColor]);

  const handleDeleteTag = ({ id }: { id: string }) => {
    removeTag({
      variables: { tagId: id },
    });
  };

  const handleAddTag = () => {
    if (newTagName && isValidNewColor) {
      createTag({
        variables: {
          data: {
            tag: newTagName,
            color: selectedColor,
            isPrivate: newLabelPrivate,
          },
        },
      });
    }
  };

  const columns = [
    {
      title: 'Tag Name',
      render: (_: string, record: TagResponse) => {
        return (
          <RoundedTag
            color={record.color}
            text={record.tag}
            onClick={() => history.push('/manage-tags/' + record.id)}
          />
        );
      },
      filterDropdown: (filterProps: FilterDropdownProps) => (
        <TableSearchBox onClearFilters={() => setTagsSearchText('')} ref={searchRef} {...filterProps} />
      ),
      filterIcon: (filtered: boolean) => (
        <S.SearchIcon $searchVisible={searchTagVisible}>
          <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
        </S.SearchIcon>
      ),
      onFilter: (value: string | number | boolean) => {
        if (!value) setTagsSearchText('');
        else setTagsSearchText(value.toString());
        return true;
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        setSearchTagVisible(visible);
        if (visible) {
          setTimeout(() => {
            if (searchRef && searchRef.current) {
              searchRef.current.select();
            }
          }, 100);
        }
      },
      width: '90%',
    },
    {
      title: 'Actions',
      align: centerAlign,
      render: (_: string, record: TagResponse) => {
        return (
          <Popconfirm
            placement="topRight"
            title={
              <div style={{ textAlign: 'center' }}>
                <S.Info>Remove Tag {record.tag}?</S.Info>
              </div>
            }
            onConfirm={() => {
              handleDeleteTag({ id: record.id });
            }}
            okText="Confirm"
            cancelText="Cancel"
          >
            <Button data-cy="components-managetagspage-delete-button" text="Delete Tag" block />
          </Popconfirm>
        );
      },
      width: '10%',
    },
  ];

  const loading = loadingFetch || loadingCreation || loadingDeletion;
  return (
    <PageWithTitle
      title="Manage Tags"
      fullHeight
      extra={
        <S.HeaderMenuContainer>
          <Button
            data-cy="components-managetagspage-new-button"
            text={<p style={{ margin: 0 }}>New Tag</p>}
            minHeight={40}
            onClick={() => setNewLabelVisible(!newLabelVisible)}
            icon={<FiPlus size={18} />}
          />
        </S.HeaderMenuContainer>
      }
    >
      <Spacer />
      <S.NewLabelContainer visible={newLabelVisible}>
        <Row align="bottom" style={{ width: '100%' }} gutter={[24, 16]}>
          <Col span={24}>
            <RoundedTag color={selectedColor} text="Tag Preview" />
          </Col>
          <Col lg={8} xs={12}>
            <S.LabelInputTitle>Label Name</S.LabelInputTitle>
            <Input
              icon={<FiSearch />}
              data-cy="components-managetagspage-label-input"
              placeholder="Label Name"
              backgroundColor="#FFFFFF"
              size="middle"
              onChange={(e) => setNewTagName(e.target.value)}
              value={newTagName}
            />
          </Col>
          <Col lg={8} xs={12}>
            <S.LabelInputTitle>Color</S.LabelInputTitle>
            <div style={{ display: 'flex' }}>
              <Popover
                title={null}
                trigger="click"
                placement="bottomLeft"
                overlayClassName="ant-popover-no-padding"
                visible={selectingColor}
                onVisibleChange={(visible) => setSelectingColor(visible)}
                content={
                  <SketchPicker
                    color={selectedColor}
                    onChangeComplete={(color) => setSelectedColor(color.hex)}
                    onChange={(color) => setSelectedColor(color.hex)}
                    disableAlpha
                  />
                }
              >
                <S.SelectedColorContainer color={selectedColor} />
              </Popover>
              <Spacer axis="horizontal" />
              <Input
                icon={<FiSearch />}
                placeholder="Label Color"
                backgroundColor="#FFFFFF"
                size="middle"
                onFocus={() => setSelectingColor(true)}
                // onBlur={() => setSelectingColor(false)}
                onChange={(e) => {
                  if (e.target.value && e.target.value[0] !== '#') {
                    setSelectedColor('#' + e.target.value);
                  } else if (!e.target.value) {
                    setSelectedColor('#');
                  } else if (e.target.value.length < 8) {
                    setSelectedColor(e.target.value);
                  }
                }}
                value={selectedColor}
              />
            </div>
          </Col>
          {isTeacher && isOrganizationAdiAdmin && (
            <Col lg={3} xs={12}>
              <S.LabelInputTitle>
                Private
                <Tooltip title="If not checked, this tag will be visible by all other organization admins">
                  <FiHelpCircle style={{ marginLeft: 8 }} />
                </Tooltip>
              </S.LabelInputTitle>
              <S.SwitchContainer>
                <Switch
                  title=""
                  value={newLabelPrivate}
                  titleAlign="left"
                  type="text"
                  margin="5px 0 0 0"
                  onChange={setNewLabelPrivate}
                />
              </S.SwitchContainer>
            </Col>
          )}
          <Col lg={3} xs={12}>
            <Button
              data-cy="components-managetagspage-save-button"
              text="Save Tag"
              minHeight={32}
              padding="3px 15px 3px 15px"
              onClick={handleAddTag}
              disabled={(newTagName?.length ?? 0) < 3 || !isValidNewColor}
            />
          </Col>
        </Row>
      </S.NewLabelContainer>
      <Spacer />

      <S.TableWrapper>
        <Table
          columns={columns}
          rowKey={(record: TagResponse) => record.id}
          bordered
          dataSource={
            data?.getTags?.filter((t) => t.tag.toLowerCase().includes(tagsSearchText?.toLowerCase() ?? '')) ?? []
          }
          pagination={{ hideOnSinglePage: true }}
          loading={loading}
        />
      </S.TableWrapper>
    </PageWithTitle>
  );
};

export default withRouter(ManageTagsPage);
