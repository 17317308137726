import styled from 'styled-components';

export const MessengerContainer = styled.div`
  height: 100%;
  background: white;
  box-shadow: 0px 0px 16px 2px rgba(123, 140, 234, 0.12);
  border-radius: 6px;
  display: flex;
  overflow: hidden;
`;

export const MessagesOutsideContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MessageInputContainer = styled.div`
  width: 100%;
  padding: 12px 24px;
  box-shadow: 0px 0px 34px rgba(123, 140, 234, 0.2);
  border-radius: 10px;
  display: flex;
  margin-top: 24px;
`;

export const AttachFileButton = styled.div`
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  margin-right: 16px;

  svg {
    min-height: 20px;
    min-width: 20px;
    color: ${(props) => props.theme.primaryColor.background};
  }
`;

export const SendMessageButton = styled.div<{ disabled?: boolean }>`
  border-radius: 6px;
  background: ${(props) => (props.disabled ? props.theme.primaryColor.disabled : props.theme.primaryColor.background)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 9px 7px 7px;
  ${(props) => !props.disabled && 'cursor: pointer;'}
  margin-left: 16px;

  svg {
    min-height: 20px;
    min-width: 20px;
    color: white;
  }
`;

export const MessagesContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;

  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const MessageBalloon = styled.div<{ sentByYou?: boolean }>`
  float: ${(props) => (props.sentByYou ? 'right' : 'left')};
  margin-${(props) => (props.sentByYou ? 'left' : 'right')}: auto;

  max-width: 60%;
  color: white;
  margin-top: 24px;
  box-shadow: 0px 0px 34px rgba(123, 140, 234, 0.2);
  border-radius: 10px;
  padding: 8px 16px;

  h2 {
    color: #eee;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 12px;
  }

  background: ${(props) =>
    props.sentByYou ? props.theme.primaryColor.background : props.theme.secondaryColor.background};
`;

export const NoMoreMessagesComponent = styled.p`
  text-align: center;
  color: #767676;
  margin-bottom: 16px;
  font-size: 13px;
`;

export const EmptyMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  h1 {
    margin-top: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 125.1%;
    /* or 23px */

    text-align: center;
    color: ${(props) => props.theme.secondaryColor.background};
  }
`;

export const NoContactSelectedContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  color: ${(props) => props.theme.secondaryColor.background};
`;

export const LoadingChatContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingMoreChatContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const ChatImage = styled.img`
  max-height: 320px;
  max-width: 100%;
`;

export const NotSupportedContainer = styled.div`
  width: 280px;
  height: 100px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const DownloadFileContainer = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    transition: opacity 0.3s;
    opacity: 0;
    position: absolute;
    top: calc(50% - 26px);
    left: calc(50% - 26px);
    background: #ffffff33;
    color: #222222;
    border-radius: 50%;
    padding: 12px;
    height: 52px;
    width: 52px;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
