import React, { useState } from 'react';
import { Col, message, Row, Spin } from 'antd';
import { GiTeacher } from 'react-icons/gi';
import * as S from './styles';
import { RiUser2Fill } from 'react-icons/ri';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { useCallback } from 'react';
import { downloadCsv } from '../../../utils/files';
import { FiBarChart } from 'react-icons/fi';
import { GQL_TeacherInvestigationsScoreAverage } from '../../../types/organization';
import ExcelJS, { Borders } from 'exceljs';
import { saveAs } from 'file-saver';
import Filter from '../../../shared/Charts/Filter';
import { FilterData } from '../../../types/investigation';
import Button from '../../../shared/Button';
import { formatDateTime } from '../../../utils/date';
import { themeConfig } from '../../../utils/theme';
import Spacer from '../../../shared/Spacer';

const DownloadInfo = () => {
  const [currentTab, setCurrentTab] = useState<'downloads' | 'investigation'>('downloads');
  const setBordersToSubRowsCell = useCallback(
    (ws: ExcelJS.Worksheet, currentRow: number, bordersStyle: ExcelJS.Borders) => {
      ws.getCell(`E${currentRow}`).border = bordersStyle;
      ws.getCell(`F${currentRow}`).border = bordersStyle;
      ws.getCell(`G${currentRow}`).border = bordersStyle;
      ws.getCell(`H${currentRow}`).border = bordersStyle;
      ws.getCell(`I${currentRow}`).border = bordersStyle;
      ws.getCell(`J${currentRow}`).border = bordersStyle;
      ws.getCell(`K${currentRow}`).border = bordersStyle;
      ws.getCell(`L${currentRow}`).border = bordersStyle;
      ws.getCell(`M${currentRow}`).border = bordersStyle;
      ws.getCell(`N${currentRow}`).border = bordersStyle;
      ws.getCell(`O${currentRow}`).border = bordersStyle;
      ws.getCell(`P${currentRow}`).border = bordersStyle;
    },
    [],
  );

  const fillInvestigationsRow = useCallback(
    (
      ws: ExcelJS.Worksheet,
      row: GQL_TeacherInvestigationsScoreAverage,
      currentRow: number,
      bordersStyle: ExcelJS.Borders,
    ) => {
      let subRow = currentRow;
      row.investigations?.forEach((inv) => {
        const className = ws.getCell(`E${subRow}`);
        const rowInvestigationName = ws.getCell(`F${subRow}`);
        const rowInvestigationSubject = ws.getCell(`G${subRow}`);
        const rowInvestigationDiscipline = ws.getCell(`H${subRow}`);
        const rowInvestigationGradeBand = ws.getCell(`I${subRow}`);
        const rowStartDate = ws.getCell(`J${subRow}`);
        const rowEndDate = ws.getCell(`K${subRow}`);
        const rowNumTotalStudents = ws.getCell(`L${subRow}`);
        const rowGradedStudents = ws.getCell(`M${subRow}`);
        const rowARS = ws.getCell(`N${subRow}`); //Average Report Score
        const rowAEG = ws.getCell(`O${subRow}`); // Average Engagement Score
        const rowAFG = ws.getCell(`P${subRow}`); // Average Feedback Score
        // Putting value to subrows
        className.value = inv.className;
        rowInvestigationName.value = inv.title;
        rowInvestigationSubject.value = inv.subject;
        rowInvestigationDiscipline.value = inv.discipline;
        rowInvestigationGradeBand.value = inv.gradeBand;
        rowStartDate.value = formatDateTime(Number(inv.startDate));
        rowEndDate.value = formatDateTime(Number(inv.endDate));
        rowNumTotalStudents.value = inv.totalNumberOfStudents;
        rowGradedStudents.value = inv.numberOfGradedStudents;
        rowARS.value = inv.avgReportScore;
        rowAEG.value = !inv.avgEngagementScore ? 0 : (inv.avgEngagementScore * 5)?.toFixed(1);
        rowAFG.value = inv.avgFeedbackScore;
        setBordersToSubRowsCell(ws, subRow, bordersStyle);
        subRow += 1;
      });
    },
    [setBordersToSubRowsCell],
  );

  const saveAsExcel = useCallback(
    async (dataset: GQL_TeacherInvestigationsScoreAverage[]) => {
      const wb = new ExcelJS.Workbook();
      const ws = wb.addWorksheet();
      const borderStyle = { style: 'thin', color: 'black' };
      const bordersStyle = {
        top: borderStyle,
        left: borderStyle,
        right: borderStyle,
        bottom: borderStyle,
        diagonal: borderStyle,
      } as Borders;
      const headerStyle = { font: { bold: true }, border: bordersStyle };
      const bodyStyle = { font: { bold: false } };
      ws.columns = [
        { header: 'First Name', key: 'teacherFirstName', width: 45 },
        { header: 'Last Name', key: 'teacherLastName', width: 45 },
        { header: 'Email', key: 'teacherEmail', width: 45 },
        { header: 'Tags', key: 'tags', width: 45 },
        { header: 'Class', key: 'className', width: 45 },
        { header: 'Investigation', key: 'investigation', width: 45 },
        { header: 'Subject', key: 'subject', width: 20 },
        { header: 'Discipline', key: 'discipline', width: 20 },
        { header: 'GradeBand', key: 'gradeBand', width: 35 },
        { header: 'Start Date', key: 'startDate', width: 20 },
        { header: 'End Date', key: 'endDate', width: 20 },
        { header: '# of Students', key: 'totalNumberOfStudents', width: 20 },
        { header: '# of Graded Students', key: 'numberOfGradedStudents', width: 20 },
        { header: 'Average Report Score', key: 'reportScore', width: 12 },
        { header: 'Average Engagement Score', key: 'engagementScore', width: 15 },
        { header: 'Average Feedback Score', key: 'feedbackScore', width: 15 },
      ];
      ws.getCell(`A1`).style = headerStyle;
      ws.getCell(`B1`).style = headerStyle;
      ws.getCell(`C1`).style = headerStyle;
      ws.getCell(`D1`).style = headerStyle;
      ws.getCell(`E1`).style = headerStyle;
      ws.getCell(`F1`).style = headerStyle;
      ws.getCell(`G1`).style = headerStyle;
      ws.getCell(`H1`).style = headerStyle;
      ws.getCell(`I1`).style = headerStyle;
      ws.getCell(`J1`).style = headerStyle;
      ws.getCell(`K1`).style = headerStyle;
      ws.getCell(`L1`).style = headerStyle;
      ws.getCell(`M1`).style = headerStyle;
      ws.getCell(`N1`).style = headerStyle;
      ws.getCell(`O1`).style = headerStyle;
      ws.getCell(`P1`).style = headerStyle;
      let currentRow = 2;

      const mergeStyle: Partial<ExcelJS.Style> = { alignment: { vertical: 'middle', horizontal: 'center' } };
      dataset.forEach((row) => {
        const excelRow = ws.addRow([row.teacherFirstName, row.teacherLastName, row.teacherEmail, row.tags.join('\n')]);
        excelRow.font = bodyStyle.font;

        const mergeLength = currentRow + (row.investigations?.length || 0) - 1;
        ws.mergeCells(`A${currentRow}:A${mergeLength}`);
        ws.mergeCells(`B${currentRow}:B${mergeLength}`);
        ws.mergeCells(`C${currentRow}:C${mergeLength}`);
        ws.mergeCells(`D${currentRow}:D${mergeLength}`);
        ws.getCell(`A${currentRow}`).border = bordersStyle;
        ws.getCell(`B${currentRow}`).border = bordersStyle;
        ws.getCell(`C${currentRow}`).border = bordersStyle;
        ws.getCell(`D${currentRow}`).border = bordersStyle;
        ws.getCell(`A${currentRow}`).style = mergeStyle;
        ws.getCell(`B${currentRow}`).style = mergeStyle;
        ws.getCell(`C${currentRow}`).style = mergeStyle;
        ws.getCell(`D${currentRow}`).style = mergeStyle;
        fillInvestigationsRow(ws, row, currentRow, bordersStyle);
        currentRow = mergeLength + 1;
      });

      let initialMerge = 0;
      for (let i = 1; i < currentRow; i++) {
        const cell1 = ws.getCell(`E${i}`);
        const cell2 = ws.getCell(`E${i + 1}`);
        if (cell1.value !== cell2.value) {
          ws.mergeCells(`E${initialMerge}:E${i}`);
          ws.getCell(`E${initialMerge}`).style = mergeStyle;
          ws.getCell(`E${initialMerge}`).border = bordersStyle;
          initialMerge = i + 1;
        }
      }

      const buf = await wb.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `Investigation Score Average Data.xlsx`);
    },
    [fillInvestigationsRow],
  );

  const [filterData, setFilterData] = useState<FilterData>({ organizations: false, teachers: false });

  const [fetchTeachersInfo, { loading: loadingTeachersData }] = useLazyQuery<{ exportTeacherData: string }>(
    gqlSchema.OrganizationSchema.queries.EXPORT.exportTeacherData,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        downloadCsv(atob(data.exportTeacherData), 'Teachers Info.csv');
      },
      onError: (err) => {
        message.error('There was an error downloading teachers info: ' + err.message);
      },
    },
  );

  const [fetchStudentsInfo, { loading: loadingStudentsData }] = useLazyQuery<{ exportStudentData: string }>(
    gqlSchema.OrganizationSchema.queries.EXPORT.exportStudentData,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        downloadCsv(atob(data.exportStudentData), 'Students Info.csv');
      },
      onError: (err) => {
        message.error('There was an error downloading teachers info: ' + err.message);
      },
    },
  );

  const [fetchDataScoreAverage, { loading: loadingDataScoreAverage }] = useLazyQuery<{
    exportDataScoreAverage: GQL_TeacherInvestigationsScoreAverage[];
  }>(gqlSchema.OrganizationSchema.queries.EXPORT.exportDataScoreAverage, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!data.exportDataScoreAverage.length) message.error('There is not data to export based on the current filter');
      else {
        console.log(data);
        const dataSortedByClass = (data.exportDataScoreAverage || []).map((d) => ({
          ...d,
          investigations: [...d.investigations].sort(
            (a, b) =>
              a.classId.localeCompare(b.classId) ||
              Number(a.startDate) - Number(b.startDate) ||
              Number(a.endDate) - Number(b.endDate),
          ),
        }));
        saveAsExcel(dataSortedByClass);
      }
    },
    onError: (err) => {
      message.error('There was an error downloading teachers info: ' + err.message);
    },
  });

  const onDownloadTeachersData = useCallback(() => {
    if (!loadingTeachersData) fetchTeachersInfo();
  }, [fetchTeachersInfo, loadingTeachersData]);

  const onDownloadStudentsData = useCallback(() => {
    if (!loadingStudentsData) fetchStudentsInfo();
  }, [fetchStudentsInfo, loadingStudentsData]);

  const onDownloadDataScoreAverage = useCallback(() => {
    const {
      organizations,
      teachers,
      disciplineIds,
      organizationId,
      teacherId,
      investigationType,
      ...data
    } = filterData;
    if (!loadingDataScoreAverage)
      fetchDataScoreAverage({ variables: { data: { ...data, subject: investigationType } } });
  }, [fetchDataScoreAverage, loadingDataScoreAverage, filterData]);

  const onChangeFilterData = useCallback((data: FilterData) => {
    setFilterData(data);
  }, []);

  const downloadButtons = (
    <>
      <Col span={12} onClick={onDownloadStudentsData}>
        <S.Card>
          <S.Icon>
            <RiUser2Fill />
          </S.Icon>
          <S.Title>Download Student Data</S.Title>
          {loadingStudentsData && (
            <>
              <Spin />
              <S.Info>This could take some time, please wait</S.Info>
            </>
          )}
        </S.Card>
      </Col>
      <Col span={12} onClick={onDownloadTeachersData}>
        <S.Card>
          <S.Icon>
            <GiTeacher />
          </S.Icon>
          <S.Title>Download Teacher Data</S.Title>
          {loadingTeachersData && (
            <>
              <Spin />
              <S.Info>This could take some time, please wait</S.Info>
            </>
          )}
        </S.Card>
      </Col>
      <Col span={24}>
        <S.Card style={{ justifyContent: 'center' }} onClick={() => setCurrentTab('investigation')}>
          <S.Icon>
            <FiBarChart />
          </S.Icon>
          <S.Title>Download Investigations Data</S.Title>
        </S.Card>
      </Col>
    </>
  );

  const downloadInvestigationDataComponent = (
    <Col span={24}>
      <Row justify="center" gutter={[0, 6]}>
        <Col span={24}>
          <h3>Select Optional Filters</h3>
          <Filter
            showDateRangeFilter={false}
            showSubjectFilter
            filterUsers={false}
            onChangeFilterData={onChangeFilterData}
            maxWidth="unset"
            display="inline-flex"
          />
          <Spacer axis="vertical" size={32} />
        </Col>
        {loadingDataScoreAverage && (
          <Col span={24}>
            <S.LoadingInfoContainer>
              <Spin />
              <S.Info>This could take some time, please wait</S.Info>
            </S.LoadingInfoContainer>
          </Col>
        )}
        <Col span={6}>
          <Button
            text="Go Back"
            style={{ marginLeft: 'auto' }}
            block
            theme={themeConfig.secondaryColor}
            onClick={() => setCurrentTab('downloads')}
          />
        </Col>
        <Spacer axis="horizontal" size={32} />
        <Col span={6}>
          <Button text="Download" style={{ marginLeft: 'auto' }} block onClick={onDownloadDataScoreAverage} />
        </Col>
      </Row>
    </Col>
  );

  return (
    <Row justify="center" gutter={[24, 24]} style={{ marginBottom: 30, marginTop: 30 }}>
      {currentTab === 'downloads' ? downloadButtons : downloadInvestigationDataComponent}
    </Row>
  );
};

export default DownloadInfo;
