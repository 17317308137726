import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiBuildingHouse, BiMessageDetail } from 'react-icons/bi';
import { GiSandsOfTime } from 'react-icons/gi';
import { BsBook } from 'react-icons/bs';
import Icon, { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
// import { DownOutlined } from '@ant-design/icons';
import { useQuery, useSubscription } from '@apollo/client';
import { FaRegUserCircle } from 'react-icons/fa';
import {
  FiBook,
  FiGrid,
  FiBell,
  FiEdit,
  FiUsers,
  FiUser,
  FiDownloadCloud,
  FiBarChart,
  FiTag,
  FiLogOut,
  FiUpload,
  FiSettings,
} from 'react-icons/fi';

import * as S from './styles';
import { notificationActions } from '../../redux/modules';
import logo from '../../assets/logo.svg';
import Notification from '../Notification';
import { useAuth } from '../../hooks/useAuth';
import { gqlSchema } from '../../gql/schema';
import { GQL_NotificationResponse } from '../../types/notification';
import Button from '../Button';
import { GQL_MessagesSubscriptionResponse } from '../../types/chat';
import Avatar from '../Avatar';
import WalkthroughIcon from '../WalkthroughIcon';
import { IStore } from '../../redux/store';
import useRoleSelectionModal from '../../hooks/useRoleSelectionModal';
import OnboardingTooltip from '../OnboardingTooltip';
import { MdPayment } from 'react-icons/md';
import useGoogleRoleUpdater from '../../hooks/updatePreferredRoleToGoogleStudent';
import { roles } from '../../utils/roles';
import { Filter } from '../../utils/antd';
import DownArrow from '../../assets/chevron-down.svg';
import Walkthrough from '../../assets/Walkthrough.svg';
import phone from '../../assets/contactUs/phone.svg';
import vendor from '../../assets/contactUs/vendor.svg';
import adi from '../../assets/contactUs/adi.svg';
import learning from '../../assets/contactUs/learning.svg';
import store from '../../assets/contactUs/store.svg';
import accounting from '../../assets/contactUs/accounting.svg';
import facebookSrc from '../../assets/contactUs/facebook.svg';
import twitterSrc from '../../assets/contactUs/twitter.svg';
import youtubeSrc from '../../assets/contactUs/youtube.svg';
import instagramSrc from '../../assets/contactUs/instagram.svg';
import support from '../../assets/knowledge/chat.svg';
import organization from '../../assets/knowledge/organization.svg';
import privacyPolicy from '../../assets/knowledge/privacy-policy.svg';
import student from '../../assets/knowledge/student.svg';
import teacher from '../../assets/knowledge/teacher.svg';

const AuthenticatedHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    signOut,
    isStudent,
    isTeacherOrFacilitator,
    isAdiSuperAdmin,
    isAdiAdmin,
    isOrganizationAdiAdmin,
    isSubscriptionUser,
    isTeacherAssistant,
    isWriter,
    isTeacher,
    isFacilitator,
    isGoogleTeacher,
    isGoogleStudent,
    user,
  } = useAuth();

  const { openModal } = useRoleSelectionModal();
  const [menuVisible, setMenuVisible] = useState(false);
  const [pendingNotifications, setPendingNotifications] = useState<GQL_NotificationResponse[]>([]);
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const { data: unreadMessagesData } = useQuery<{ getUnreadMessagesCount: number }>(
    gqlSchema.ChatSchema.query.getUnreadMessagesCount,
    {
      onError: (err) => {
        message.error('There was an error loading unread messages count: ' + err.message || 'Unexpected Error');
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const [isContactUsModalVisible, setIsContactUsModalVisible] = useState(false);
  const [isKnowlegeModalVisible, setIsKnowlegeModalVisible] = useState(false);





  const menu = (
    <Menu >
      <Menu.Item key="1" onClick={() => setIsContactUsModalVisible(true)}>
        Connect With Us

      </Menu.Item>
      <Menu.Item key="2" onClick={() => setIsKnowlegeModalVisible(true)}>
        Knowledge
      </Menu.Item>
    </Menu>


  );


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const doesRequireInvestigationRefresh = useCallback((type: string) => {
    const notificationsNeedsRefresh = [
      'peer_review',
      'new_peer_review',
      'stuck_student_for_student_reflection_extra_work',
      'stuck_student_for_student_peer_review_report',
      'progress_check_unlocked',
    ];
    return notificationsNeedsRefresh.includes(type);
  }, []);

  useSubscription<{ listenForChatMessages: GQL_MessagesSubscriptionResponse }>(
    gqlSchema.ChatSchema.subscription.listenForChatMessages,
    {
      onSubscriptionData: ({ subscriptionData: { data }, client }) => {
        if (data?.listenForChatMessages?.senderId !== user.id) {
          const existingData = client?.readQuery<{ getUnreadMessagesCount: number }>({
            query: gqlSchema.ChatSchema.query.getUnreadMessagesCount,
          });

          client.writeQuery({
            query: gqlSchema.ChatSchema.query.getUnreadMessagesCount,
            data: { getUnreadMessagesCount: (existingData?.getUnreadMessagesCount ?? 0) + 1 },
          });
        }
        const isMessageFromCurrentUser = data?.listenForChatMessages?.senderId === user.id;

        if (location.pathname !== '/messages') {
          const newMessage = {
            chatId: data?.listenForChatMessages?.chatId,
            recipientId: data?.listenForChatMessages?.recipientId,
            recipientUserName: data?.listenForChatMessages?.recipientUserName,
            senderId: data?.listenForChatMessages?.senderId,
            senderUserName: data?.listenForChatMessages?.senderUserName,
            message: data?.listenForChatMessages?.message,
            sent: data?.listenForChatMessages?.sent,
            chatMessageId: data?.listenForChatMessages?.chatMessageId,
            read: isMessageFromCurrentUser,
            fileUrl: data?.listenForChatMessages?.fileURL,
            MIMEType: data?.listenForChatMessages?.MIMEType,
            __typename: 'MessagesListResponse',
          };

          client.writeQuery({
            query: gqlSchema.ChatSchema.query.getMessages,
            variables: {
              data: {
                chatId: data?.listenForChatMessages?.chatId,
              },
            },
            data: {
              getMessages: {
                chat: [newMessage],
              },
            },
          });
        }
      },
    },
  );

  useSubscription<{ listenForNotifications: GQL_NotificationResponse }>(
    gqlSchema.NotificationSchema.subscriptions.listenForNotifications,
    {
      onSubscriptionData: ({ subscriptionData: { data }, client }) => {
        if (data?.listenForNotifications) {
          const existingData = client?.readQuery<{ getNotifications: GQL_NotificationResponse[] }>({
            query: gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications,
          });

          client.writeQuery({
            query: gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications,
            data: {
              getNotifications: [...(existingData?.getNotifications || []), { ...data?.listenForNotifications }],
            },
          });

          if (doesRequireInvestigationRefresh(data.listenForNotifications.type)) {
            client.refetchQueries({
              include: [gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById],
            });
          }
        }
      },
    },
  );

  const { data, loading } = useQuery<{ getNotifications: GQL_NotificationResponse[] }>(
    gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications,
    {
      onError: (err) => {
        message.error('There was an error loading your notifications: ' + err.message || 'Unexpected Error');
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (data) {
      setPendingNotifications(
        data.getNotifications
          .filter((notif: GQL_NotificationResponse) => !notif.seen)
          ?.sort((a, b) => (b.priority || 0) - (a.priority || 0)),
      );
    }
  }, [data]);

  useEffect(() => {
    dispatch(notificationActions.notificationLoad());
  }, [dispatch]);

  useEffect(() => setCurrentPath(location.pathname), [location]);

  const handleLogout = useCallback(() => {
    signOut();
  }, [signOut]);

  const toggleMenuVisible = useCallback(() => {
    setMenuVisible(!menuVisible);
  }, [menuVisible]);

  const onSelectMenu = useCallback(
    (item: any) => {
      setMenuVisible(false);
      history.push(item.key);
      setCurrentPath(item.key);
    },
    [history],
  );

  const toggleNotifications = useCallback(() => {
    if (!notificationVisible) dispatch(notificationActions.notificationLoad());
    setNotificationVisible(!notificationVisible);
  }, [dispatch, notificationVisible]);

  const renderMenuStudent = useMemo(() => {
    return (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-studentmenu-item-myduedates"
          $menuVisible={menuVisible}
          icon={<GiSandsOfTime />}
          key="/student-dashboard/due-dates"
        >
          My Due Dates
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-studentmenu-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/student-dashboard"
        >
          Dashboard
        </S.MenuItem>
      </S.Menu>
    );
  }, [menuVisible, currentPath, onSelectMenu]);

  const renderMenuGoogleTeacher = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        {(
          <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/googleclassroom-teacher-dashboard">
            Dashboard
          </S.MenuItem>
        )}
        {/* <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-teacher-insights"
          $menuVisible={menuVisible}
          icon={<FiBarChart />}
          key="/teacher-insights/investigations"
        >
          Insights
        </S.MenuItem> */}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/googleclassroom-teacher-assignment"
        >
          Assignment
        </S.MenuItem>
        {/* <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-investigation"
          $menuVisible={menuVisible}
          icon={<BsBook />}
          key="/googleclassroom-teacher-assignment"
        >
          Users
        </S.MenuItem> */}

      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isGoogleTeacher],
  );

  const renderMenuGoogleStudent = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/googleclassroom-students-dashboard"
        >
          Dashboard
        </S.MenuItem>

        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/googleclassroom-students-assignmentduedate"
        >
          Assignment Due Dates
        </S.MenuItem>
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isWriter, isTeacher],
  );

  const renderMenuTeacher = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        {user.subscription?.customLibrary && isWriter && (
          <S.MenuItem $menuVisible={menuVisible} icon={<FiBook />} key="/organization-library">
            Organization Library
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-investigation"
          $menuVisible={menuVisible}
          icon={<BsBook />}
          key="/teacher-investigation"
        >
          Lessons
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/teacher-assessment"
        >
          Assessments
        </S.MenuItem>
        {['Clever', 'Classlink'].includes(user.source) && (
          <S.MenuItem icon={<FiDownloadCloud />} key="/sync-list">
            Sync
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-teacher-insights"
          $menuVisible={menuVisible}
          icon={<FiBarChart />}
          key="/teacher-insights/investigations"
        >
          Insights
        </S.MenuItem>
        {isTeacher && (
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-teacher-users"
            $menuVisible={menuVisible}
            icon={<FiUser />}
            key="/teacher-users"
          >
            Users
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/teacher-dashboard"
        >
          Dashboard
        </S.MenuItem>
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isWriter, isTeacher],
  );

  const renderMenuTeacherAssistant = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/teacher-dashboard"
        >
          Dashboard
        </S.MenuItem>
      </S.Menu>
    ),
    [menuVisible, currentPath, onSelectMenu],
  );

  const renderMenuWriter = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiBook />} key="/organization-library">
          Organization Library
        </S.MenuItem>
      </S.Menu>
    ),
    [menuVisible, currentPath, onSelectMenu],
  );
  const currentMenu = useSelector((state: IStore) => state.menu.currentMenu);

  const renderMenuAdiAdmin = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentMenu || '']}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-investigations"
          $menuVisible={menuVisible}
          icon={<FiBook />}
          key="/adi-investigations"
        >
          Lessons
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-organizations"
          $menuVisible={menuVisible}
          icon={<BiBuildingHouse />}
          key="/adi-organizations"
        >
          Organizations
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/adi-assessments"
        >
          Assessments
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-users"
          $menuVisible={menuVisible}
          icon={<FiUsers />}
          key="/adi-users"
        >
          Users
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/adi-dashboard"
        >
          Dashboard
        </S.MenuItem>
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentMenu],
  );

  const renderMenuOrganizationAdmin = useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        {user.subscription?.customLibrary && (
          <S.MenuItem
            data-cy="shared-authenticatedheader-profilemenu-item-organization-library"
            $menuVisible={menuVisible}
            icon={<FiBook />}
            key="/organization-library"
          >
            Organization Library
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-organization-users"
          $menuVisible={menuVisible}
          icon={<FiUsers />}
          key="/organization-users"
        >
          Users
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-organization-details"
          $menuVisible={menuVisible}
          icon={<BiBuildingHouse />}
          key="/organization-details"
        >
          Organization
        </S.MenuItem>
        {user.subscription?.reportPermission && (
          <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/organization-dashboard">
            Dashboard
          </S.MenuItem>
        )}
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user],
  );

  const showNotifications = useCallback(() => {
    if (data) {
      if (pendingNotifications.length > 0) toggleNotifications();

      if ((pendingNotifications.length === 0 && data.getNotifications.length > 0) || data.getNotifications.length === 0)
        history.push('/notifications');
    }
  }, [data, history, pendingNotifications.length, toggleNotifications]);

  const renderMenu = useCallback(() => {
    if (isStudent) return renderMenuStudent;
    if (isGoogleTeacher) return renderMenuGoogleTeacher;
    if (isGoogleStudent) return renderMenuGoogleStudent;
    else if (isTeacherOrFacilitator || isFacilitator || isSubscriptionUser) return renderMenuTeacher;
    else if (isAdiSuperAdmin || isAdiAdmin) return renderMenuAdiAdmin;
    else if (isOrganizationAdiAdmin) return renderMenuOrganizationAdmin;
    else if (isTeacherAssistant) return renderMenuTeacherAssistant;
    else if (isWriter) return renderMenuWriter;
  }, [
    isStudent,
    renderMenuTeacher,
    renderMenuStudent,
    isTeacherOrFacilitator,
    isAdiSuperAdmin,
    isAdiAdmin,
    isWriter,
    isOrganizationAdiAdmin,
    isTeacherAssistant,
    renderMenuAdiAdmin,
    isSubscriptionUser,
    renderMenuOrganizationAdmin,
    renderMenuTeacherAssistant,
    renderMenuWriter,
    isFacilitator,
    isGoogleStudent,
    isGoogleTeacher,
  ]);
  const { updatePreferredRoleToGoogleStudent, updatePreferredRoleToGoogleTeacher } = useGoogleRoleUpdater();

  const handleRoleToggle = () => {

    if (user.preferredRole === 'google_student') {
      updatePreferredRoleToGoogleTeacher();
    } else if (user.preferredRole === 'google_teacher') {
      updatePreferredRoleToGoogleStudent();
    }
    else {
      openModal();
    }
  };

  const profileMenu = useMemo(
    () => (
      <S.Menu $menuVisible={menuVisible} onSelect={onSelectMenu}>
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-myprofile"
          $menuVisible={menuVisible}
          icon={<FaRegUserCircle size={18} style={{ marginRight: 12 }} />}
          onClick={() => {
            history.push('/profile');
          }}
        >
          My Profile
        </S.MenuItem>
        <Menu.Divider style={{ margin: '4px 24px' }} />
        {!isStudent && !isTeacherAssistant && isMobile && !isAdiSuperAdmin && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-managetags"
              icon={<BiMessageDetail size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/messages');
              }}
            >
              My Messages
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {user.roles.filter((r) => r !== 'non_enterprise').length > 1 && (
          <>
            <S.MenuItem
              $menuVisible={menuVisible}
              onClick={handleRoleToggle}
              icon={<FiUsers size={18} style={{ marginRight: 12 }} />}
            >
              Change Role
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}
        {user?.subscriptions?.length !== undefined && user?.subscriptions?.length > 0 && (
          <>
            <S.MenuItem
              $menuVisible={menuVisible}
              icon={<FiUsers size={18} style={{ marginRight: 12 }} />}
            >
              {(user?.preferredSubscription === "user") ? "USER SUBSCRIPTION" : "ORGANIZATION SUBSCRIPTION"}
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}
        {!isStudent && !isGoogleStudent && !isTeacherAssistant && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-managetags"
              icon={<FiTag size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/manage-tags');
              }}
            >
              Manage Tags
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}
        {isSubscriptionUser && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-billing-information"
              icon={<MdPayment size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/billing-information');
              }}
            >
              Billing Information
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {(isAdiAdmin || isAdiSuperAdmin) && (
          <>
            <S.MenuItem
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/whitelist-domain');
              }}
              icon={<FiUsers size={18} style={{ marginRight: 12 }} />}
            >
              Whitelist domain
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {(isAdiAdmin || isAdiSuperAdmin) && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-uploadcsvs"
              icon={<FiUpload size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => history.push('/upload-csv')}
            >
              Upload CSVs
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {isSubscriptionUser && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-updatesubscription"
              icon={<FiSettings size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => history.push('/manage-subscription')}
            >
              Manage Subscription
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-logout"
          icon={<FiLogOut size={18} style={{ marginRight: 12 }} />}
          $menuVisible={menuVisible}
          onClick={handleLogout}
        >
          Logout
        </S.MenuItem>
      </S.Menu>
    ),
    [
      handleLogout,
      isSubscriptionUser,
      history,
      menuVisible,
      onSelectMenu,
      isStudent,
      isTeacherAssistant,
      user.roles,
      openModal,
      isAdiAdmin,
      isAdiSuperAdmin,
      user?.preferredSubscription,
      user?.subscriptions?.length,

    ],
  );

  const getFontSize = (text: string): string => {
    if (text.length <= 8) return `15px`; // Minimum size for very short text
    else if (text.length <= 12) return `14px`; // Slightly smaller for short text
    else if (text.length <= 15) return `12px`; // Medium size for medium text
    else return `12px`; // Default size for long text
  };




  const [searchTerm, setSearchTerm] = useState('');


  const handleSearch = (e: any) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      window.open(`https://help.adilearninghub.com/knowledge/kb-search-results?term=${searchTerm}`, '_blank');
    }
  };


  const renderAuthenticatedMenu = useMemo(() => {
    let roleItem = roles.find((r: Filter) => r.value === user.preferredRole);
    if (!roleItem && user.roles.length === 1) {
      roleItem = roles.find((r: Filter) => r.value === user.roles[0]);
    }

    // Calculate font size based on the role text length
    const fontSize = roleItem ? getFontSize(roleItem.text) : '16px';





    return (
      <>
        <Col xl={17} xs={0}>
          {renderMenu()}
        </Col>
        <S.Col
          $defaultAlign="start"
          $responsiveAlign="end"
          xl={4}
          lg={18}
          md={18}
          sm={18}
          xs={18}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            paddingLeft: '20px', // Adjust as needed
            paddingRight: '10px' // Consistent padding on right
          }}
        >
          <S.Div>
            {/* Notification Icon */}
            {(isTeacherOrFacilitator || isStudent || isAdiAdmin || isAdiSuperAdmin) && (
              <S.HeaderBadgeWrapper>
                <S.HeaderBadge
                  data-cy="shared-authenticatedheader-badge"
                  count={pendingNotifications.length}
                  $visible={notificationVisible}
                  size="small"
                  offset={[-10, 12]} // Adjust offset to reduce space
                >
                  <Notification
                    notifications={pendingNotifications}
                    loading={loading}
                    visible={notificationVisible}
                    setVisible={setNotificationVisible}
                  >
                    <Icon
                      data-cy="shared-authenticatedheader-notifications-icon"
                      component={() => <FiBell />}
                      onClick={showNotifications}
                    />
                  </Notification>
                </S.HeaderBadge>
              </S.HeaderBadgeWrapper>
            )}

            {/* Message Icon */}
            {(!isStudent) && (!isGoogleStudent) && !isMobile && (
              <S.HeaderBadgeWrapper>
                <S.HeaderBadge
                  count={unreadMessagesData?.getUnreadMessagesCount}
                  size="small"
                  overflowCount={10}
                  offset={[-10, 15]} // Adjust offset to reduce space
                >
                  <Icon
                    data-cy="shared-authenticatedheader-messages-icon"
                    component={() => <BiMessageDetail size={25} />}
                    onClick={() => history.push('/messages')}
                  />
                </S.HeaderBadge>
              </S.HeaderBadgeWrapper>
            )}

            {/* Avatar and Role Text */}
            <Dropdown
              trigger={['click']}
              overlay={profileMenu}
              arrow
              placement="bottomRight"
            >
              <S.AvatarContainer $fontSize={fontSize}>
                <span role="none" style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar
                    data-cy="shared-authenticatedheader-avatar-icon"
                    backgroundColor="#FFFFFF"
                    size={45}
                    src={user?.avatar}
                  />
                  <span className="role-text">
                    {roleItem ? roleItem.text : 'Role'} <S.imgRole src={DownArrow} />
                  </span>
                </span>
              </S.AvatarContainer>
            </Dropdown>
          </S.Div>
          {(isGoogleTeacher || isGoogleStudent) && (
            <S.HelpIcon>
              <Dropdown overlay={menu} trigger={['click']}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>

                  <img src={Walkthrough} />
                </div>
              </Dropdown>
            </S.HelpIcon>
          )}

          {!isAdiSuperAdmin && !isAdiAdmin && !isTeacherOrFacilitator && !isGoogleTeacher && !isGoogleStudent && (
            <S.HelpIcon>
              <OnboardingTooltip
                placement="bottomLeft"
                text="If you need assistance on how to use a specific functionality within the Learning Hub, click here to view our video tutorials."
              >
                <WalkthroughIcon top="20px" placement="bottomRight" />
              </OnboardingTooltip>
            </S.HelpIcon>
          )}



          {/* First Modal */}

          <S.Modal width='80%'
            visible={isContactUsModalVisible}
            onCancel={() => setIsContactUsModalVisible(false)}

          >
            <S.Container>
              <S.Heading>How can we help you?</S.Heading>
              <S.CardContainer>
                <S.Card className="contact">
                  <S.Icon>
                    <img src={phone} className="fas fa-phone"></img>
                  </S.Icon>
                  <S.CardHeading>Contact Us</S.CardHeading>
                  <S.CardParagraph>
                    <span className="phone">p: 512-777-4099</span> | <span className="fax">f: 512-599-8784</span>
                  </S.CardParagraph>
                  <S.CardParagraph>12707 Nutty Brown Rd. G-100 Austin, TX 78737</S.CardParagraph>
                  <S.ContactLink className="email">howdy@argumentdriveninquiry.com</S.ContactLink>
                  <S.SocialLinks>
                    <S.SocialLink href="https://www.facebook.com/ArgumentDriven/" target="_blank" rel="noopener noreferrer">
                      <img src={facebookSrc} className="fab fa-facebook-f"></img>
                    </S.SocialLink>
                    <S.SocialLink href="https://x.com/ArgumentDriven" target="_blank" rel="noopener noreferrer">
                      <img src={twitterSrc} className="fab fa-twitter"></img>
                    </S.SocialLink>
                    <S.SocialLink href="#" target="_blank" rel="noopener noreferrer">
                      <img src={instagramSrc} className="fab fa-instagram"></img>
                    </S.SocialLink>
                    <S.SocialLink href="https://www.youtube.com/c/ArgumentDrivenInquiry/featured" target="_blank" rel="noopener noreferrer">
                      <img src={youtubeSrc} className="fab fa-youtube"></img>
                    </S.SocialLink>
                  </S.SocialLinks>
                </S.Card>
                <S.Card className="vendor">
                  <S.Icon>
                    <img src={vendor} className="fas fa-user-tie"></img>
                  </S.Icon>
                  <S.CardHeading>Vendor Registration</S.CardHeading>
                  <S.CardParagraph>Need ADI to become a Vendor?</S.CardParagraph>
                  <S.CardParagraph>
                    Inquire about Vendor Registration by contacting us at{' '}
                    <S.ContactLink href="#" target="_blank" rel="noopener noreferrer">
                      howdy@argumentdriveninquiry.com
                    </S.ContactLink>
                  </S.CardParagraph>
                </S.Card>
                <S.Card className="accounting">
                  <S.Icon>
                    <img src={accounting} className="fas fa-file-invoice-dollar"></img>
                  </S.Icon>
                  <S.CardHeading>Accounting</S.CardHeading>
                  <S.CardParagraph>Have a question about a Purchase Order?</S.CardParagraph>
                  <S.ContactLink className="phone">p: 512-777-4099</S.ContactLink>
                  <S.ContactLink className="email">billing@argumentdriveninquiry.com</S.ContactLink>
                  <S.CardParagraph>
                    Learn how to enter with a school purchase order here.{' '}
                    <S.ContactLink href="#" target="_blank" rel="noopener noreferrer">
                      Apply for a Tax Exempt Account here
                    </S.ContactLink>
                  </S.CardParagraph>
                </S.Card>
                <S.Card className="store">
                  <S.Icon>
                    <img src={store} className="fas fa-store"></img>
                  </S.Icon>
                  <S.CardHeading>Store</S.CardHeading>
                  <S.CardParagraph>Have a question about an order?</S.CardParagraph>
                  <S.ContactLink className="email">orders@argumentdriveninquiry.com</S.ContactLink>
                </S.Card>
                <S.Card className="learning">
                  <S.Icon>
                    <img src={learning} className="fas fa-chalkboard-teacher"></img>
                  </S.Icon>
                  <S.CardHeading>Professional Learning</S.CardHeading>
                  <S.CardParagraph>Have a question about Professional Learning?</S.CardParagraph>
                  <S.ContactLink className="email">pls@argumentdriveninquiry.com</S.ContactLink>
                  <S.CardParagraph>
                    Talk with a Sales Representative about scheduling PL for educators in
                    your district here.
                  </S.CardParagraph>
                </S.Card>
                <S.Card className="hub">
                  <S.Icon>
                    <img src={adi} className="fas fa-cog"></img>
                  </S.Icon>
                  <S.CardHeading>ADI Learning Hub</S.CardHeading>
                  <S.CardParagraph>
                    Interested in finding out more about the ADI Learning Hub? Fill out
                    this form or email us to be connected with a sales
                    representative.
                  </S.CardParagraph>
                </S.Card>
              </S.CardContainer>
            </S.Container>
          </S.Modal>


          {/* Second Modal */}
          <S.Modal width='80%'
            visible={isKnowlegeModalVisible}
            onCancel={() => setIsKnowlegeModalVisible(false)}

          >
            <S.ContainerK>
              <S.HeadingK>How can we help you?</S.HeadingK>
              <form onSubmit={handleSearch}>
                <S.SearchInputK
                  type="text"
                  placeholder="Search for answer..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  formTarget='_blank'
                />
              </form>
              <S.CardContainerK>
                {[
                  { icon: adi, title: 'Getting Started', description: 'Familiarize yourself with the basics of the ADI Learning Hub.', link: 'https://help.adilearninghub.com/knowledge/getting-started' },
                  { icon: organization, title: 'Organization', description: 'Tools for Organization Leaders in the ADI Learning Hub.', link: 'https://help.adilearninghub.com/knowledge/organization' },
                  { icon: teacher, title: 'Teacher', description: 'Tools for a Teacher in ADI Learning Hub.', link: 'https://help.adilearninghub.com/knowledge/teacher' },
                  { icon: student, title: 'Student', description: 'Tools for a student in ADI Learning Hub.', link: 'https://help.adilearninghub.com/knowledge/student' },
                  { icon: privacyPolicy, title: 'Privacy & Security', description: 'Privacy and Information about your protected information.', link: 'https://help.adilearninghub.com/knowledge/privacy-security' },
                  { icon: support, title: 'Support', description: 'Contact the Learning Hub team.', link: 'https://help.adilearninghub.com/knowledge/support' }
                ].map((card, index) => (
                  <S.CardK key={index}>
                    <S.IconK>
                      <img src={card.icon} alt={card.title} />
                    </S.IconK>
                    <S.CardHeadingK>{card.title}</S.CardHeadingK>
                    <S.CardParagraphK>{card.description}</S.CardParagraphK>
                    <S.CardButtonK as="a" href={card.link} target="_blank" rel="noopener noreferrer">
                      View more
                    </S.CardButtonK>
                  </S.CardK>
                ))}
              </S.CardContainerK>

            </S.ContainerK>
          </S.Modal>

        </S.Col>
        {menuVisible && (
          <S.ResponsiveMenuContainer $menuVisible={menuVisible} span={24}>
            {renderMenu()}
          </S.ResponsiveMenuContainer>
        )}
      </>
    );


  }, [
    history,
    user,
    isStudent,
    isAdiSuperAdmin,
    isAdiAdmin,
    isTeacherOrFacilitator,
    loading,
    menuVisible,
    notificationVisible,
    pendingNotifications,
    profileMenu,
    renderMenu,
    showNotifications,
    unreadMessagesData,
    roles, // Ensure roles is included if used within the component
    isContactUsModalVisible,
    isKnowlegeModalVisible,
    searchTerm,
  ]);




  return (
    <S.Header>
      <S.Row align="middle" onMouseLeave={() => setNotificationVisible(false)} $menuVisible={menuVisible}>
        <Col xl={0} xs={3}>
          <Button
            onClick={toggleMenuVisible}
            type="primary"
            style={{ marginLeft: 30 }}
            icon={menuVisible ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
        </Col>
        <S.Col $defaultAlign="end" $responsiveAlign="start" span={3}>
          <S.LogoImage
            data-cy="shared-authenticatedheader-logo"
            src={logo}
            alt="ADI Logo"
            onClick={() => {
              history.push('/');
            }}
          />
        </S.Col>
        {renderAuthenticatedMenu}
      </S.Row>
    </S.Header>
  );
};
export default AuthenticatedHeader;

