import React, { useRef, useCallback, useState, useEffect, RefObject } from 'react';
import { Col, Row, Input as AntdInput, Button, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft, FiSearch } from 'react-icons/fi';
import { startCase } from 'lodash';
import { IInvestigationListFilters } from '..';
import useStateStandardFilter, { useNgssStandarFilter } from '../../../hooks/useStateStandardFilter';
import Input from '../../../shared/Input';
import { cleanCoreIdeaCode, sortTexasStandardsByCode } from '../../../utils/utils';
import { GQL_InvestigationStandard } from '../../../types/investigationStandard';
import { gradesFilterListAllBands } from '../../../utils/constants';
import { useLibraryFilters } from '../../../hooks/useLibraryFliters';
import * as S from './styles';
import { SUBJECT_TYPES } from '../../../types/subjects';
// import useStateStandardsFilterMenuItemToggle from '../../../hooks/useStateStandardsFilterMenuItemToggle';
// import { GQL_CodeTextPair } from '../../../types/investigation';

const { Option, OptGroup } = Select;

interface IInvestigationListHeader {
  backUrl?: string;
  updateSearchText: (text: string) => void;
  refreshMethod: () => void;
  filters: IInvestigationListFilters[];
  setFilters: (filters: IInvestigationListFilters[]) => void;
  stateStandards?: GQL_InvestigationStandard[] | undefined;
  ngssStateStandards?: GQL_InvestigationStandard[] | undefined;
  type?: string;
}

interface KeywordFilterProps {
  inputRef: RefObject<AntdInput>;
  selectedKeyword: string;
  setSelectedKeyword: (value: string) => void;
  handleInputSearch: () => void;
}
interface TeacherFavouriteFilterProps {
  filtersBeforeFetch: any;
  setNewFilter: any;
}

interface TrialFilterProps {
  filtersBeforeFetch: any;
  setNewFilter: any;
}

interface SubjectFilterProps {
  filtersBeforeFetch: any;
  setNewFilter: any;
}
interface GradeFilterProps {
  filtersBeforeFetch: any;
  setNewFilter: any;
}
interface DisciplineFilterProps {
  filteredDisciplines: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}
interface CoreIdeaFilterProps {
  filteredCoreIdeas: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}

interface StandardFilterProps {
  getFilteredNGSSStandards: any;
  getFilteredTexasStandards: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}

interface PublishedStatusFilterProps {
  publishedStatuses: any;
  filtersBeforeFetch: any;
  setNewFilter: any;
}

const InvestigationListHeader: React.FC<IInvestigationListHeader> = ({
  backUrl,
  updateSearchText,
  refreshMethod,
  filters,
  setFilters,
  stateStandards,
  ngssStateStandards,
}) => {
  const history = useHistory();
  const inputRef = useRef<AntdInput>(null);
  
  const { stateStandardFilterData } = useStateStandardFilter({ stateStandards });
  const { ngssStandardFilterData } = useNgssStandarFilter({ stateStandards: ngssStateStandards });

  const { coreIdeas, disciplines, publishedStatuses } = useLibraryFilters();
  const defaultFilters: IInvestigationListFilters[] = [
    { field: "subject", value: "" },
    { field: "grade", value: "" },
    { field: "discipline", value: "" },
    { field: "coreIdea", value: "" },
    { field: 'state', value: "" },
    { field: "status", value: "" },
    { field: "teacherFavorite", value: "" },
    { field: "trial", value: "" },
  ];
  const [filtersBeforeFetch, setFiltersBeforeFetch] = useState<IInvestigationListFilters[]>(filters.length > 0 ? filters : defaultFilters);
  const [filteredDisciplines, setFilteredDisciplines] = useState<any[]>([]);
  const [filteredCoreIdeas, setFilteredCoreIdeas] = useState<any[]>([]);
  const [selectedKeyword, setSelectedKeyword] = useState<string>('');
  
  const handleInputSearch = useCallback(() => {
    updateSearchText(inputRef?.current?.state?.value);
  }, [updateSearchText]);

  const filterObjectByField = (object: any, field: string) => object?.[field] ? { [field]: object[field] } : {};

  const filterAndConvertObject = useCallback((array: any, type: string, value: string) => {
    const filteredRaw = filterObjectByField(array[type], value);
    return Object.values(filteredRaw).flat();
  }, []);

  const setFilteredOptions = useCallback(() => {
    const subject = filtersBeforeFetch.find((f) => f.field === "subject")?.value || "";
    const grade = filtersBeforeFetch.find((f) => f.field === "grade")?.value || "";

    if (subject && grade) {
      setFilteredDisciplines(filterAndConvertObject(disciplines, subject, grade));
      setFilteredCoreIdeas(filterAndConvertObject(coreIdeas, subject, grade));
    }
  }, [filtersBeforeFetch, disciplines, coreIdeas, filterAndConvertObject]);

  useEffect(() => {
    if (filtersBeforeFetch.length > 0) {
      setFilteredOptions();
    }
  }, [filtersBeforeFetch, disciplines, coreIdeas, setFilteredOptions]);

  const setNewFilter = (filter: IInvestigationListFilters) => {
    const updatedFilters = filtersBeforeFetch.filter((f) => f.field !== filter.field);
    
    if (filter.field === 'subject') {
      setFiltersBeforeFetch([
        ...updatedFilters.filter((f) => !['grade', 'coreIdea', 'discipline', 'state'].includes(f.field)),
        { field: "grade", value: "" },
        { field: "discipline", value: "" },
        { field: "coreIdea", value: "" },
        { field: "state", value: "" },
        filter,
      ]);
    } else if (filter.field === 'grade') {
      const subject = filtersBeforeFetch.find((f) => f.field === 'subject')?.value || "";
      setFilteredDisciplines(filterAndConvertObject(disciplines, subject, filter.value));
      setFilteredCoreIdeas(filterAndConvertObject(coreIdeas, subject, filter.value));
      setFiltersBeforeFetch([
        ...updatedFilters.filter((f) => !['coreIdea', 'discipline', 'state'].includes(f.field)),
        { field: "discipline", value: "" },
        { field: "coreIdea", value: "" },
        { field: "state", value: "" },
        filter,
      ]);
    } else {
      setFiltersBeforeFetch([...updatedFilters, filter]);
    }
  };

  const handleClearFilters = () => {
    setFiltersBeforeFetch(defaultFilters);
    setSelectedKeyword("");
  };

  const handleSearchAction = () => {
    setFilters(filtersBeforeFetch);
    updateSearchText(inputRef?.current?.state?.value);
  };

  const getFilteredTexasStandards = () => {
    const selectedGrade = filtersBeforeFetch.find((f) => f.field === 'grade')?.value.replace('K', '0') || "";
    return stateStandardFilterData.filter((grade) => {
      if (selectedGrade.includes("-")) {
        const [lowerBound, upperBound] = selectedGrade.split("-").map((g:any) => g.trim());
        const gradeKey = parseInt(grade.key.replace('Grade 0','').replace('K', '0').replace('Grade', ''));
        return (gradeKey >= parseInt(lowerBound) && gradeKey <= parseInt(upperBound)) || grade.standards?.some((standard) => standard.grade.replace('0', '') === selectedGrade);
      } else {
        return grade.key.replace('Grade 0','') === selectedGrade || grade.standards?.some((standard) => standard.grade.replace('0', '') === selectedGrade);
      }
    });
  };

  const getFilteredNGSSStandards = () => {
    const selectedGrade = filtersBeforeFetch.find((f) => f.field === 'grade')?.value || "";
    if (selectedGrade.includes("-")) {
      return ngssStandardFilterData.filter((gradeBand) => gradeBand.key.includes(selectedGrade) || gradeBand.standards?.some((standard) => standard.code.startsWith(selectedGrade.split("-")[0])));
    } else {
      return ngssStandardFilterData.filter((gradeBand) => {
        const [lowerBound, upperBound] = gradeBand.key.replace("Grade ", "").split("-");
        const transformedGrade = (selectedGrade === 'K') ? '0' : selectedGrade;
        return (transformedGrade >= lowerBound && transformedGrade <= upperBound) || gradeBand.standards?.some((standard) => standard.code.startsWith(selectedGrade));
      });
    }
  };

  return (
    <S.HeaderContainer onClick={(e) => e.stopPropagation()} gutter={24}>
      <Col xs={1}>
        <S.BackIconButton onClick={() => (backUrl) ? history.push(backUrl) : history.goBack()}>
          <FiArrowLeft />
        </S.BackIconButton>
      </Col>
      <Col xs={23}>
        <Row gutter={[16, 16]} align="stretch" id="assessment-list-header">
          <KeywordFilter 
            inputRef={inputRef} 
            selectedKeyword={selectedKeyword} 
            setSelectedKeyword={setSelectedKeyword} 
            handleInputSearch={handleInputSearch} 
          />
          <SubjectFilter 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <GradeFilter 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <DisciplineFilter 
            filteredDisciplines={filteredDisciplines} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <CoreIdeaFilter 
            filteredCoreIdeas={filteredCoreIdeas} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <StandardFilter 
            getFilteredNGSSStandards={getFilteredNGSSStandards()} 
            getFilteredTexasStandards={getFilteredTexasStandards()} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <PublishedStatusFilter 
            publishedStatuses={publishedStatuses} 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <TeacherFavouriteFilter 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
          <TrialFilter 
            filtersBeforeFetch={filtersBeforeFetch} 
            setNewFilter={setNewFilter} 
          />
        
          <Col md={6} xs={3} sm={4} style={{ marginLeft: '25%' }}>
            <Button
              type="primary"
              onClick={handleSearchAction}
              style={{ width: '100%' }}
            >
              Search
            </Button>
          </Col>
          <Col md={6} xs={3} sm={4}>
            <Button
              onClick={handleClearFilters}
              style={{ width: '100%' }}
            >
              Clear All Filters
            </Button>
          </Col>
        </Row>
      </Col>
    </S.HeaderContainer>
  );
};
const KeywordFilter: React.FC<KeywordFilterProps> = ({ inputRef, selectedKeyword, setSelectedKeyword, handleInputSearch }) => (
  <Col md={6} xs={12} sm={12}>
    <Input
      ref={inputRef}
      value={selectedKeyword}
      onChange={(e) => setSelectedKeyword(e.target.value)}
      placeholder="Search"
      icon={<FiSearch />}
      onPressEnter={handleInputSearch}
      style={{ height: '32px', width: '100%', borderRadius: '0', marginTop: '3px' }}
    />
  </Col>
);
const SubjectFilter: React.FC<SubjectFilterProps> = ({ filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Subject"
      onChange={(value) => setNewFilter({ field: 'subject', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'subject')?.value || undefined}
      style={{ width: '100%' }}
    >
    <Option
      value={SUBJECT_TYPES.MATH}
      disabled={filtersBeforeFetch.some((filter:any) => filter.value === SUBJECT_TYPES.MATH)}
    >
      {SUBJECT_TYPES.MATH}
    </Option>
    <Option
      value={SUBJECT_TYPES.SCIENCE}
      disabled={filtersBeforeFetch.some((filter:any) => filter.value === SUBJECT_TYPES.SCIENCE)}
    >
      {SUBJECT_TYPES.SCIENCE}
    </Option>
    <Option
      value={SUBJECT_TYPES.ENGINEERING}
      disabled={filtersBeforeFetch.some((filter:any) => filter.value === SUBJECT_TYPES.ENGINEERING)}
    >
      {SUBJECT_TYPES.ENGINEERING}
    </Option>
    <Option
      value={SUBJECT_TYPES.SCIENCE_TEXAS_EDITION}
      disabled={filtersBeforeFetch.some((filter:any) => filter.value === SUBJECT_TYPES.SCIENCE_TEXAS_EDITION)}
    >
      {SUBJECT_TYPES.SCIENCE_TEXAS_EDITION.replaceAll("_", " ")}
    </Option>
    </Select>
  </Col>
);

const GradeFilter: React.FC<GradeFilterProps> = ({ filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Grade"
      onChange={(value) => setNewFilter({ field: 'grade', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'grade')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'subject')[0].value)}
    >
      {gradesFilterListAllBands.map((grade:any, i:number) => (
        <Option key={`${grade}-${i}`} value={grade}>{`Grade ${grade}`}</Option>
      ))}
    </Select>
  </Col>
);

const DisciplineFilter: React.FC<DisciplineFilterProps> = ({ filteredDisciplines, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Discipline"
      onChange={(value) => setNewFilter({ field: 'discipline', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'discipline')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value)}
    >
      <OptGroup label="Discipline">
        {filteredDisciplines.map((discipline:any) => (
          <Option
            key={`discipline-${discipline.name}`}
            value={discipline.name}>{startCase(discipline.name)}
          </Option>
        ))}
      </OptGroup>
    </Select>
  </Col>
);

const CoreIdeaFilter: React.FC<CoreIdeaFilterProps> = ({ filteredCoreIdeas, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Core Idea"
      onChange={(value) => setNewFilter({ field: 'coreIdea', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'coreIdea')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value)}
    >
      <OptGroup label="Core Idea">
        {filteredCoreIdeas.sort(sortTexasStandardsByCode).map((coreIdea:any) => (
          <Option key={`core-idea-${coreIdea.code}`} value={coreIdea.code}>{startCase(coreIdea.text)} {cleanCoreIdeaCode(coreIdea.code)}</Option>
        ))}
      </OptGroup>
    </Select>
  </Col>
);

const StandardFilter: React.FC<StandardFilterProps> = ({ getFilteredNGSSStandards, getFilteredTexasStandards, filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Standard"
      onChange={(value) => setNewFilter({ field: 'state', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'state')?.value || undefined}
      style={{ width: '100%' }}
      disabled={!(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value)}
    >
      <OptGroup key={`NGSS Standards`} label="NGSS Standards">
        {getFilteredNGSSStandards.map((ngss:any, i:number) => (
          // <OptGroup key={`ngss-gradeBands-${i}`} label={ngss.key}>
          ngss.standards
            .filter((standard: any) => standard.code.startsWith(filtersBeforeFetch.filter((f:any) => f.field === 'grade')[0].value.split("-")[0]))
            .map((standard: any, j:number) => (
              <Option
                key={standard.id}
                value={standard.id}
                disabled={filtersBeforeFetch.some((filter:any) => filter.value === standard.id)}
              >
                {`${standard.code} - ${standard.abbreviatedStatement}`}
              </Option>
            ))
          
        // </OptGroup>
        ))}
      </OptGroup>
      <OptGroup key={`Texas Standards`} label="Texas Standards">
        {getFilteredTexasStandards.map((texas:any, i:number) => (
          // <OptGroup key={`texas-grade-${i}`} label={texas.key}>
          texas.standards?.sort(sortTexasStandardsByCode).map((standard:any) => (
            <Option key={standard.id} value={standard.id}>{`${standard.code} - ${standard.abbreviatedStatement}`}</Option>
          ))
          // </OptGroup>
        ))}
      </OptGroup>
    </Select>
  </Col>
);

const PublishedStatusFilter: React.FC<PublishedStatusFilterProps> = ({ filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Select Published Status"
      onChange={(value) => setNewFilter({ field: 'status', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'status')?.value || undefined}
      style={{ width: '100%' }}
    >
      <Option key={`ALL`} value={``}>{'ALL'}</Option>
      <Option key={`published`} value={`published`}>{'Published'}</Option>
      <Option key={`unpublished`} value={`unpublished`}>{'Unpublished'}</Option>
      <Option key={`published`} value={`deleted`}>{'Marked for Deletion'}</Option>
    </Select>
  </Col>
);

const TeacherFavouriteFilter: React.FC<TeacherFavouriteFilterProps> = ({ filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Teacher Favourite"
      onChange={(value) => setNewFilter({ field: 'teacherFavorite', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'teacherFavorite')?.value || undefined}
      style={{ width: '100%' }}
    >
      <Option key={`non-favourite`} value={``}>{'ALL'}</Option>
      <Option key={`favourite`} value={`favourite`}>{'Favorite Only'}</Option>
    </Select>
  </Col>
);

const TrialFilter: React.FC<TrialFilterProps> = ({ filtersBeforeFetch, setNewFilter }) => (
  <Col md={6} xs={3} sm={4}>
    <Select
      placeholder="Trial"
      onChange={(value) => setNewFilter({ field: 'trial', value })}
      value={filtersBeforeFetch.find((f:any) => f.field === 'trial')?.value || undefined}
      style={{ width: '100%' }}
    >
      <Option key={`ALL`} value={``}>{'ALL'}</Option>
      <Option key={`available`} value={`available`}>{'Available'}</Option>
      <Option key={`unavailable`} value={`unavailable`}>{'Unavailable'}</Option>
    </Select>
  </Col>
);
export default InvestigationListHeader;
