import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { AUTH_TOKEN_KEY, getExpirationTokenCasted, GOOGLE_AUTH_TOKEN_KEY, GOOGLE_REFRESH_TOKEN_KEY, REFRESH_TOKEN_KEY, TOKEN_EXPIRATION_KEY } from '../../hooks/useAuth';
import history from '../../history';
import { GQL_RefreshToken } from '../../types/login';
import { getMainDefinition } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import extractFiles from '../../utils/extractFile';

let newTokenPromise: Promise<any> | null;
let newTokenPromiseGoogle: Promise<any> | null;

const newToken = async () => {
  try {
    let refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    const baseUrl = process.env.REACT_APP_API_URL || 'https://adi-api-prod.adilearninghub.com/graphql';

    const response = await fetch(baseUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          query {
            refreshToken(data: {token: "${refreshToken}"}) {
              refreshToken
              accessToken
              expiresIn
            }
          }
      `,
      }),
    });

    const parsedResponse = await response.json();
    const parsedData = parsedResponse.data.refreshToken as GQL_RefreshToken;

    if (parsedData) {
      const expiration = getExpirationTokenCasted(parsedData.expiresIn);
      sessionStorage.setItem(AUTH_TOKEN_KEY, parsedData.accessToken);
      sessionStorage.setItem(REFRESH_TOKEN_KEY, parsedData.refreshToken);
      sessionStorage.setItem(TOKEN_EXPIRATION_KEY, String(expiration));
    } else {
      history.push('/session-expired');
    }
  } catch (err) {
    history.push('/session-expired');
  }
};

const newTokenGoogle = async () => {
  try {
    let refreshToken = sessionStorage.getItem(GOOGLE_REFRESH_TOKEN_KEY);
    if (!refreshToken) refreshToken = localStorage.getItem(GOOGLE_REFRESH_TOKEN_KEY);
    const baseUrl = process.env.REACT_APP_API_URL || 'https://adi-api-prod.adilearninghub.com/graphql';

    const response = await fetch(baseUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: `
          query {
            refreshTokenGoogle(data: {token: "${refreshToken}"}) {
              refreshToken
              accessToken
              expiresIn
            }
          }
      `,
      }),
    });

    const parsedResponse = await response.json();
    const parsedData = parsedResponse.data.refreshTokenGoogle as GQL_RefreshToken;


    if (parsedData) {
      sessionStorage.setItem(GOOGLE_AUTH_TOKEN_KEY, parsedData.accessToken);
      sessionStorage.setItem(GOOGLE_REFRESH_TOKEN_KEY, parsedData.refreshToken);
    } else {
      console.log("google Trigereed");
      history.push('/session-expired');
    }
  } catch (err) {
    history.push('/session-expired');
  }
};

const authLink = setContext((_, { headers }) => {
  let token = sessionStorage.getItem(AUTH_TOKEN_KEY);
  let googleToken = sessionStorage.getItem(GOOGLE_AUTH_TOKEN_KEY);
  if (!token) token = localStorage.getItem(AUTH_TOKEN_KEY);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-google-token': googleToken ? ` ${googleToken}` : '',
    },
  };
});

const asyncAuthLink = setContext(async () => {
  // Checks if user is signed in, if not reason to refresh token
  let token = sessionStorage.getItem(AUTH_TOKEN_KEY);
  if (!token) token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (!token) return;

  const expiration = parseInt(
    sessionStorage.getItem(TOKEN_EXPIRATION_KEY) || localStorage.getItem(TOKEN_EXPIRATION_KEY) || '0',
  );

  // If token is expired or there's no expiration date,
  // renew token with refresh token
  if (!expiration || Date.now() > expiration) {

    if (!newTokenPromise) {
      newTokenPromise = newToken();

    }
    if (!newTokenPromiseGoogle) {
      newTokenPromiseGoogle = newTokenGoogle();
    }
    await newTokenPromise;
    newTokenPromise = null;

    await newTokenPromiseGoogle;
    newTokenPromiseGoogle = null;

  }
});


const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL || 'https://adi-api-prod.adilearninghub.com/graphql',
  fetch: window.fetch,
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (error?.extensions?.code === 'UNAUTHENTICATED') {
        history.push('/session-expired');
      } else if (error.extensions?.code?.toString() === '500') {
        // rethrowing error doesn't work, since sentry doesn't recognize it
        throw new Error(`Error 500 on Request: ${JSON.stringify(error)}`);
      }
    });
  }
});

const wsLink = new WebSocketLink({
  uri:
    process.env.REACT_APP_API_URL?.replace('https://', 'wss://').replace('http://', 'ws://') ||
    'wss://adi-api-prod.adilearninghub.com/graphql',
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      let token = sessionStorage.getItem(AUTH_TOKEN_KEY);
      if (!token) token = localStorage.getItem(AUTH_TOKEN_KEY);

      return { authorization: token ? `Bearer ${token}` : '' };
    },
    connectionCallback: async (err) => {
      // Apparently error has a wrong type on the interface, as it should be Error instead of Error[]
      const error = (err as unknown) as Error;

      if (error?.message?.includes('invalid token (expired)')) {
        await newToken();
      }
    },
  },
});

const batchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_API_URL || 'https://adi-api-prod.adilearninghub.com/graphql',
  batchInterval: 10,
  batchMax: 1,
});

const splitLink = split(
  (opts) => {
    const definition = getMainDefinition(opts.query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  split(
    (operation) => extractFiles(operation).files.size > 0,
    ApolloLink.from([
      errorLink,
      (asyncAuthLink as unknown) as ApolloLink,
      (authLink as unknown) as ApolloLink,
      (uploadLink as unknown) as ApolloLink,
    ]),
    ApolloLink.from([
      errorLink,
      (asyncAuthLink as unknown) as ApolloLink,
      (authLink as unknown) as ApolloLink,
      batchHttpLink,
    ]),
  ),
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      StudentResponse: {
        keyFields: ['userId', 'inviteId'],
      },
      ChatListResponse: {
        keyFields: ['chatId'],
      },
      MessagesListResponse: {
        keyFields: ['chatMessageId'],
      },
      InvestigationTeacherSummary_PerStudent_Steps: {
        merge: false,
        keyFields: false,
      },
      InvestigationTeacherSummary: {
        keyFields: ['id', 'classId'],
      },
      Query: {
        fields: {
          getMessages: {
            keyArgs: ['data', ['chatId']],
            merge: (existing = { chat: [] }, incoming) => {
              // If there's no pagination, it's coming from subscription
              if (incoming.pagination) {
                return { ...incoming, ...existing, chat: [...(existing.chat || []), ...incoming.chat] };
              } else {
                return { ...existing, chat: [...incoming.chat, ...(existing.chat || [])] };
              }
            },
          },
        },
      },
    },
  }),
});

export default client;