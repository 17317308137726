import { Col, Row } from 'antd';
import React, { Dispatch, SetStateAction, useMemo, ReactElement } from 'react';
import * as S from './styles';

interface Props {
  title: string | ReactElement;
  titleAlign: 'left' | 'right';
  type: 'text' | 'grid';
  titleColumns?: number;
  margin?: string;
  onChange?: Dispatch<SetStateAction<boolean>> | ((checked: boolean) => void);
  onClick?: () => void;
  size?: 'small' | 'default';
  disabled?: boolean;
  value?: boolean;
  removeWrapperHeight?: boolean;
  loading?: boolean;
}

const Switch: React.FC<Props> = (props) => {
  const {
    title,
    onChange,
    onClick,
    value,
    titleAlign,
    type,
    titleColumns = 24,
    size,
    margin = '0 5px',
    disabled,
    removeWrapperHeight,
    loading,
  } = props;
  const dataCyTitle = typeof title === 'string' ? title.toLowerCase().replace(/ /g, '-') : 'component';
  const switchColums = useMemo(() => {
    const cols = 24 - titleColumns;
    return cols <= 0 ? 24 : cols;
  }, [titleColumns]);
  const text = useMemo(
    () => (
      <S.CText>
        {titleAlign === 'right' && (
          <S.CSwitch
            data-cy={`shared-switch-toggle-${dataCyTitle}-${value ? 'on' : 'off'}`}
            onChange={onChange}
            onClick={onClick}
            checked={value}
            $margin={margin}
            size={size}
            disabled={disabled}
            loading={loading}
          />
        )}
        {title}
        {titleAlign === 'left' && (
          <S.CSwitch
            data-cy={`shared-switch-toggle-${dataCyTitle}-${value ? 'on' : 'off'}`}
            onChange={onChange}
            onClick={onClick}
            checked={value}
            $margin={margin}
            size={size}
            disabled={disabled}
            loading={loading}
          />
        )}
      </S.CText>
    ),
    [titleAlign, onChange, onClick, value, title, margin, size, disabled, loading, dataCyTitle],
  );

  const grid = useMemo(
    () => (
      <Row gutter={8}>
        {titleAlign === 'right' && (
          <Col span={switchColums}>
            <S.CSwitch
              data-cy={`shared-switch-toggle-${dataCyTitle}-${value ? 'on' : 'off'}`}
              onChange={onChange}
              onClick={onClick}
              checked={value}
              $margin={margin}
              size={size}
              disabled={disabled}
              loading={loading}
            />
          </Col>
        )}
        <Col span={titleColumns}> {title}</Col>
        {titleAlign === 'left' && (
          <Col span={switchColums}>
            <S.CSwitch
              data-cy={`shared-switch-toggle-${dataCyTitle}-${value ? 'on' : 'off'}`}
              onChange={onChange}
              onClick={onClick}
              checked={value}
              size={size}
              $margin={margin}
              disabled={disabled}
              loading={loading}
            />
          </Col>
        )}
      </Row>
    ),
    [
      titleAlign,
      switchColums,
      onChange,
      onClick,
      value,
      titleColumns,
      title,
      size,
      margin,
      disabled,
      loading,
      dataCyTitle,
    ],
  );
  return <S.Wrapper removeWrapperHeight={removeWrapperHeight}>{type === 'text' ? text : grid}</S.Wrapper>;
};

export default Switch;
