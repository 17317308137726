import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { useAuth } from '../../../hooks/useAuth';
import * as S from './styles';
import { GQL_GoogleClassroomCourses } from '../../../types/googleClassroom';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { format } from 'date-fns';

interface IClassInfos {
  classInfo?: GQL_GoogleClassroomCourses;
  loading: boolean;
}

const ClassInfos: React.FC<IClassInfos> = ({ classInfo, loading }) => {
  const { user } = useAuth();
  const classTitle = classInfo ? (classInfo.room ? 'Class' : 'Class') : '';
  const [numberOfStudents, setNumberOfStudents] = useState<number | null>(null);

  // Fetch number of students
  const { data: studentsData, loading: studentsLoading } = useQuery<{ getCourseStudents: { numberOfStudents: number } }>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudents,
    {
      variables: { courseId: classInfo?.id },
      skip: !classInfo,
    }
  );

  useEffect(() => {
    if (studentsData) {
      setNumberOfStudents(studentsData.getCourseStudents.numberOfStudents);
    }
  }, [studentsData]);

  // Function to determine status color and text based on course state
  const getStatusColorAndText = (state: string | "UNKNOWN") => {
    switch (state) {
      case 'ACTIVE':
        return { color: 'green', text: 'Ongoing' };
      case 'ARCHIVED':
        return { color: 'black', text: 'Archived' };
      case 'PROVISIONED':
        return { color: 'blue', text: 'Provisioned' };
      case 'DECLINED':
        return { color: 'red', text: 'Declined' };
      case 'SUSPENDED':
        return { color: 'orange', text: 'Suspended' };
      default:
        return { color: 'black', text: state };
    }
  };

  const { color: statusColor, text: statusText } = getStatusColorAndText(classInfo?.courseState || 'UNKNOWN');

  return (
    <S.ClassInfosContainer>
      <Row>
        {/* Class Name */}
        <Col span={8}>
          <S.Title>{classTitle} Name</S.Title>
          {!loading ? (
            <p>{classInfo?.name}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>

        {/* Class Duration */}
        <Col span={8}>
          <S.Title>{classTitle} Start</S.Title>
          {!loading ? (
            <p>
              {classInfo?.creationTime && classInfo?.updateTime
                ? `${format(new Date(classInfo.creationTime), 'dd MMMM yyyy')}`
                : 'Not provided'}
            </p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>

        {/* Class Students */}
        <Col span={8}>
          <S.Title>{classTitle} Students</S.Title>
          {!loading && !studentsLoading ? (
            <p>{numberOfStudents !== null ? `${numberOfStudents} Enrolled Students` : '-'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
      </Row>

      <Row>
        {/* Class Owner */}
        <Col span={8}>
          <S.Title>{classTitle} Owner</S.Title>
          {!loading ? (
            <p>{user ? `${user.name} - ${user.email}` : '-'}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
        {/* Class Status */}
        <Col span={8}>
          <S.Title>{classTitle} Status</S.Title>
          {!loading ? (
            <p style={{ color: statusColor }}>{statusText}</p>
          ) : (
            <Skeleton.Input active size="small" />
          )}
        </Col>
      </Row>
    </S.ClassInfosContainer>
  );
};

export default ClassInfos;
