import { useLazyQuery } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../gql/schema';
import PageWithTitle from '../../shared/PageWithTitle';
import { GQL_OrganizationDetailsResponse } from '../../types/organization';
import { GQL_UserDetailsResponsePaginated, GQL_UsersListFilterInput } from '../../types/user';
import LinkedSubscriptions from '../../shared/LinkedSubscriptions';
import OrganizationAdmins from './OrganizationAdmins';
import OrganizationInfo from './OrganizationInfo';
import { useAuth } from '../../hooks/useAuth';
import { Col, message, Row } from 'antd';
import Button from '../../shared/Button';
import * as S from './styles';
import DownloadInfo from './DownloadInfo';

type RouterProps = RouteComponentProps<{
  organizationId: string;
}>;

const OrganizationDetailsPage = (props: RouterProps) => {
  const { organizationId } = props.match.params;
  const { isOrganizationAdiAdmin, user } = useAuth();
  const [downloadDataVisible, setDownloadDataVisible] = useState(false);

  const [fetchOrganization, { data }] = useLazyQuery<
    { getOrganizationDetails: GQL_OrganizationDetailsResponse },
    { id: string }
  >(gqlSchema.OrganizationSchema.queries.DETAILS.getOrganizationDetails, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading organization details: ' + err.message || 'Unexpected Error');
    },
  });

  const [fetchTeachers, { data: organizationTeachers }] = useLazyQuery<
    { getAllUsersByFilter: GQL_UserDetailsResponsePaginated },
    { data: GQL_UsersListFilterInput }
  >(gqlSchema.UserSchema.queries.LIST.getAllUsersByFilter, {
    fetchPolicy: 'network-only',
    onError: (err) => {
      message.error('There was an error loading users: ' + err.message || 'Unexpected Error');
    },
  });

  const getOrganization = useCallback(() => {
    let id = organizationId;
    if (!organizationId && isOrganizationAdiAdmin) id = user.subscription?.organizationId || '';

    fetchOrganization({ variables: { id } });
  }, [organizationId, user, isOrganizationAdiAdmin, fetchOrganization]);

  const getTeachers = useCallback(() => {
    let id = organizationId;
    if (!organizationId && isOrganizationAdiAdmin) id = user.subscription?.organizationId || '';

    fetchTeachers({
      variables: {
        data: {
          organizationId: id,
          roles: ['teacher'],
        },
      },
    });
  }, [organizationId, user, fetchTeachers, isOrganizationAdiAdmin]);

  useEffect(() => {
    getOrganization();
    getTeachers();
  }, [getOrganization, getTeachers]);

  return (
    <PageWithTitle
      title={data?.getOrganizationDetails ? `${data.getOrganizationDetails.name} Details` : 'Loading...'}
      extra={
        isOrganizationAdiAdmin ? (
          <Row justify="end">
            <Col
              xxl={{ span: 20 }}
              xl={{ span: 16 }}
              lg={{ span: 14 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Button
                text="Download Organization Info."
                style={{ marginLeft: 'auto' }}
                block
                onClick={() => setDownloadDataVisible(true)}
              />
            </Col>
          </Row>
        ) : null
      }
    >
      <OrganizationInfo
        organization={data?.getOrganizationDetails}
        refetchOrganization={getOrganization}
        teachers={organizationTeachers?.getAllUsersByFilter.users}
      />
      <OrganizationAdmins
        organization={data?.getOrganizationDetails}
        refetchOrganization={getOrganization}
        teachers={organizationTeachers?.getAllUsersByFilter.users}
        refetchTeachers={getTeachers}
      />
      <LinkedSubscriptions
        subscription={data?.getOrganizationDetails.subscription}
        teachers={organizationTeachers?.getAllUsersByFilter.users}
        organizationId={organizationId}
      />
      <S.CModal
        visible={downloadDataVisible}
        title={<S.Title>Download Organization Info.</S.Title>}
        onCancel={() => setDownloadDataVisible(false)}
        width={800}
        footer={null}
      >
        <DownloadInfo />
      </S.CModal>
    </PageWithTitle>
  );
};

export default withRouter(OrganizationDetailsPage);
