import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Col, message, Row, Skeleton, Tooltip } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import Input from '../../../shared/Input';
import Select from '../../../shared/Select';
import SelectOption from '../../../shared/Select/Option';
import { themeConfig } from '../../../utils/theme';

import * as S from './styles';

interface StuckStudentPeerReviewModalProps {
  investigationName: string;
  className: string;
  activityName: string;
  stageName: string;
  studentName: string;
  studentBlockersAmount: number;
  studentBlockersNameReadable: string;
  visible: boolean;
  students: { key: string; text: string }[];
  reassignedStudent?: { key: string; text: string };
  loading: boolean;
  resetParams: (() => void) | undefined;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleDoNothing: () => void;
  handleConfirmReAssignment: (selectedStudent: string) => void;
  disableDoNothing?: boolean;
}

interface StuckStudentReflectionModalProps {
  investigationName: string;
  className: string;
  activityName: string;
  stageName: string;
  studentName: string;
  studentBlockersAmount: number;
  studentBlockersNameReadable: string;
  visible: boolean;
  students: { key: string; text: string }[];
  reassignedStudent?: { key: string; text: string };
  loading: boolean;
  resetParams: (() => void) | undefined;
  setVisible: Dispatch<SetStateAction<boolean>>;
  handleDoNothing: () => void;
  handleConfirmReAssignment: (selectedStudent: string, extraPoint: number) => void;
  handlePeerReviewByYourself: () => void;
  disableDoNothing?: boolean;
}

interface StuckStudentModalProps {
  classId: string;
  activityId: string;
  studentId: string;
  notificationId: string;
  submissionVersion: number;
}

const StuckStudentModal = (props: StuckStudentModalProps) => {
  // states
  const { search } = useLocation();
  const { user } = useAuth();
  const history = useHistory();
  const [visible, setVisible] = useState(search.includes('peer-review') || search.includes('reflection'));
  const initialType = search.includes('peer-review') ? 'peer-review' : 'reflection';
  const [type] = useState<'peer-review' | 'reflection'>(initialType);
  const { submissionVersion } = props;

  // variable
  const classId = props?.classId;
  const activityId = props?.activityId;
  const studentId = props?.studentId;
  const notificationId = props?.notificationId;

  // queries & mutations
  const { data: notificationData } = useQuery(gqlSchema.NotificationSchema.queries.NOTIFICATIONS.getNotifications, {
    fetchPolicy: 'cache-only',
  });
  const [getReassignmentData, { data: modalData, loading: loadingModal }] = useLazyQuery(
    gqlSchema.InvestigationSchema.queries.REASSIGNMENT.getReassignmentData,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [reassignmentMutation] = useMutation(
    gqlSchema.InvestigationSchema.mutations.REASSIGNMENT.assignSubmissionForPeerReview,
    {
      onCompleted: () => {
        setVisible(false);
        handleResetParams();
      },
    },
  );
  const [notifyStudentReassignmentDoNothingMutation] = useMutation(
    gqlSchema.InvestigationSchema.mutations.REASSIGNMENT.notifyStudentReassignmentDoNothing,
    {
      onCompleted: () => {
        message.success('The student will receive a notification telling him/her to wait a little more time.');
      },
    },
  );
  const [createPeerReview] = useMutation(gqlSchema.InvestigationSchema.mutations.REASSIGNMENT.createTeacherPeerReview);

  useEffect(() => {
    if (visible) {
      getReassignmentData({
        variables: {
          classId: classId,
          activityId: activityId,
          studentId: studentId,
          activityType: initialType.toLocaleUpperCase().replace('-', '_'),
          notificationId: notificationId,
          submissionVersion: submissionVersion,
        },
      });
    }
  }, [getReassignmentData, initialType, activityId, classId, studentId, visible, notificationId, submissionVersion]);

  const notificationList = notificationData?.getNotifications;
  const currentNotification = useMemo(() => {
    return notificationList?.find((n: any) => n.id === notificationId);
  }, [notificationList, notificationId]);

  // prevent from accessing non-existing-yet variables
  if (!visible || !modalData) {
    return <React.Fragment />;
  }

  // variables
  const investigationName = modalData?.getReassignmentData?.investigation?.title;
  const className = modalData?.getReassignmentData?.class?.name;
  const activityName = modalData?.getReassignmentData?.activity?.name;
  const stageName = modalData?.getReassignmentData?.step?.name;
  const studentName = modalData?.getReassignmentData?.stuckUser?.fullName;
  const studentBlockersNames = modalData?.getReassignmentData?.blockerUsers?.map((u: any) => u.fullName) || [];
  const studentBlockersAmount = studentBlockersNames?.length;
  const studentBlockersNameReadable = studentBlockersNames.length
    ? [studentBlockersNames.slice(0, -1).join(', '), studentBlockersNames.slice(-1)[0]].join(
        studentBlockersNames.length < 2 ? '' : ' and ',
      )
    : '';
  const studentsList = modalData?.getReassignmentData?.newUsersToReassign?.map((u: any) => ({
    key: u.id,
    text: u.fullName,
  }));
  const reassignedStudent = modalData?.getReassignmentData?.reassignedStudent
    ? {
        key: modalData?.getReassignmentData?.reassignedStudent?.id,
        text: modalData?.getReassignmentData?.reassignedStudent?.fullName,
      }
    : undefined;

  // handlers
  const handleResetParams = () => {
    history.replace({ pathname: history.location.pathname, search: '' });
  };
  const handleDoNothing = () => {
    setVisible(false);
    notifyStudentReassignmentDoNothingMutation({
      variables: {
        classId: classId,
        activityId: activityId,
        studentId: studentId,
        activityType: initialType.toLocaleUpperCase().replace('-', '_'),
        notificationId: notificationId,
      },
    });
    handleResetParams();
  };
  const handleConfirmReAssignmentPeerReview = (selectedStudent: string) => {
    reassignmentMutation({
      variables: {
        classInvestigationId: modalData?.getReassignmentData?.investigation?.classInvestigationId,
        classStepId: modalData?.getReassignmentData?.step?.classStepId,
        activityId: modalData?.getReassignmentData?.activity?.id,
        studentReceiverId: modalData?.getReassignmentData?.stuckUser?.id,
        studentSubmitterId: selectedStudent,
        notificationId: notificationId,
        activityType: initialType.toLocaleUpperCase().replace('-', '_'),
      },
    });
  };

  const handleConfirmReAssignmentReflection = (selectedStudent: string, extraPoint: number) => {
    reassignmentMutation({
      variables: {
        classInvestigationId: modalData?.getReassignmentData?.investigation?.classInvestigationId,
        classStepId: modalData?.getReassignmentData?.step?.classStepId,
        activityId: modalData?.getReassignmentData?.activity?.id,
        studentReceiverId: selectedStudent,
        studentSubmitterId: modalData?.getReassignmentData?.stuckUser?.id,
        notificationId: notificationId,
        activityType: initialType.toLocaleUpperCase().replace('-', '_'),
        extraEngagementPoints: extraPoint,
      },
    });
  };
  const handlePeerReviewByYourself = async () => {
    await createPeerReview({
      variables: {
        studentId: modalData?.getReassignmentData?.stuckUser?.id,
        classStepId: modalData?.getReassignmentData?.step?.classStepId,
        activityId: activityId,
      },
    });
    const isReportPeerReview = modalData?.getReassignmentData?.peerReviewByYourselfType === 'REPORT';

    history.push(
      `/student-investigation/${modalData?.getReassignmentData?.investigation?.classInvestigationId}/${
        isReportPeerReview ? 'peer-review-report' : 'peer-review'
      }/${modalData?.getReassignmentData?.step?.classStepId}/${
        isReportPeerReview
          ? modalData?.getReassignmentData?.reviewInvestigationId
          : modalData?.getReassignmentData?.activity?.id
      }/${modalData?.getReassignmentData?.stuckUser?.id}`,
      {
        userId: user.id,
      },
    );
  };

  // renders
  if (type === 'peer-review') {
    return (
      <StuckStudentModalPeerReview
        investigationName={investigationName}
        className={className}
        activityName={activityName}
        stageName={stageName}
        studentName={studentName}
        studentBlockersAmount={studentBlockersAmount}
        studentBlockersNameReadable={studentBlockersNameReadable}
        visible={visible}
        students={studentsList}
        reassignedStudent={reassignedStudent}
        setVisible={setVisible}
        handleDoNothing={handleDoNothing}
        handleConfirmReAssignment={handleConfirmReAssignmentPeerReview}
        loading={loadingModal}
        resetParams={handleResetParams}
        disableDoNothing={currentNotification?.metadata?.hasDoneNothing}
      />
    );
  }

  return (
    <StuckStudentModalReflection
      investigationName={investigationName}
      className={className}
      activityName={activityName}
      stageName={stageName}
      studentName={studentName}
      studentBlockersAmount={studentBlockersAmount}
      studentBlockersNameReadable={studentBlockersNameReadable}
      visible={visible}
      students={studentsList}
      setVisible={setVisible}
      handleDoNothing={handleDoNothing}
      handleConfirmReAssignment={handleConfirmReAssignmentReflection}
      handlePeerReviewByYourself={handlePeerReviewByYourself}
      loading={loadingModal}
      resetParams={handleResetParams}
      disableDoNothing={currentNotification?.metadata?.hasDoneNothing}
    />
  );
};

const StuckStudentModalPeerReview: React.FC<StuckStudentPeerReviewModalProps> = (props) => {
  const [selectedStudent, setSelectedStudent] = useState<string | undefined>(props?.reassignedStudent?.key);

  if (props.loading) {
    return <Skeleton paragraph={{ rows: 4 }} active />;
  }

  return (
    <S.Modal
      visible={props.visible}
      afterClose={props.resetParams}
      width={900}
      destroyOnClose
      closable
      onCancel={() => {
        props.setVisible(false);
        props.resetParams?.();
      }}
      title={
        <Row style={{ marginTop: 12 }} justify="center">
          <Col span={24}>
            <S.Title>Manage stuck student during Peer Review</S.Title>
          </Col>
        </Row>
      }
      footer={
        <Row justify="center" style={{ marginTop: 8, marginBottom: 16 }}>
          <Col span={20}>
            <Row justify="center" gutter={[16, 16]}>
              <Col span={8}>
                <Button
                  text="Do Nothing"
                  onClick={props.handleDoNothing}
                  block
                  theme={themeConfig.noColor}
                  minHeight={40}
                  disabled={props.disableDoNothing || !!props?.reassignedStudent?.key}
                />
              </Col>
              <Col span={8}>
                <Button
                  disabled={!selectedStudent || props.disableDoNothing}
                  type="primary"
                  onClick={() => props.handleConfirmReAssignment(selectedStudent ?? '')}
                  block
                  text="Confirm Re-Assignment"
                  minHeight={40}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <S.Wrapper>
        <S.SubTitle>Context</S.SubTitle>
        <S.Divider />
        <S.Info>
          When completing the <strong>Investigation {props.investigationName}</strong>, on{' '}
          <strong>Class {props.className}</strong> for the <strong>Activity {props.activityName}</strong> at{' '}
          <strong>Stage {props.stageName}</strong>, <strong>{props.studentName}</strong> got stuck.
        </S.Info>

        <S.SubTitle>What should have happened</S.SubTitle>
        <S.Divider />
        <S.Info>
          <strong>{props.studentName}</strong> is supposed to peer review work from students{' '}
          <strong>{props.studentBlockersNameReadable}</strong>, but{' '}
          {props.studentBlockersAmount > 1 ? 'none of the' : 'the'} student
          {props.studentBlockersAmount > 1 ? 's have' : "hasn't"} submitted work yet.
        </S.Info>

        <S.SubTitle>Possible Action Item</S.SubTitle>
        <S.Divider />
        <S.Info>
          Assign{' '}
          <Select
            style={{ width: 200, margin: '0 4px' }}
            allowClear
            placeholder="Select a student"
            defaultValue={selectedStudent}
            onChange={(value) => setSelectedStudent(value as string)}
            disabled={props.disableDoNothing}
          >
            {props?.reassignedStudent && (
              <SelectOption value={props?.reassignedStudent?.key} key={props?.reassignedStudent?.key}>
                {props?.reassignedStudent?.text}
              </SelectOption>
            )}
            {props?.students?.map((student) => (
              <SelectOption value={student.key} key={student.key}>
                {student.text}
              </SelectOption>
            ))}
          </Select>{' '}
          submission for <strong>{props.studentName}</strong> to peer review.
        </S.Info>
      </S.Wrapper>
    </S.Modal>
  );
};

const StuckStudentModalReflection: React.FC<StuckStudentReflectionModalProps> = (props) => {
  const [selectedStudent, setSelectedStudent] = useState<string | undefined>();
  const [selectedExtraPoint, setSelectedExtraPoint] = useState(0);
  const handleExtraPointChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    if (newValue >= 10) {
      setSelectedExtraPoint(newValue % 10);
    } else {
      setSelectedExtraPoint(newValue);
    }
  }, []);

  if (props.loading) {
    return <Skeleton paragraph={{ rows: 4 }} active />;
  }

  return (
    <S.Modal
      visible={props.visible}
      width={900}
      afterClose={props.resetParams}
      destroyOnClose
      onCancel={() => {
        props.setVisible(false);
        props.resetParams?.();
      }}
      title={
        <Row style={{ marginTop: 12 }} justify="center">
          <Col span={24}>
            <S.Title>Manage stuck student during Reflection</S.Title>
          </Col>
        </Row>
      }
      footer={
        <Row justify="center" style={{ marginTop: 8, marginBottom: 16 }}>
          <Col span={20}>
            <Row justify="center" gutter={[16, 16]}>
              <Col span={8}>
                <Button
                  onClick={props.handleDoNothing}
                  block
                  text="Do Nothing"
                  theme={themeConfig.noColor}
                  minHeight={40}
                  disabled={props.disableDoNothing || !!props?.reassignedStudent?.key}
                />
              </Col>
              <Col span={8}>
                <Button
                  onClick={props.handlePeerReviewByYourself}
                  block
                  text="Peer Review by Yourself"
                  theme={themeConfig.noColor}
                  minHeight={40}
                  disabled={props.disableDoNothing}
                />
              </Col>
              <Col span={8}>
                <Button
                  type="primary"
                  disabled={!selectedStudent || props.disableDoNothing}
                  onClick={() => props.handleConfirmReAssignment(selectedStudent ?? '', selectedExtraPoint)}
                  block
                  text="Confirm Re-Assignment"
                  minHeight={40}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <S.Wrapper>
        <S.SubTitle>Context</S.SubTitle>
        <S.Divider />
        <S.Info>
          When completing the <strong>Investigation {props.investigationName}</strong>, on{' '}
          <strong>Class {props.className}</strong> for the <strong>Activity {props.activityName}</strong> at{' '}
          <strong>Stage {props.stageName}</strong>, <strong>{props.studentName}</strong> got stuck.
        </S.Info>

        <S.SubTitle>What should have happened</S.SubTitle>
        <S.Divider />
        <S.Info>
          <strong>{props.studentName}</strong>, has no reflections available for his/her submission.{' '}
          {props.studentBlockersNameReadable ? (
            <>
              <strong>{props.studentBlockersNameReadable}</strong>, {props.studentBlockersAmount > 1 ? 'were' : 'was'}{' '}
              supposed to peer review his/her work, but {props.studentBlockersAmount > 1 ? 'have' : 'has'}n't yet
            </>
          ) : (
            'None of the students that were supposed to peer review his/her work have submitted their work yet.'
          )}
        </S.Info>

        <S.SubTitle>Possible Action Item</S.SubTitle>
        <S.Divider />
        <S.Info>
          Assign{' '}
          <Select
            style={{ width: 200, margin: '0 4px' }}
            allowClear
            placeholder="Select a student"
            defaultValue={selectedStudent}
            onChange={(value) => setSelectedStudent(value as string)}
            disabled={props.disableDoNothing}
          >
            {props?.reassignedStudent && (
              <SelectOption value={props?.reassignedStudent?.key} key={props?.reassignedStudent?.key}>
                {props?.reassignedStudent?.text}
              </SelectOption>
            )}
            {props?.students?.map((student) => (
              <SelectOption value={student.key} key={student.key}>
                {student.text}
              </SelectOption>
            ))}
          </Select>{' '}
          to peer review <strong>{props.studentName}</strong> work.
        </S.Info>
        <S.Info>
          This will give this student extra work to do, give them{' '}
          <Input
            min={0}
            max={9}
            value={selectedExtraPoint.toString()}
            onChange={handleExtraPointChange}
            backgroundColor="white"
            type="number"
            style={{ width: 60 }}
            disabled={props.disableDoNothing}
          />{' '}
          extra engagement points.
          <Tooltip title="This will add extra points to their engagement score. As a guideline: 8 extra points will add one extra star out of five">
            <FiHelpCircle style={{ marginLeft: 8, verticalAlign: 'middle' }} />
          </Tooltip>
        </S.Info>
      </S.Wrapper>
    </S.Modal>
  );
};

export default StuckStudentModal;
