import React, { useEffect, useMemo, useState } from 'react';
import { Col, message, Row, Spin } from 'antd';
import Button from '../../../shared/Button';
import { FiPlus } from 'react-icons/fi';
import CommentBox from '../../../shared/CommentBox';
import * as S from './styles';
import Editor from '../../../shared/Editor';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { GQL_Comment, GQL_SubmitCommentVariables } from '../../../types/investigation';
import { IStore } from '../../../redux/store';
import { useSelector } from 'react-redux';
import CommentActions from '../../../shared/CommentActions';
import { debounce } from 'lodash';
import { themeConfig } from '../../../utils/theme';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';

interface IInvestigationComment {
  updateHasWrittenComment: (hasWritten: boolean) => void;
  activityId?: string;
  defaultActiveComment?: string;
  selectedStudentId?: string;
  investigationId?: string;
  classId?: string;
  submissionVersion: number;
}

const InvestigationComment = (props: IInvestigationComment) => {
  const {
    updateHasWrittenComment,
    activityId,
    defaultActiveComment,
    investigationId,
    selectedStudentId,
    classId,
    submissionVersion,
  } = props;
  const [addingComment, setAddingComment] = useState(false);
  const [addingSubComment, setAddingSubComment] = useState(false);
  const [activeComment, setActiveComment] = useState('');
  const [comment, setComment] = useState('');
  const [subComment, setSubComment] = useState('');
  const { isStudent } = useAuth();
  const currentActivityId = useSelector((state: IStore) => state.investigation.currentActivityId) || activityId;

  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const student = investigationData?.getInvestigationProgressSummary?.perStudents?.find(
    (student) => student.userId === selectedStudentId,
  );

  const { data, loading: loadingComments } = useQuery<
    { getCommentByActivityId?: GQL_Comment[] },
    { activityId: string; classId: string }
  >(gqlSchema.InvestigationSchema.queries.COMMENT.getComments, {
    fetchPolicy: 'network-only',
    variables: {
      activityId: currentActivityId || '',
      classId: classId || '',
    },
    onError: (err) => {
      message.error('There was an error loading the comments: ' + err.message);
    },
    onCompleted: ({ getCommentByActivityId }) => {
      const comment = getCommentByActivityId?.find((c) =>
        c.responses?.some((subComment) => subComment.id === defaultActiveComment),
      );
      setActiveComment(comment?.id || '');
    },
  });

  useEffect(() => {
    if (data?.getCommentByActivityId) {
      const comments = data.getCommentByActivityId;

      // Checks if user has written either a comment or reponse to a comment
      const hasWrittenComment = comments.some(
        (comment) =>
          comment.author.toLowerCase() === 'me' ||
          comment.responses.some((commentResponse) => commentResponse.author.toLowerCase() === 'me'),
      );

      if (hasWrittenComment) updateHasWrittenComment(hasWrittenComment);
    }
  }, [data, updateHasWrittenComment]);

  const sortedComments = useMemo<GQL_Comment[]>(() => {
    if (!data) return [];

    // Since arrays are read-only on strict mode,
    // we need to create a copy of it before sorting, hence the slice before sort
    return (
      data.getCommentByActivityId
        ?.filter(
          (c: GQL_Comment) => c.activityId === currentActivityId && (student ? student?.group === c.group : true),
        )
        .slice()
        .sort((a, b) => b.createdAt - a.createdAt) || []
    );
  }, [data, currentActivityId, student]);

  const [submitComment, { loading: loadingSubmit }] = useMutation<
    { submitCommentByActivityId: GQL_Comment },
    { comment: GQL_SubmitCommentVariables }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitComment, {
    onCompleted: () => {
      setComment('');
      setAddingComment(false);
    },
    onError: (err) => {
      message.error('There was an error submitting the comment: ' + err.message);
    },
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          getCommentByActivityId(existingComments = []) {
            const newCommentRef = cache.writeFragment({
              data: data?.submitCommentByActivityId,
              fragment: gqlSchema.InvestigationSchema.fragments.COMMENTS.commentFragment,
            });

            return [...existingComments, newCommentRef];
          },
        },
      });
    },
  });

  const [submitSubComment, { loading: loadingSubComment }] = useMutation<
    { submitCommentByActivityId: GQL_Comment },
    { comment: GQL_SubmitCommentVariables }
  >(gqlSchema.InvestigationSchema.mutations.COMMENTS.submitComment, {
    onCompleted: () => {
      setSubComment('');
      setAddingSubComment(false);
    },
    onError: (err) => {
      message.error('There was an error submitting comment: ' + err.message || 'Unexpected Error');
    },
  });

  const cancelComment = () => {
    setAddingComment(false);
    setComment('');
  };

  const cancelSubComment = () => {
    setAddingSubComment(false);
    setSubComment('');
  };

  const handleActiveCommentChange = (id: string) => {
    cancelSubComment();
    setActiveComment(id);
  };

  const handleCommentSubmit = () => {
    if (currentActivityId) {
      submitComment({
        variables: {
          comment: {
            activityId: currentActivityId,
            classId: classId,
            text: comment,
            coordinates: {},
          },
        },
      });
    }
  };

  const handleSubCommentSubmit = () => {
    if (!addingSubComment) setAddingSubComment(true);
    else if (activeComment && currentActivityId) {
      submitSubComment({
        variables: {
          comment: {
            activityId: currentActivityId,
            text: subComment,
            classId: classId,
            commentParent: activeComment,
            coordinates: {},
          },
        },
      });
    }
  };

  const addCommentDebounced = debounce(setComment, 300);
  const addSubCommentDebounced = debounce(setSubComment, 300);

  return (
    <Col span={24}>
      <Row justify="center" align="middle" gutter={[0, 12]}>
        <Col>
          <Button
            text={addingComment ? 'Adding a comment...' : 'Add a Comment'}
            icon={<FiPlus size={20} />}
            disabled={addingComment || !isStudent}
            onClick={() => setAddingComment(!addingComment)}
          />
        </Col>
        {addingComment && (
          <CommentBox
            daya-cy="components-investigation-comment-comment-box"
            actions={[
              <S.CommentWarning>
                *Your teacher and colleagues will be able to see and rate your comment.
              </S.CommentWarning>,
            ]}
          >
            <Editor
              placeholder="Start a discussion or provide a meaningful comment to the current one"
              onChange={(v) => addCommentDebounced(v.isEditorEmpty ? '' : v.value)}
              data-cy="components-comment-section-editor-editable"
            />
            <S.ButtonsRow gutter={16}>
              <Col>
                <Button
                  text="Submit Comment"
                  onClick={handleCommentSubmit}
                  loading={loadingSubmit}
                  disabled={!comment}
                />
              </Col>
              <Col>
                <Button text="Cancel" theme={themeConfig.noColor} onClick={cancelComment} disabled={loadingSubmit} />
              </Col>
            </S.ButtonsRow>
          </CommentBox>
        )}
        {loadingComments && (
          <S.SpinningContainer>
            <Spin />
          </S.SpinningContainer>
        )}
        {sortedComments.map((comment) => (
          <S.Comment
            author={comment.author}
            avatar={comment.avatar}
            highlightReport={!isStudent && !!comment.flags?.count}
            actions={[
              <CommentActions
                ownComment={comment.author === 'Me'}
                key="action"
                setActiveComment={handleActiveCommentChange}
                commentSelected={activeComment === comment.id}
                commentId={comment.id}
                comments={comment.responses.length}
                likes={comment.likes}
                flags={comment.flags}
                submissionVersion={submissionVersion}
              />,
            ]}
            key={comment.id}
          >
            <Editor editable={false} value={comment.text} data-cy="components-comment-section-editor-not-editable" />
            {activeComment === comment.id && (
              <div>
                <S.Divider />
                {comment.responses.map((subComment) => (
                  <S.SubCommentContainer key={subComment.id}>
                    <CommentBox
                      author={subComment.author}
                      avatar={subComment.avatar}
                      highlightReport={!isStudent && !!subComment.flags?.count}
                      actions={[
                        <CommentActions
                          key="action"
                          commentId={subComment.id}
                          showComment={false}
                          flags={subComment.flags}
                          likes={subComment.likes}
                          submissionVersion={submissionVersion}
                        />,
                      ]}
                    >
                      <Editor editable={false} value={subComment.text} />
                    </CommentBox>
                  </S.SubCommentContainer>
                ))}
                {addingSubComment && (
                  <Editor
                    placeholder="Write Your Comment Here..."
                    onChange={(v) => addSubCommentDebounced(v.isEditorEmpty ? '' : v.value)}
                    data-cy="components-comment-section-subcomment-editor-editable"
                  />
                )}
                <S.ButtonsRow gutter={24}>
                  <Col>
                    <Button
                      text={addingSubComment ? 'Post Comment' : 'Add Comment'}
                      icon={!addingSubComment && <FiPlus size={20} />}
                      onClick={handleSubCommentSubmit}
                      loading={loadingSubComment}
                      disabled={!isStudent || (addingSubComment && !subComment)}
                    />
                  </Col>
                  {addingSubComment && (
                    <Button
                      text="Cancel"
                      theme={themeConfig.noColor}
                      onClick={cancelSubComment}
                      disabled={loadingSubmit}
                    />
                  )}
                </S.ButtonsRow>
              </div>
            )}
          </S.Comment>
        ))}
      </Row>
    </Col>
  );
};

export default InvestigationComment;
