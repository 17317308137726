import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { Col, Layout, Row, Pagination, message, Checkbox, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { IStore } from '../../../redux/store';
import { investigationActions } from '../../../redux/modules';
import InvestigationContent from '../../../shared/InvestigationContent';
import { GQL_InvestigationActivity, GQL_InvestigationDisplay } from '../../../types/investigation';
import InvestigationLoading from '../InvestigationLoading';
import * as S from './styles';
import Button from '../../../shared/Button';
import { themeConfig } from '../../../utils/theme';
import InvestigationFileDisplay from '../../../shared/InvestigationFileDisplay';
import StudentInvestigationSider from '../StudentInvestigationSider';

type Props = RouteComponentProps<
  { investigationId: string },
  any,
  { file: File; stepId: string; activityId: string; contentIndex?: number; report?: boolean }
>;

const InvestigationFilePreview: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;
  const { file, stepId, activityId, contentIndex, report } = props.location.state || {};
  const history = useHistory();
  const [loadingState, setLoadingState] = useState<'loading' | 'error' | 'success'>('loading');
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [investigation, setInvestigation] = useState<GQL_InvestigationDisplay | null>(null);
  const dispatch = useDispatch();
  const currentActivityId = useSelector((state: IStore) => state.investigation.currentActivityId);
  const shouldShowPaginatorNums = numPages > 1;

  if (!file) history.goBack();

  const [getInvestigationById, { loading }] = useLazyQuery(
    gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById,
    {
      onCompleted: ({ getInvestigationById }: { getInvestigationById: GQL_InvestigationDisplay }) => {
        if (!currentActivityId) {
          const undoneActivityId = getInvestigationById.steps
            .find((step) => !step.completed)
            ?.activities?.find((activity) => !activity.completed)?.id;

          if (undoneActivityId) {
            dispatch(
              investigationActions.updateCurrentActivity({
                id: undoneActivityId,
              }),
            );
          }
        }
        setInvestigation(getInvestigationById);
      },
      onError: (error: ApolloError) => {
        console.log('error', error);
      },
    },
  );

  const goToInvestigation = useCallback(
    ({ keepContentIndex }: { keepContentIndex: boolean }) => {
      history.push(`/student-investigation/${investigation?.id}`, {
        contentIndex: keepContentIndex ? contentIndex : 0,
      });
    },
    [history, investigation, contentIndex],
  );

  const [submitFile, { loading: loadingFile }] = useMutation<
    { submitInvestigationPlanFile: GQL_InvestigationActivity },
    { file: File; stepId: string; activityId: string }
  >(gqlSchema.InvestigationSchema.mutations.PLAN.submitFile, {
    onCompleted: () => {
      goToInvestigation({ keepContentIndex: false });
    },
    onError: (err) => {
      message.error('There was an error uploading your file: ' + err.message);
    },
    update(cache, { data }) {
      setConfirmationModalVisible(false);
      const activity = data?.submitInvestigationPlanFile;
      if (activity) {
        cache.modify({
          id: activity.id,
          fields: {
            content() {
              cache.writeFragment({
                fragment: gqlSchema.InvestigationSchema.fragments.CONTENT.contentFragment,
                data: {
                  ...activity.content,
                },
              });
            },
          },
        });
      }
    },
  });

  const [submitFinalReport, { loading: loadingFinalReport }] = useMutation<
    { submitFinalReportFile: GQL_InvestigationDisplay },
    { investigationid: string; file: File }
  >(gqlSchema.InvestigationSchema.mutations.PLAN.submitFinalReportFile, {
    onCompleted: () => {
      goToInvestigation({ keepContentIndex: false });
    },
    onError: (err) => {
      message.error('There was an error uploading your file: ' + err.message);
    },
    update: () => {
      setConfirmationModalVisible(false);
    },
  });

  const onSubmit = useCallback(() => {
    if (report) {
      submitFinalReport({
        variables: {
          file,
          investigationid: investigationId,
        },
      });
    } else {
      submitFile({
        variables: { file, stepId, activityId },
      });
    }
  }, [file, submitFile, stepId, activityId, submitFinalReport, investigationId, report]);

  const onLoadSuccess = (pdf: any) => {
    setLoadingState('success');
    setNumPages(pdf.numPages);
  };

  const handleProceedModal = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  useEffect(() => {
    if (investigationId) {
      getInvestigationById({
        variables: {
          id: investigationId,
        },
      });
    }
  }, [investigationId, getInvestigationById]);

  if (loading) return <InvestigationLoading />;

  return (
    investigation && (
      <Layout>
        <InvestigationFilePreviewConfirmationModal
          isSubmitting={loadingFile || loadingFinalReport}
          visible={confirmationModalVisible}
          onClose={() => setConfirmationModalVisible(false)}
          onProceed={handleProceedModal}
        />
        <StudentInvestigationSider investigation={investigation} resetContentIndex={() => {}} disabled />
        <InvestigationContent>
          <Row justify="center">
            <Col span={16} style={{ textAlign: 'center' }}>
              <h1>Preview Your Submission</h1>
            </Col>
            <Col span={16} style={{ textAlign: 'center' }}>
              <S.Info>If you are sure that you uploaded the correct file then press the submit button.</S.Info>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center" style={{ marginTop: 20 }}>
            <Col span={16}>
              {shouldShowPaginatorNums && (
                <S.BarRow align="middle" justify="center" style={{ width: '100%' }}>
                  <Pagination
                    size="small"
                    total={numPages}
                    pageSize={1}
                    current={pageNumber}
                    onChange={setPageNumber}
                  />
                </S.BarRow>
              )}
              <InvestigationFileDisplay
                file={file}
                mimeType={file.type}
                onLoadSuccess={onLoadSuccess}
                onLoadError={() => setLoadingState('error')}
                page={pageNumber}
              />
            </Col>
          </Row>
          <Row justify="center" gutter={16} style={{ marginTop: 20 }}>
            <Col span={4}>
              <Button
                onClick={() => goToInvestigation({ keepContentIndex: true })}
                text={'Go Back'}
                loading={loadingFile || loadingFinalReport}
                theme={themeConfig.primaryOutlined}
                block
              />
            </Col>
            <Col span={4}>
              <Button
                data-cy="components-studentinvestigation-filepreview-submit-button"
                onClick={() => setConfirmationModalVisible(true)}
                text={'Submit File'}
                loading={loadingFile || loadingFinalReport || loadingState === 'loading'}
                disabled={loadingState === 'error'}
                block
              />
            </Col>
          </Row>
        </InvestigationContent>
      </Layout>
    )
  );
};

const InvestigationFilePreviewConfirmationModal = ({
  visible,
  onClose,
  onProceed,
  isSubmitting,
}: {
  visible: boolean;
  onClose: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  onProceed: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  isSubmitting: boolean;
}) => {
  const [checkBoxes, setCheckBoxes] = useState({ first: false, second: false, third: false });
  const confirmDisabled = Object.values(checkBoxes).some((checked) => !checked);

  return (
    <S.Modal
      centered
      visible={visible}
      width={600}
      destroyOnClose
      onCancel={onClose}
      title={
        <Row style={{ marginTop: 12 }} justify="center">
          <Col span={24}>
            <S.ModalTitle>File Submission Confirmation</S.ModalTitle>
          </Col>
        </Row>
      }
      footer={
        <Row justify="center" style={{ marginTop: 8, marginBottom: 16 }}>
          <Col span={12}>
            <Row justify="center" gutter={[16, 16]}>
              <Col span={12}>
                <Button onClick={onClose} theme={themeConfig.noColor} block text="Cancel" minHeight={40} />
              </Col>
              <Col span={12}>
                <Button
                  onClick={onProceed}
                  loading={isSubmitting}
                  disabled={confirmDisabled}
                  block
                  text="Confirm"
                  minHeight={40}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <S.ModalWrapper>
        <S.ModalInfo>Please confirm the following before you submit your file:</S.ModalInfo>

        <Space direction="vertical" size="middle">
          <Checkbox
            data-cy="components-studentinvestigation-filepreview-checkbox-0"
            onChange={(value) => setCheckBoxes({ ...checkBoxes, first: value.target.checked })}
          >
            Did you upload the file you wanted to upload?
          </Checkbox>
          <Checkbox
            data-cy="components-studentinvestigation-filepreview-checkbox-1"
            onChange={(value) => setCheckBoxes({ ...checkBoxes, second: value.target.checked })}
          >
            Does the file include everything you want it to include?
          </Checkbox>
          <Checkbox
            data-cy="components-studentinvestigation-filepreview-checkbox-2"
            onChange={(value) => setCheckBoxes({ ...checkBoxes, third: value.target.checked })}
          >
            Is this file the one you want to be peer reviewed?
          </Checkbox>
        </Space>
      </S.ModalWrapper>
    </S.Modal>
  );
};

export default withRouter(InvestigationFilePreview);
