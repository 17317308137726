import { useMutation } from '@apollo/client';
import { Dropdown, Menu, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import AnnouncementModal from '../../components/ClassDashboard/AnnouncementModal';
import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import { GQL_CreateAnnouncementResponse } from '../../types/class';
import { formatDateTime } from '../../utils/date';
import { sliceRichText } from '../../utils/string';
import Avatar from '../Avatar';
import Spacer from '../Spacer';

import * as S from './styles';

interface IAnnouncementCard {
  text: string;
  id: string;
  creation: number;
  teacherName: string;
  teacherAvatar?: string;
  className: string;
  readMoreChars?: number;
}

const AnnouncementCard = (props: IAnnouncementCard) => {
  const { text, id, teacherName, teacherAvatar, className, creation, readMoreChars = 300 } = props;
  const [announcementText, setAnnouncementText] = useState<string | undefined>(text);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);
  const { isTeacherOrFacilitator } = useAuth();

  const [deleteAnnouncement] = useMutation<{ deleteAnnouncement: boolean }, { id: string }>(
    gqlSchema.ClassSchema.mutation.CLASS.ANNOUNCEMENT.deleteAnnouncement,
    {
      onCompleted: () => {
        message.success('Announcement Deleted');
      },
      onError: (err) => {
        message.error(err.message || 'Unexpected error deleting announcement, try again later');
      },
      update: (cache) => {
        cache.modify({
          fields: {
            getClassAnnouncements: (existingAnnouncements: any[], { readField }) => {
              return existingAnnouncements.filter((ref) => readField('id', ref) !== id);
            },
          },
        });
      },
    },
  );

  const [updateAnnouncement, { loading: loadingEdit }] = useMutation<
    { editAnnouncement: GQL_CreateAnnouncementResponse },
    { data: { id: string; text: string } }
  >(gqlSchema.ClassSchema.mutation.CLASS.ANNOUNCEMENT.editAnnouncement, {
    onCompleted: () => {
      message.success('Announcement updated successfully');
      setEditModalVisible(false);
    },
    onError: (err) => message.error(err.message || 'Error updating announcement, please try again later'),
  });
  const handleUpdateAnnouncement = () => {
    if (announcementText) {
      updateAnnouncement({
        variables: {
          data: {
            id,
            text: announcementText,
          },
        },
      });
    }
  };

  const handleEditModalClose = () => {
    setEditModalVisible(false);
  };

  const handleDeleteAnnouncement = useCallback(() => {
    deleteAnnouncement({
      variables: {
        id,
      },
    });
  }, [deleteAnnouncement, id]);

  const menuOverlay = useCallback(
    () => (
      <Menu>
        <Menu.Item onClick={() => setEditModalVisible(true)} data-cy="components-announcement-card-edit">
          Edit Announcement
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={handleDeleteAnnouncement} data-cy="components-announcement-card-delete">
          Delete Announcement
        </Menu.Item>
      </Menu>
    ),
    [handleDeleteAnnouncement],
  );

  const needsReadMore = text.replace(/<.*?>/g, '').length > readMoreChars;
  const cutOutText = needsReadMore ? sliceRichText(text, readMoreChars) : text;

  const invertIsReadingMore = () => {
    setIsReadingMore(!isReadingMore);
  };

  return (
    <>
      <S.CardContainer>
        <S.CardHeader>
          <Avatar margin="5px" size={45} backgroundColor="#FFFFFF" src={teacherAvatar} />
          <span role="none" >
            <h1>
              {teacherName} - {className}
            </h1>
            <h2>Posted on {formatDateTime(creation, "HH:mm aa',' MM.dd.yyyy")}</h2>
          </span>
        </S.CardHeader>
        <Spacer />
        <div dangerouslySetInnerHTML={{ __html: isReadingMore ? text : cutOutText }} />
        {needsReadMore && (
          <S.ReadMoreButton onClick={invertIsReadingMore}>{isReadingMore ? 'Read Less' : 'Read More'}</S.ReadMoreButton>
        )}
        {isTeacherOrFacilitator && (
          <Dropdown overlay={menuOverlay()} placement="bottomRight" trigger={['click']}>
            <S.MoreButton
              icon={<FiMoreVertical size={24} />}
              minHeight={24}
              shape="circle"
              data-cy="components-announcement-card-more-button"
            />
          </Dropdown>
        )}
      </S.CardContainer>
      <AnnouncementModal
        loading={loadingEdit}
        onClose={handleEditModalClose}
        visible={editModalVisible}
        onCreate={handleUpdateAnnouncement}
        onTextChange={setAnnouncementText}
        text={announcementText}
      />
    </>
  );
};

export default AnnouncementCard;
