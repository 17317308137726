import React, { useCallback, useState } from 'react';
import { Row, Col, Progress, Spin, Badge } from 'antd';
import { getProgressColor, themeConfig } from '../../../../utils/theme';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { gqlSchema } from '../../../../gql/schema';
import { useQuery } from '@apollo/client';

interface Props {
  progress?: number | null;
  date: string;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  courseWorkId: string;
  className: React.ReactNode;
  courseName: string;
  icon: React.ReactNode;
  workType: string; // Added workType prop
}

const Item: React.FC<Props> = (props) => {
  const { assignmentName, assignmentId, className, courseId, icon, workType } = props;
  const history = useHistory();
  const [progress, setProgress] = useState<number>(0);

  const { loading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudentsTurnedIn,
    {
      variables: { expiredCourseWork: [{ id: assignmentId, courseId }] },
      onError: (error) => {
        console.error('Error fetching students turned in data:', error);
      },
      onCompleted: (data) => {
        const turnedIn = data.getCourseStudentsTurnedIn.find((item: any) => item.id === assignmentId);
        if (turnedIn) {
          setProgress(turnedIn.complete);
        }
      },
    }
  );

  const goToDashboard = useCallback(() => {
    history.push(`/googleclassroom-teacher-dashboard/class/${courseId}/assignment-summary/${assignmentId}`);
  }, [assignmentId, history, courseId]);

  // Function to capitalize only the first letter of a string
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <S.Card>
      <Row align="middle">
        <Col span={2}>
          <S.IconWrapper>{icon}</S.IconWrapper>
        </Col>
        <Col span={18}>
          <S.ClassCourseInfo>
            <S.Info style={{ fontSize: '1.2em', marginLeft: '16px', textAlign: 'left', fontWeight: 500 }}>
              {className}
            </S.Info>
          </S.ClassCourseInfo>
        </Col>
        <Col span={4}>
        <Badge 
            count={capitalizeFirstLetter(workType)} 
            style={{ backgroundColor: '#E9A643', borderRadius: '16px', color: '#fff', fontWeight: 500, fontSize: '12px' }} 
          />
        </Col>
      </Row>
      {/* Place assignmentName below the class icon */}
      <Row style={{ marginTop: '1em' }}>
        <Col span={24}>
        <S.AssignmentName>
            <span style={{
              fontWeight: 500, // Bold for title appearance
              fontSize: '14px', // Slightly larger font size for title
              color: '#333', // Dark color for readability
              margin: '0', // No extra margin
              display: 'block', // Display as block for full width
              textAlign: 'left', // Align text to the left
              textTransform: 'capitalize',  // Capitalize first letter of each word
            }}>
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="middle">
          {loading ? (
            <Spin />
          ) : (
            <Progress
              strokeLinecap="round"
              strokeWidth={9}
              style={{ marginTop: 30 }}
              strokeColor={getProgressColor(progress || 0)}
              format={(percent) => (
                <>
                  <S.Info $bold>{percent !== undefined ? Math.round(percent) : 0}%</S.Info>
                   <S.InfoProgress>Total Submissions</S.InfoProgress>
                </>
              )}
              width={145}
              type="circle"
              percent={progress || 0}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col span={14} offset={5}>
          <S.SummarydButton
            text="View Summary"
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={goToDashboard}
            block
          />
        </Col>
      </Row>
    </S.Card>
  );
};

export default Item;
