import { useMutation } from '@apollo/client';
import { Col, Row, Skeleton, Divider, message } from 'antd';
import React, { useCallback, useState, useMemo } from 'react';
import { BiCheck, BiEdit } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import ModalConfirm from '../../../shared/ModalConfirm';
import RangePicker from '../../../shared/RangePicker';
import { GQL_ClassResponse, GQL_EditClassInput, GQL_RemoveClassInput } from '../../../types/class';
import { formatDateTime, toDateFormat } from '../../../utils/date';
import { themeConfig } from '../../../utils/theme';
import * as S from './styles';

interface IClassInfos {
  classInfo?: GQL_ClassResponse;
  loading: boolean;
}

// eslint-disable-next-line complexity
const ClassInfos = (props: IClassInfos) => {
  const { classInfo } = props;
  const [editingName, setEditingName] = useState(false);
  const [className, setClassName] = useState<string>();
  const history = useHistory();
  const [editingDuration, setEditingDuration] = useState(false);
  const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const { isTeacherOrFacilitator, isFacilitator, isSubscriptionUser } = useAuth();

  const totalInvestigation = classInfo?.investigations.filter((each) => !each.isAssessment);
  const totalAssessment = classInfo?.investigations.filter((each) => each.isAssessment);

  const [duration, setDuration] = useState<{
    startDate: string;
    endDate: string;
  }>();

  const [editClass] = useMutation<{ editClass: GQL_ClassResponse }, { data: GQL_EditClassInput }>(
    gqlSchema.ClassSchema.mutation.CLASS.EDIT.editClass,
    {
      onCompleted: () => message.success(`${classInfo?.name} class updated successfully`),
      onError: () => message.error('There was an error trying to edit the class, please try again later'),
    },
  );

  const [removeClass, { loading: loadingRemoveClass }] = useMutation<
    { removeClass: boolean },
    { data: GQL_RemoveClassInput }
  >(gqlSchema.ClassSchema.mutation.CLASS.DELETE.removeClass, {
    onCompleted: (data: { removeClass: boolean }) => {
      if (data.removeClass) {
        history.push('/teacher-dashboard');
        message.success('Class deleted successfully');
      } else {
        message.error('There was an error trying to delete the class, please try again later');
      }
    },
    onError: (err) => {
      message.error('There was an error trying to delete the class, please try again later');
    },
    update(cache, { data }) {
      if (data?.removeClass) {
        cache.modify({
          fields: {
            getInvestigationsByTeacher(existingInvestigations = [], { readField }) {
              return existingInvestigations.filter(
                (classInvestigationItem: any) => readField('classId', classInvestigationItem) !== classInfo?.id,
              );
            },
          },
          broadcast: true,
        });

        cache.modify({
          fields: {
            getClasses(existingClasses = [], { readField }) {
              return existingClasses.filter((classItem: any) => readField('id', classItem) !== classInfo?.id);
            },
          },
          broadcast: true,
        });
      }
    },
  });

  const onRemoveClass = useCallback(() => {
    if (classInfo) {
      removeClass({ variables: { data: { id: classInfo.id } } });
    }
  }, [classInfo, removeClass]);

  const handleNameEditingButton = () => {
    if (editingName) {
      if (className === classInfo?.name || !className) {
        setClassName(classInfo?.name);
      } else if (classInfo) {
        editClass({
          variables: {
            data: {
              id: classInfo.id,
              name: className,
              numberOfGroups: classInfo.numberOfGroups,
              startDate: new Date(classInfo.startDate).getTime(),
              endDate: new Date(classInfo.endDate).getTime(),
            },
          },
        });
      }
    }
    setEditingName(!editingName);
  };

  const handleDurationEditingButton = () => {
    if (classInfo && editingDuration && duration) {
      editClass({
        variables: {
          data: {
            id: classInfo.id,
            name: classInfo.name,
            numberOfGroups: classInfo.numberOfGroups,
            startDate: new Date(toDateFormat(duration.startDate) || classInfo.startDate).getTime(),
            endDate: new Date(toDateFormat(duration.endDate) || classInfo.endDate).getTime(),
          },
        },
      });
    }
    setEditingDuration(!editingDuration);
  };

  const classFinished = classInfo?.endDate ? new Date(classInfo?.endDate || '').getTime() > Date.now() : undefined;
  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);
  return (
    <S.ClassInfosContainer>
      <Row>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Row align="middle">
            <Col span={18}>
              <S.Title>{classTitle} Name</S.Title>
              {!classInfo ? (
                <Skeleton.Input active size="small" />
              ) : (
                <S.SInput
                  disabled={!editingName}
                  bordered={editingName}
                  defaultValue={classInfo.name}
                  value={className}
                  onPressEnter={handleNameEditingButton}
                  onChange={(e) => setClassName(e.target.value)}
                />
              )}
            </Col>
            <Col span={6} style={{ textAlign: 'center' }}>
              {isTeacherOrFacilitator && (
                <S.EditButton
                  type="primary"
                  shape="circle"
                  minHeight={24}
                  icon={editingName ? <BiCheck /> : <BiEdit />}
                  onClick={handleNameEditingButton}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Row align="middle">
            <Col span={16}>
              <S.Title>{classTitle} Duration</S.Title>
              {editingDuration ? (
                <RangePicker
                  value={duration}
                  onChange={setDuration}
                  allowClear={false}
                  defaultValue={{
                    startDate: formatDateTime(classInfo?.startDate),
                    endDate: formatDateTime(classInfo?.endDate),
                  }}
                />
              ) : (
                <S.SubText>
                  {duration?.startDate || formatDateTime(classInfo?.startDate)} -{' '}
                  {duration?.endDate || formatDateTime(classInfo?.endDate)}
                </S.SubText>
              )}
            </Col>
            <Col span={6} offset={2}>
              {isTeacherOrFacilitator && (
                <S.EditButton
                  type="primary"
                  shape="circle"
                  minHeight={24}
                  icon={editingDuration ? <BiCheck /> : <BiEdit />}
                  onClick={handleDurationEditingButton}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Col>
            <S.Title>{classTitle} Students</S.Title>
          </Col>
          <Col>
            {!classInfo ? (
              <Skeleton.Input active size="small" />
            ) : (
              <S.SubText>{classInfo?.numberOfStudents} Enrolled Students</S.SubText>
            )}
          </Col>
        </Col>
        <Divider />
        {isTeacherOrFacilitator && (
          <S.InsightsButton
            theme={themeConfig.primaryColor}
            onClick={() =>
              history.push('/teacher-insights/investigations', {
                classId: classInfo?.id,
              })
            }
            text={`Insights`}
          />
        )}
      </Row>
      <Row>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Col>
            <S.Title>{classTitle} Investigations/Assessments</S.Title>
          </Col>
          <Col>
            {!classInfo ? (
              <Skeleton.Input active size="small" />
            ) : (
              <>
                <S.SubText>
                  {totalInvestigation?.length}
                  {totalInvestigation?.length === 1 ? ' Investigation' : ' Investigations'}
                  {' / '}
                  {totalAssessment?.length}
                  {totalAssessment?.length === 1 ? ' Assessment' : ' Assessments'}
                </S.SubText>
              </>
            )}
          </Col>
        </Col>
        <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
          <Col>
            <S.Title>{classTitle} Owner</S.Title>
          </Col>
          <Col>
            <S.SubText>
              {classInfo?.teacherName ? `${classInfo?.teacherName} - ${classInfo?.teacherEmail}` : '-'}
            </S.SubText>
          </Col>
        </Col>
        <Col xxl={5} xl={5} lg={12} md={20} sm={20} xs={20}>
          <Col>
            <S.Title>{classTitle} Status</S.Title>
          </Col>
          <Col>
            <S.StatusText finished={classFinished}>
              {classFinished !== undefined && (classFinished ? 'Ongoing' : 'Finished')}
            </S.StatusText>
          </Col>
        </Col>
        <Col xxl={3} xl={3} lg={4} md={4} sm={4} xs={4} style={{ display: 'grid' }} flex="1">
          {isTeacherOrFacilitator && (
            <>
              <S.DeleteButton
                data-cy="components-classdashboard-classinfos-delete-button"
                disabled={isSubscriptionUser}
                theme={themeConfig.error}
                onClick={() => setDeleteConfirmVisible(true)}
                text={`Delete ${classTitle}`}
                disabledReason={
                  isSubscriptionUser ? <>You can only delete a class when you restart your subscription.</> : <></>
                }
              />
            </>
          )}
        </Col>
      </Row>
      <ModalConfirm
        visible={deleteConfirmVisible}
        setVisible={setDeleteConfirmVisible}
        title={`You are deleting ${classInfo?.name}`}
        lineInfo1={`Are you sure you want to delete ${classInfo?.name}? All student progress and grades`}
        lineInfo2={`will be permanently deleted. Student accounts will not be deleted`}
        deleteButtonTitle="Delete Class"
        nameToCheck={classInfo?.name || ''}
        loading={loadingRemoveClass}
        deleteFn={onRemoveClass}
        errorMessage="The class name is not the same"
      />
    </S.ClassInfosContainer>
  );
};

export default ClassInfos;
