import { message, Modal, Pagination, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { FiCheck, FiDownloadCloud, FiEye, FiEyeOff, FiTrash2 } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import Button from '../../../../shared/Button';
import Upload from '../../../../shared/Upload';
import * as S from './styles';
import Carousel from '../../../../shared/Carousel';
import driveStepOneSrc from '../../../../assets/google-drive-steps/google-drive-step-1.png';
import driveStepTwoSrc from '../../../../assets/google-drive-steps/google-drive-step-2.png';
import driveStepThreeSrc from '../../../../assets/google-drive-steps/google-drive-step-3.png';
import { GQL_investigationTeacherSummary_PerStudent } from '../../../../types/teacher';
import InvestigationFileDisplay from '../../../../shared/InvestigationFileDisplay';
import Spacer from '../../../../shared/Spacer';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_InvestigationDisplay } from '../../../../types/investigation';
import { useAuth } from '../../../../hooks/useAuth';

interface ISubmitFile {
  type?: string;
  stepId: string;
  activityId: string;
  fileUrl?: string;
  investigationId?: string;
  currentContentIndex?: number;
  student?: GQL_investigationTeacherSummary_PerStudent;
  mimeType?: string;
  isReport?: boolean;
  hidden?: boolean;
}

const SubmitFile: React.FC<ISubmitFile> = (props) => {
  const {
    activityId,
    stepId,
    fileUrl,
    investigationId,
    currentContentIndex,
    student,
    mimeType,
    isReport,
    hidden,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { isTeacherOrFacilitator } = useAuth();
  const history = useHistory();
  const hasFile = fileUrl && fileUrl !== '/';
  const studentName = student ? ` by ${student.firstName} ${student.lastName}` : '';

  const [changeSubmissionVisibility] = useMutation<
    { changeVisibilityOfStudentSubmission: boolean },
    { stepId: string; studentId: string; activityId: string; hidden: boolean }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.changeVisibilityOfStudentSubmission, {
    onError: (err) => message.error(err.message),
    update: (cache, { data }) => {
      const cachedInvestigation = cache.readQuery<{ getInvestigationByIdForTeacher: GQL_InvestigationDisplay }>({
        query: gqlSchema.InvestigationSchema.queries.CORE.getInvestigationByIdForTeacher,
        variables: {
          id: investigationId,
        },
      });
      if (!cachedInvestigation?.getInvestigationByIdForTeacher) return;

      const cachedActivity = cachedInvestigation.getInvestigationByIdForTeacher.steps
        .find((s) => s.id === stepId)
        ?.activities?.find((a) => a.id === activityId);
      const newContent = cachedActivity?.content?.map((c) => {
        if (!c.blocks.some((b) => b.type === 'Upload' || 'UploadFinalReport')) return c;

        return {
          ...c,
          blocks: c.blocks.map((b) => {
            if (b.userId !== student?.userId) return b;

            return {
              ...b,
              values: [
                {
                  ...b.values[0],
                  hidden: data?.changeVisibilityOfStudentSubmission,
                },
              ],
            };
          }),
        };
      });

      cache.writeFragment({
        id: 'InvestigationActivityTeacherEntry:' + activityId,
        fragment: gqlSchema.InvestigationSchema.fragments.ACTIVITIES.teacherActivityEntry,
        fragmentName: 'activityTeacherEntry',
        broadcast: false,
        data: {
          id: cachedActivity?.id,
          content: newContent,
          __typename: 'InvestigationActivityTeacherEntry',
        },
      });
    },
  });

  const [removeStudentFinalSubmission] = useMutation<
    { removeStudentFinalSubmission: boolean },
    { stepId: string; studentId: string; activityId: string; investigationId: string }
  >(gqlSchema.InvestigationSchema.mutations.PROGRESS.removeStudentFinalSubmission, {
    onError: (err) => message.error(err.message),
  });

  const handleVisibilityChange = () => {
    if (student) {
      changeSubmissionVisibility({
        variables: {
          activityId,
          stepId,
          studentId: student.userId,
          hidden: !hidden,
        },
        optimisticResponse: {
          changeVisibilityOfStudentSubmission: !hidden,
        },
      });
    }
  };

  const handleFinalSubmissionDelete = () => {
    if (isReport && student && investigationId) {
      removeStudentFinalSubmission({
        variables: {
          investigationId,
          studentId: student.userId,
          activityId,
          stepId,
        },
      });
    }
  };

  const onLoadSuccess = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  const buildFile = () => {
    if (hasFile && fileUrl) {
      return (
        <>
          {numPages ? (
            <>
              <Spacer size={8} />
              <Row align="middle" justify="center" style={{ width: '100%' }}>
                <Pagination
                  size="small"
                  total={numPages}
                  pageSize={1}
                  current={currentPage}
                  onChange={setCurrentPage}
                />
              </Row>
            </>
          ) : (
            <></>
          )}
          <InvestigationFileDisplay
            file={fileUrl}
            mimeType={mimeType || ''}
            onLoadSuccess={onLoadSuccess}
            page={currentPage}
          />
        </>
      );
    } else if (!hidden) {
      return (
        <S.UploadContainer>
          <Upload
            title="Drag and drop your pdf or jpg submission here..."
            onLoad={setFile}
            accept="image/*, .pdf"
            onRemoveFile={() => {
              setFile(undefined);
            }}
          />
        </S.UploadContainer>
      );
    } else {
      return (
        <>
          <Spacer size={48} />
          <p>This submission was hidden by your teacher and will be reviewed</p>
          <Spacer size={48} />
        </>
      );
    }
  };

  return (
    <>
      <S.Container>
        <S.Title>
          <div>
            {hasFile ? `File Submitted${studentName}` : `Submit Your ${props.type || 'File'}`}
            {hasFile &&
              (student ? (
                <>
                  <Spacer axis="horizontal" size={32} />
                  <a href={fileUrl} download>
                    <FiDownloadCloud />
                  </a>
                </>
              ) : (
                <S.IconStatusContainer completed>{<FiCheck size={16} />}</S.IconStatusContainer>
              ))}
          </div>
          <div>
            {isReport && isTeacherOrFacilitator && (
              <>
                <Tooltip title="Delete this submission to allow student to resubmit his final report? This action is irreversible!">
                  <FiTrash2 onClick={handleFinalSubmissionDelete} />
                </Tooltip>
              </>
            )}
            {hasFile && student && (
              <>
                <Spacer axis="horizontal" size={32} />
                <Tooltip
                  title={
                    hidden ? 'Unhide this submission' : 'Hide this submission and remove peer reviews and reflections'
                  }
                >
                  {hidden ? <FiEye onClick={handleVisibilityChange} /> : <FiEyeOff onClick={handleVisibilityChange} />}
                </Tooltip>
              </>
            )}
          </div>
        </S.Title>
        {buildFile()}
        {!hasFile && (
          <Row justify="end">
            <Button
              data-cy="components-studentinvestigation-investigationupload-preview-button"
              style={{ marginLeft: 5 }}
              text={'Preview to Proceed'}
              onClick={() =>
                history.push(`/student-investigation/${investigationId}/preview-file`, {
                  file,
                  stepId,
                  activityId,
                  contentIndex: currentContentIndex,
                  report: isReport,
                })
              }
              disabled={!file || !!hasFile}
            />
          </Row>
        )}
      </S.Container>
      <Modal visible={modalOpen} zIndex={1100} onCancel={() => setModalOpen(false)} footer={null} width="80%">
        <Carousel width={24}>
          <S.DriveStepsContainer>
            <img src={driveStepOneSrc} alt="Google Docs document of a generic plan" />
            <p>Open the template and fill it with your plan information</p>
          </S.DriveStepsContainer>
          <S.DriveStepsContainer>
            <img
              src={driveStepTwoSrc}
              alt="Google Docs with menu File open, then clicking on Download and PDF Document"
            />
            <p>After you have successfully completed the template with your information, save it as a PDF</p>
          </S.DriveStepsContainer>
          <S.DriveStepsContainer>
            <img src={driveStepThreeSrc} alt="Showing a file being selected to upload on the system" />
            <p>After you have saved the template as a PDF, upload it to the Submission field and submit your plan</p>
          </S.DriveStepsContainer>
        </Carousel>
      </Modal>
    </>
  );
};

export default SubmitFile;
