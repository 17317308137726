import { gql } from '@apollo/client';
import TAGS_FRAGMENTS from '../../Tags/fragments/Tags';

export const CLASS = {
  CLASSES: {
    getClasses: gql`
      query GetClasses($role: String) {
        getClasses(role: $role) {
          id
          name
          startDate
          endDate
          colorHex
          status
          numberOfGroups
          numberOfStudents
          teacherName
          avatar
          type
          investigations {
            id
            title
            investigationId
            isAssessment
          }
        }
      }
    `,
    getClassesSubjects: gql`
      query getClassesSubjects {
        getClassesSubjects {
          name
          teacherAllowedSubjects
          isEnterpriseTeacher
        }
      }
    `,
    getClass: gql`
      query GetClass($data: QueryClassInput!) {
        getClass(data: $data) {
          id
          name
          startDate
          endDate
          status
          source
          colorHex
          numberOfGroups
          numberOfStudents
          teacherName
          avatar
          teacherEmail
          students {
            avatar
            userId
            inviteId
            email
            firstName
            lastName
            group
            emailSent
            tags {
              ...TagResponseFragment
            }
          }
          investigations {
            id
            title
            isAssessment
            investigationId
          }
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
    getUserDetails: gql`
      query GetUserDetails($data: UserInput!) {
        getUserDetails(data: $data) {
          id
          name
          email
          organization
          roles
          classes {
            classId
            name
            group
          }
          enrollmentDate
          lastLogin
          tags {
            ...TagResponseFragment
          }
          source
        }
      }
      ${TAGS_FRAGMENTS.tagResponse}
    `,
  },
  FRAGMENTS: {
    addClass: gql`
      fragment NewClass on ClassResponse {
        id
        name
        startDate
        endDate
        numberOfGroups
        students {
          userId
          inviteId
          email
          firstName
          lastName
          group
        }
      }
    `,
  },
  ANNOUNCEMENT: {
    getClassAnnouncements: gql`
      query GetClassAnnouncements($classId: String!) {
        getClassAnnouncements(classId: $classId) {
          id
          classId
          text
          createdAt
        }
      }
    `,
    getClassAnnouncementsForMyClasses: gql`
      query GetClassAnnouncementsForMyClasses {
        getClassAnnouncementsForMyClasses {
          id
          classId
          text
          createdAt
        }
      }
    `,
  },
};
