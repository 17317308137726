import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, Progress, Spin, message, Badge } from 'antd';
import { getProgressColor } from '../../../../utils/theme';
import * as S from './styles';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { themeConfig } from '../../../../utils/theme';
import AssignmentSummaryPopup from '../../GActiveAssignmentsRow/AssignmentSummaryPopup';

interface Props {
  date: string;
  assignmentName: string;
  assignmentId: string;
  courseId: string;
  courseWorkId: string;
  className: React.ReactNode;
  courseName: string;
  courseWorkType: string;
  icon: React.ReactNode;
}

const Item: React.FC<Props> = ({
  date,
  assignmentName,
  assignmentId,
  className,
  courseName,
  courseId,
  courseWorkId,
  courseWorkType,
  icon,
}) => {
  const [progress, setProgress] = useState<number>(0);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [maxPoints, setMaxPoints] = useState<number>(0);
  const [assignedGrade, setAssignedGrade] = useState<number | undefined>(undefined); // Changed to undefined

  // Fetch course work details including maxPoints
  const { loading: courseWorkLoading, data: courseWorkData, error: courseWorkError } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkById,
    {
      variables: { courseId: courseId, id: courseWorkId },
      onError: (error) => {
        console.error('Error fetching course work details:', error);
        message.error('Failed to fetch course work details');
      },
      onCompleted: (data) => {
        if (data && data.getCourseWorkById) {
          const points = data.getCourseWorkById.maxPoints || 0;
          setMaxPoints(points);
        }
      },
    }
  );

  // Fetch submission list to get assignedGrade
  const { loading: submissionListLoading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseWorkSubmissionList,
    {
      variables: { data: [{ courseId: courseId, courseWorkId: assignmentId }] },
      onError: (error) => {
        console.error('Error fetching submission list:', error);
        message.error('Failed to fetch submission list');
      },
      onCompleted: (data) => {
        console.log('Submission list data:', data); // Log the full response
        const submissionList = data?.getCourseWorkSubmissionList?.[0]?.studentSubmissions || [];
        if (submissionList.length > 0) {
          const submission = submissionList[0];
          const grade = submission?.assignedGrade ?? null; // Set default grade to 0
          console.log('Found submission:', submission); // Log the found submission
          console.log('Found assigned grade:', grade); // Log the found grade
          setAssignedGrade(grade);
        } else {
          console.log('No submissions found'); // Log when no submissions are found
          setAssignedGrade(0); // Set default grade to 0 if no submission found
        }
      },
    }
  );

  // Calculate progress percentage based on assignedGrade and maxPoints
  useEffect(() => {
    console.log('useEffect - maxPoints:', maxPoints, 'assignedGrade:', assignedGrade); // Log the values when useEffect is triggered
    if (maxPoints > 0 && assignedGrade !== undefined) {
      const percentage = (assignedGrade / maxPoints) * 100;
      console.log('Calculated percentage:', percentage); // Log the calculated percentage
      setProgress(percentage);
    } else {
      console.log('Skipping percentage calculation due to invalid values'); // Log when skipping calculation
    }
  }, [maxPoints, assignedGrade]);

  // Handle view summary popup
  const handleViewSummaryClick = useCallback(() => {
    setPopupVisible(true);
  }, []);

  // Handle close popup
  const handleClosePopup = useCallback(() => {
    setPopupVisible(false);
  }, []);

  // Function to capitalize only the first letter of a string
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <S.Card>
      <Row align="middle">
        <Col span={2}>
          <S.IconWrapper>{icon}</S.IconWrapper> {/* Render the icon here */}
        </Col>
        <Col span={18}>
          <S.ClassCourseInfo>
            <S.Info style={{ fontSize: '1.2em', marginLeft: '16px', textAlign: 'left', fontWeight: 500 }}>
              {className}
            </S.Info>
          </S.ClassCourseInfo>
        </Col>
        <Col span={4}>
          <Badge count={capitalizeFirstLetter(courseWorkType)} style={{ backgroundColor: '#E9A643', borderRadius: '16px', color: '#fff', fontWeight: 500, fontSize: '12px' }} />
        </Col>
      </Row>
      <Row style={{ marginTop: '1em' }}>
        <Col span={24}>
          <S.AssignmentName>
            <span style={{
              fontWeight: 500, // Bold for title appearance
              fontSize: '14px', // Slightly larger font size for title
              color: '#333', // Dark color for readability
              margin: '0', // No extra margin
              display: 'block', // Display as block for full width
              textAlign: 'left', // Align text to the left
              textTransform: 'capitalize', // Capitalize first letter of each word
            }}>
              {assignmentName}
            </span>
          </S.AssignmentName>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="middle">
          {courseWorkLoading || submissionListLoading ? (
            <Spin />
          ) : (
            <Progress
              strokeLinecap="round"
              strokeWidth={9}
              style={{ marginTop: 30 }}
              strokeColor={getProgressColor(progress)}
              format={(percent) => (
                <>
                  <S.Info $bold>{percent !== undefined ? Math.round(percent) : 0}%</S.Info>
                  <S.InfoProgress>Total Marks: {maxPoints}</S.InfoProgress>
                </>
              )}
              width={145}
              type="circle"
              percent={progress}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: '3em' }}>
        <Col span={14} offset={5}>
          <S.SummarydButton
            text="View Summary"
            htmlType="button"
            theme={themeConfig.primaryOutlined}
            onClick={handleViewSummaryClick}
            block
          />
        </Col>
      </Row>
      {isPopupVisible && (
        <Spin spinning={courseWorkLoading || submissionListLoading}>
          <AssignmentSummaryPopup
            courseId={courseId}
            assignmentId={assignmentId}
            visible={isPopupVisible}
            onClose={handleClosePopup}
            isFinished={true}
            score={progress}
            assignedGrade={assignedGrade}
            courseWorkData={courseWorkData}
            courseWorkLoading={courseWorkLoading} 
            courseWorkError={courseWorkError}
          />
        </Spin>
      )}
    </S.Card>
  );
};

export default Item;
