import React, { useCallback, useMemo, useState } from 'react';
import * as S from './styles';
import { Col, List, message, Row, Skeleton, Tabs } from 'antd';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';
import {
  GQL_EngagementQuestion,
  GQL_EngagementQuestionInput,
  GQL_FinalReportQuestionnaire,
  GQL_FinalReportQuestionnaireInput,
  GQL_FinalReportQuestionnaireQuestion,
  GQL_FinalReportQuestionnaireQuestionInput,
  GQL_GradedEngagementQuestions,
  GQL_GradedFinalReportQuestionnaire,
  GQL_InvestigationDisplay,
} from '../../types/investigation';
import InvestigationQuestionnaire from '../StudentInvestigation/InvestigationQuestionnaire';
import PageWithTitle from '../../shared/PageWithTitle';
import { useAuth } from '../../hooks/useAuth';
import Button from '../../shared/Button';
import { gradingEngagementAnswers, gradingFinalReportAnswers } from '../../utils/investigation';

const { TabPane } = Tabs;

type Props = RouteComponentProps<{
  investigationId: string;
}>;

const InvestigationGradeReportPage: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;
  const history = useHistory();
  const { isStudent, user } = useAuth();
  const [current] = useState(1);
  const [activeKey, setActiveKey] = useState('final-report');
  const [gradeFinalReportAnswers, setGradeFinalReportAnswers] = useState<GQL_FinalReportQuestionnaireInput[]>([]);
  const [gradeEngagementAnswers, setGradeEngagementAnwers] = useState<GQL_EngagementQuestionInput[]>([]);

  const { data: investigationData, loading: loadingInvestigation } = useQuery<
    { getInvestigationById: GQL_InvestigationDisplay },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CORE.getInvestigationById, {
    variables: {
      id: investigationId,
    },
    onError: () => {
      message.error(
        'There was an error loading the investigation details, please check your connection and try again later',
      );
    },
  });

  const { data: dataFinalReportGrading, loading } = useQuery<
    { getFinalReportGrading: GQL_GradedFinalReportQuestionnaire },
    { investigationId: string }
  >(gqlSchema.InvestigationSchema.queries.GRADING.getFinalReportGrading, {
    variables: {
      investigationId,
    },
    onCompleted: (data) => {
      setGradeFinalReportAnswers(
        data.getFinalReportGrading.questionnaire.map((q: GQL_FinalReportQuestionnaire) => ({
          index: q.index,
          feedback: q.feedback,
          questions: q.questions.map((s: GQL_FinalReportQuestionnaireQuestion) => ({
            index: s.index,
            answer: s.answer,
            isValid: true,
          })),
        })),
      );
    },
    onError: (err) => {
      message.error('There was an error loading grading: ' + err.message || 'Unexpected Error');
    },
  });

  const { data: dataEngagementGrading } = useQuery<
    { getEngagementGrading: GQL_GradedEngagementQuestions },
    { investigationId: string }
  >(gqlSchema.InvestigationSchema.queries.GRADING.getEngagementGrading, {
    variables: {
      investigationId,
    },
    onCompleted: (data) => {
      setGradeEngagementAnwers(
        data.getEngagementGrading.questions.map((q: GQL_EngagementQuestion) => ({
          index: q.index,
          answer: q.answer,
          isValid: true,
        })),
      );
    },
    onError: (err) => {
      message.error('There was an error loading engagement grade: ' + err.message || 'Unexpected Error');
    },
  });

  const onSelectAnswer = useCallback(
    (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
      const newGradeAnswers = gradeFinalReportAnswers.map((q: GQL_FinalReportQuestionnaireInput) => {
        if (q.index === questionIndex) {
          return {
            ...q,
            questions: q.questions.map((s: GQL_FinalReportQuestionnaireQuestionInput) => {
              if (subquestionIndex === s.index) {
                return {
                  ...s,
                  answer: answerIndex,
                  isValid: true,
                };
              }
              return { ...s };
            }),
          };
        }
        return { ...q };
      });
      setGradeFinalReportAnswers(newGradeAnswers);
    },
    [gradeFinalReportAnswers],
  );

  const onSelectEngagementAnswer = useCallback(
    (questionIndex: number, subquestionIndex: number, answerIndex: number) => {
      const newGradeAnswers = gradeEngagementAnswers.map((q: GQL_EngagementQuestionInput) => {
        if (subquestionIndex === q.index)
          return {
            ...q,
            answer: answerIndex,
            isValid: true,
          };
        return { ...q };
      });
      setGradeEngagementAnwers(newGradeAnswers);
    },
    [gradeEngagementAnswers],
  );

  const onSaveFeedback = useCallback(
    (questionIndex: number, feedback: string) => {
      if (gradeFinalReportAnswers.length > 0) {
        const newGradeAnswers = gradeFinalReportAnswers.map((q: GQL_FinalReportQuestionnaireInput) => {
          if (q.index === questionIndex) {
            return {
              ...q,
              feedback: feedback,
            };
          }
          return { ...q };
        });

        setGradeFinalReportAnswers(newGradeAnswers);
      }
    },
    [gradeFinalReportAnswers],
  );

  const loadingPlaceholder = useMemo(() => {
    const skeletons = [];
    for (let i = 0; i < 4; i++) {
      skeletons.push(
        <Skeleton key={i} loading active>
          <List.Item.Meta />
        </Skeleton>,
      );
    }
    return <S.Container>{skeletons}</S.Container>;
  }, []);

  return (
    <PageWithTitle
      title={
        <S.TitleContainer>
          {loadingInvestigation || loading
            ? 'Loading...'
            : `Report Score for ${investigationData?.getInvestigationById.title}`}
        </S.TitleContainer>
      }
    >
      <Row justify="center">
        <Col span={24}>
          <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={(activeKey: string) => setActiveKey(activeKey)}
          >
            <TabPane
              tab={<S.TitleTab data-cy="investigationgradereportpage-final-report">Final Report</S.TitleTab>}
              key="final-report"
              style={{ marginBottom: 30 }}
            >
              {loading ? (
                loadingPlaceholder
              ) : (
                <InvestigationQuestionnaire
                  onSelectAnswer={onSelectAnswer}
                  onFeedbackChange={onSaveFeedback}
                  disableAnswers={isStudent && !user.isAllowedGrading}
                  hideExtraFieldIfBlank
                  questionnaire={{
                    questions:
                      dataFinalReportGrading?.getFinalReportGrading?.questionnaire.map(
                        (q: GQL_FinalReportQuestionnaire) => {
                          const ans = gradeFinalReportAnswers.find(
                            (ans: GQL_FinalReportQuestionnaireInput) => ans.index === q.index,
                          );
                          return {
                            title: q.title,
                            needsFeedback: true,
                            feedback: ans?.feedback,
                            subquestions: q.questions.map(
                              (subquestion: GQL_FinalReportQuestionnaireQuestion, i: number) => {
                                const subQuestion = ans?.questions.find(
                                  (r: GQL_FinalReportQuestionnaireQuestionInput) => r.index === i,
                                );
                                return {
                                  text: subquestion.question,
                                  answer: subQuestion?.answer,
                                  isValid: subQuestion?.isValid || false,
                                  options: gradingFinalReportAnswers,
                                };
                              },
                            ),
                          };
                        },
                      ) || [],
                  }}
                />
              )}
            </TabPane>
            <TabPane
              tab={<S.TitleTab data-cy="investigationgradereportpage-engagement">Engagement</S.TitleTab>}
              key="engagement"
              style={{ marginBottom: 30 }}
            >
              <InvestigationQuestionnaire
                onSelectAnswer={onSelectEngagementAnswer}
                disableAnswers={isStudent && !user.isAllowedGrading}
                hideExtraFieldIfBlank
                questionnaire={{
                  questions: [
                    {
                      title: 'Take a look at the engagement. Do you think the student has:',
                      needsFeedback: false,
                      subquestions:
                        dataEngagementGrading?.getEngagementGrading?.questions.map((q: GQL_EngagementQuestion) => {
                          const ans = gradeEngagementAnswers.find(
                            (ans: GQL_EngagementQuestionInput) => ans.index === q.index,
                          );
                          return {
                            text: q.question,
                            options: gradingEngagementAnswers,
                            answer: ans?.answer,
                            isValid: ans?.isValid || false,
                          };
                        }) || [],
                    },
                  ],
                }}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Row justify="end" style={{ margin: '10px 0' }}>
        <Col span={24}>
          {current === 2 && <Button block size="large" onClick={() => history.goBack()} text="Go to Investigation" />}
        </Col>
      </Row>
    </PageWithTitle>
  );
};

export default withRouter(InvestigationGradeReportPage);
