import { gql } from '@apollo/client';
import ACCOUNT_FRAGMENTS from '../fragment/Accounts';

export const ACCOUNT = {
  AUTH: {
    login: gql`
      mutation Login($data: LoginInput!) {
        login(data: $data) {
          id
          accessToken
          expiresIn
          refreshToken
          name
          email
          active
          username
          assistantsAllowed
          givenName
          familyName
          roles
          betaEnrolled
          userEnabled
          tokenType
          avatar
          organizationName
          enrollmentDate
          walkthroughs
          acknowledgedPresentationMessage
          isAllowedGrading
          isCleverAdmin
          isClasslinkAdmin
          isAdvancedSearch
          preferredRole
          subscription {
            ...Subscription
          }
          subscriptions {
            ...Subscription
          }
          emailPreference {
            id
            title
            active
          }
          source
          disabled
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
    loginWithClever: gql`
      mutation LoginWithClever($code: String!) {
        loginWithClever(code: $code) {
          id
          accessToken
          expiresIn
          refreshToken
          name
          email
          active
          username
          assistantsAllowed
          givenName
          familyName
          roles
          userEnabled
          tokenType
          avatar
          organizationName
          enrollmentDate
          walkthroughs
          acknowledgedPresentationMessage
          isAdvancedSearch
          isAllowedGrading
          subscription {
            ...Subscription
          }
          subscriptions {
            ...Subscription
          }
          emailPreference {
            id
            title
            active
          }
          source
          disabled
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
    loginWithClasslink: gql`
      mutation loginWithClasslink($code: String!) {
        loginWithClasslink(code: $code) {
          id
          accessToken
          expiresIn
          refreshToken
          name
          email
          active
          username
          assistantsAllowed
          givenName
          familyName
          roles
          userEnabled
          tokenType
          avatar
          organizationName
          enrollmentDate
          walkthroughs
          acknowledgedPresentationMessage
          isAdvancedSearch
          isAllowedGrading
          subscription {
            ...Subscription
          }
          subscriptions {
            ...Subscription
          }
          emailPreference {
            id
            title
            active
          }
          source
          disabled
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
    loginWithGoogle: gql`
      mutation loginWithGoogle($code: String!) {
        loginWithGoogle(code: $code) {
          id
          accessToken
          expiresIn
          refreshToken
          name
          email
          active
          username
          assistantsAllowed
          givenName
          familyName
          roles
          userEnabled
          tokenType
          avatar
          organizationName
          enrollmentDate
          walkthroughs
          acknowledgedPresentationMessage
          isAdvancedSearch
          isAllowedGrading
          subscription {
            ...Subscription
          }
          subscriptions {
            ...Subscription
          }
          emailPreference {
            id
            title
            active
          }
          source
          disabled
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
    loginWithGoogleTest: gql`
      mutation loginWithGoogleTest($code: String!) {
        loginWithGoogleTest(code: $code) {
          id
          accessToken
          expiresIn
          refreshToken
          googleAuthToken
          googleAuthRefreshToken
          googleAuthTokenExpires_in
          name
          email
          active
          username
          assistantsAllowed
          givenName
          familyName
          roles
          userEnabled
          tokenType
          avatar
          organizationName
          enrollmentDate
          walkthroughs
          isGoogleTeacher
          isGoogleStudent
          acknowledgedPresentationMessage
          isAdvancedSearch
          isAllowedGrading
          subscription {
            ...Subscription
          }
          subscriptions {
            ...Subscription
          }
          emailPreference {
            id
            title
            active
          }
          source
          disabled
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
    forgotPassword: gql`
      mutation ForgotPassword($data: PasswordResetInput!) {
        forgotPassword(data: $data) {
          emailSent
        }
      }
    `,
  },
  SIGN_UP: {
    signUp: gql`
      mutation SignUp($data: SignupInput!) {
        signup(data: $data) {
          id
          email
        }
      }
    `,
  },
  CREATE_SUBSCRIPTION_USER: {
    createSubscriptionUser: gql`
      mutation CreateSubscriptionUser($data: SubscriptionUserInput!) {
        createSubscriptionUser(data: $data)
      }
    `,
  },
  PROFILE: {
    changePasswordFromToken: gql`
      mutation ChangePasswordFromToken($token: String!, $newPassword: String!) {
        setNewPasswordFromToken(token: $token, newPassword: $newPassword)
      }
    `,
    changePassword: gql`
      mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
        setNewPasswordByMe(currentPassword: $currentPassword, newPassword: $newPassword)
      }
    `,
    uploadAvatar: gql`
      mutation UploadAvatar($file: Upload) {
        uploadAvatar(file: $file) {
          userId
          MIMEType
          url
        }
      }
    `,
    toggleEmailPreference: gql`
      mutation ToggleEmailPreference($data: [ToggleEmailPreferenceInput]!) {
        toggleEmailPreference(data: $data) {
          id
          isActive
        }
      }
    `,
    updatePreferredRole: gql`
      mutation updatePreferredRole($role: String!,$subscription: String) {
        updatePreferredRole(role: $role,subscription: $subscription)
      }
    `,
    editUserRoles: gql`
      mutation EditUserRoles($data: EditUserRolesInput!) {
        editUserRoles(data: $data)
      }
    `,
    updateBetaEnrollment: gql`
      mutation updateBetaEnrollment($enroll: Boolean!) {
        updateBetaEnrollment(enroll: $enroll)
      }
    `,
    dismissOnboarding: gql`
      mutation dismissOnboarding {
        dismissOnboarding
      }
    `,
    dismissInsightsTutorial: gql`
      mutation dismissInsightsTutorial {
        dismissInsightsTutorial
      }
    `,
  },
  WALKTRHOUGH: {
    markWalkthroughsComplete: gql`
      mutation MarkWalkthroughsComplete($types: [String]!) {
        markWalkthroughsComplete(types: $types)
      }
    `,
  },
};
