import React, { useEffect, useState } from 'react';
import { Col, message, Row, Tooltip } from 'antd';
import Button from '../../../shared/Button';
import CommentBox from '../../../shared/CommentBox';
import * as S from './styles';
import Editor from '../../../shared/Editor';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { debounce } from 'lodash';
import { ITextSubmitContentValue } from '../../../types/investigation';
import Spacer from '../../../shared/Spacer';
import { useAuth } from '../../../hooks/useAuth';
import { GQL_InvestigationSummaryResponse } from '../../../types/teacher';
import { FaUnlock } from 'react-icons/fa';

interface IInvestigationTextSubmit {
  currentStepId?: string;
  values: ITextSubmitContentValue[];
  investigationId?: string;
  activityId: string;
  userId?: string;
  isAssessment?: boolean;
  isLocked?: boolean;
}

interface IUnlockStudentSubmission {
  investigationId?: string;
  currentStepId?: string;
  currentActivityId?: string;
  studentId?: string;
  isUnlockButtonEnabled: boolean;
  setIsUnlockButtonEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvestigationTextSubmit = (props: IInvestigationTextSubmit) => {
  const { currentStepId, values, investigationId, userId, isAssessment = false, isLocked = true } = props;
  const { isStudent } = useAuth();
  const value = values[0];
  const [isUnlockButtonEnabled, setIsUnlockButtonEnabled] = useState(!value.isUnlocked);
  const [comment, setComment] = useState(value.text || '');
  const addCommentDebounce = debounce(setComment, 300);

  useEffect(() => {
    setComment(value.text || '');
  }, [value.text]);

  useEffect(() => {
    // If resubmission allowed for the user, enable new text submission
    if (isAssessment && !isLocked) {
      setIsUnlockButtonEnabled(false);
    }
  }, [isAssessment, isLocked]);

  const { data: investigationData } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId || '',
    },
    fetchPolicy: 'cache-only',
  });

  const studentList = investigationData?.getInvestigationProgressSummary?.perStudents || [];
  const student = studentList?.find((student) => student.userId === userId);
  const studentName = student ? `${student.firstName} ${student.lastName}` : undefined;

  const [submitTextSubmission, { loading: loadingSubmit }] = useMutation<
    { submitTextSubmission: { id: string } },
    { activityId: string; stepId: string; text: string }
  >(gqlSchema.InvestigationSchema.mutations.TEXT_SUBMISSION.submitTextSubmission, {
    onCompleted: () => setIsUnlockButtonEnabled(true),
    onError: (err) => message.error('There was an error submitting the comment: ' + err.message),
  });

  const handleTextSubmit = () => {
    if (props.activityId && currentStepId) {
      submitTextSubmission({
        variables: {
          activityId: props.activityId,
          text: comment,
          stepId: currentStepId,
        },
      });
    }
  };

  return (
    <Col span={24} data-cy="components-investigation-text-submit">
      <Row justify="center" align="middle" gutter={[0, 12]}>
        {value.text && isUnlockButtonEnabled ? (
          <CommentBox
            author={isStudent ? undefined : studentName}
            avatar={student?.avatar}
            actions={
              isStudent ? (
                [<S.CommentWarning key="warning">*Only your teacher can see this response.</S.CommentWarning>]
              ) : (
                <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
                  <UnlockStudentSubmission
                    currentActivityId={props.activityId}
                    currentStepId={currentStepId}
                    studentId={student?.userId}
                    isUnlockButtonEnabled={isUnlockButtonEnabled}
                    setIsUnlockButtonEnabled={setIsUnlockButtonEnabled}
                  />
                </Col>
              )
            }
          >
            <h2 style={{ fontWeight: 400 }}>{value.title}</h2>
            <Spacer />
            <Editor
              value={comment || value.text}
              listenToValue
              editable={false}
              key="not-editable"
              data-cy="components-text-submit-editor-not-editable"
            />
          </CommentBox>
        ) : (
          <CommentBox
            author={isStudent ? undefined : studentName}
            avatar={student?.avatar}
            actions={
              isStudent ? (
                <S.CommentWarning key="warning">*Only your teacher will be able to see this response.</S.CommentWarning>
              ) : (
                <>
                  <Col>
                    <S.CommentWarning key="warning">
                      *Students should submit their answer before proceeding.
                    </S.CommentWarning>
                  </Col>

                  <Col>
                    <UnlockStudentSubmission
                      currentActivityId={props.activityId}
                      currentStepId={currentStepId}
                      studentId={student?.userId}
                      isUnlockButtonEnabled={isUnlockButtonEnabled}
                      setIsUnlockButtonEnabled={setIsUnlockButtonEnabled}
                    />
                  </Col>
                </>
              )
            }
          >
            <h2 style={{ fontWeight: 400 }}>{value.title}</h2>
            <Spacer />

            <Editor
              data-cy="components-text-submit-editor-editable"
              placeholder="Submit your understandings of the proposed text"
              editable={isStudent}
              key="editable"
              listenToValue
              value={value.text}
              onChange={(v) => {
                return addCommentDebounce(v.isEditorEmpty ? '' : v.value);
              }}
            />
            {isStudent && (
              <S.ButtonsRow gutter={16}>
                <Col>
                  <Button
                    data-cy="components-studentinvestigation-investigationtextsubmit-submit-btn"
                    text="Submit Comment"
                    onClick={handleTextSubmit}
                    loading={loadingSubmit}
                    disabled={!comment || !isStudent}
                  />
                </Col>
              </S.ButtonsRow>
            )}
          </CommentBox>
        )}
      </Row>
    </Col>
  );
};

export default InvestigationTextSubmit;

const UnlockStudentSubmission: React.FC<IUnlockStudentSubmission> = ({
  currentStepId,
  currentActivityId,
  studentId,
  isUnlockButtonEnabled,
  setIsUnlockButtonEnabled,
}) => {
  const [unlockTextSubmission, { loading: isUnlocking }] = useMutation<
    { unlockTextSubmission: { id: string } },
    { activityId: string; stepId: string; studentId: string }
  >(gqlSchema.InvestigationSchema.mutations.TEXT_SUBMISSION.unlockTextSubmission, {
    onCompleted: () => {
      message.success(`Student progress check unlocked. They are now able to edit and re-submit their comment.`);
      setIsUnlockButtonEnabled(false);
    },
    onError: (err) => message.error('There was an error unlocking the submission: ' + err.message),
  });

  const handleUnlockTextSubmission = (e: React.MouseEvent<HTMLElement> | undefined) => {
    if (currentActivityId && currentStepId && studentId) {
      unlockTextSubmission({
        variables: {
          activityId: currentActivityId!,
          stepId: currentStepId!,
          studentId: studentId!,
        },
      });
    }
  };

  return (
    <S.UnlockPopConfirm
      title={
        <S.UnlockPopConfirmParagraph>
          By clicking <strong>confirm</strong>, you are allowing the student <br /> to edit and re-submit their comment.
          Click <strong>cancel</strong> to exit.
        </S.UnlockPopConfirmParagraph>
      }
      overlayClassName="help-stuck-popconfirm-overlay"
      trigger={isUnlockButtonEnabled ? 'click' : ''}
      onConfirm={handleUnlockTextSubmission}
      okText="Confirm"
      cancelText="Cancel"
      placement="left"
      icon={<></>}
    >
      <Tooltip
        placement="left"
        title={
          isUnlockButtonEnabled
            ? `Click here to unlock the student's progress check which will allow them to edit and re-submit their comment.`
            : `Unable to unlock the student's progress check because they have not submitted a new progress check.`
        }
      >
        <S.UnlockSubmissionContainer>
          <S.UnlockSubmissionButton
            type="text"
            $disabled={!isUnlockButtonEnabled}
            loading={isUnlocking}
            shape="circle"
            icon={<FaUnlock size={20} />}
          />
        </S.UnlockSubmissionContainer>
      </Tooltip>
    </S.UnlockPopConfirm>
  );
};
