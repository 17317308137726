import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Empty } from 'antd';
import Item from './Item';
import { useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';
import AssignmentIcon from '../../../assets/assignment.svg'; // Import the AssignmentIcon

interface IClassAssignmentsRow {
  classId: string;
  shouldLoad?: boolean;
  loaded?: () => void;
}

const ClassAssignmentsRow: React.FC<IClassAssignmentsRow> = ({ classId, shouldLoad, loaded }) => {
  const [sortedAssignments, setSortedAssignments] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const { data, loading: assignmentsLoading } = useQuery(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCoursesListWithCourseWork
  );

  useEffect(() => {
    if (!assignmentsLoading && data) {
      const assignments = data.getCoursesListWithCourseWork.flatMap((course: any) => {
        return (course.coursework || []).map((work: any) => ({
          ...work,
          courseName: course.name,
          courseDescription: course.description,
        }));
      });

      const sortedList = assignments.sort((a: any, b: any) => {
        if (a.dueDate && b.dueDate) {
          return new Date(b.dueDate.year, b.dueDate.month - 1, b.dueDate.day).getTime() - new Date(a.dueDate.year, a.dueDate.month - 1, a.dueDate.day).getTime();
        } else {
          return 0;
        }
      });

      setSortedAssignments(sortedList);
      loaded && loaded();
    }
  }, [assignmentsLoading, data, loaded]);

  const filteredAssignments = useMemo(() => {
    if (!searchText) {
      return sortedAssignments;
    }
    return sortedAssignments.filter((item: any) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [sortedAssignments, searchText]);

  const activeAndExtraData = useMemo(() => {
    return filteredAssignments.map((item: any, index: number) => {
      console.log('Item:', item); // Add console log here to check the structure of item
      if (!item.dueDate) return null; // Check if dueDate exists
      return (
        <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24} key={item.id} style={{ marginBottom: '16px' }}>
          <Item
            description={item.courseDescription}
            timeLeft={new Date(item.dueDate.year, item.dueDate.month - 1, item.dueDate.day).getTime()}
            assignmentName={item.title}
            assignmentId={item.id}
            progress={0}
            timeWarning={false}
            courseId={item.courseId}
            icon={<img src={AssignmentIcon} alt="Assignment Icon" />}
            version={1}
            workType={item.workType}
            className={item.courseName} // Use the correct property name
          />
        </Col>
      );
    });
  }, [filteredAssignments]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <input
          type="text"
          placeholder="Search Assignments"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 'calc(30% - 100px)', marginRight: '10px' }}
        />
      </div>
      {assignmentsLoading ? (
        <Row gutter={[24, 24]}>
          {[...Array(3)].map((_, index) => (
            <Col key={index} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
              <DashboardLoadingCard />
            </Col>
          ))}
        </Row>
      ) : filteredAssignments.length ? (
        <Row gutter={[24, 24]}>
          {activeAndExtraData}
        </Row>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Active Assignments" />
      )}
    </div>
  );
};

export default ClassAssignmentsRow;
