import styled from 'styled-components';

export const SummaryContainer = styled.div`
  padding: 20px;
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;

  > div {
    flex: 1 1 30%;
    margin: 10px 0;
  }
`;

export const InfoRow = styled.div`
  margin-bottom: 10px;
`;

export const InfoLabel = styled.div`
  font-weight: bold;
`;

export const InfoValue = styled.div`
  margin-top: 5px;
`;

export const AssignmentLinks = styled.div`
  margin-top: 5px;

  > div {
    margin-bottom: 5px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button:first-child {
    margin-right: 10px;
  }
`;

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const UploadedLinks = styled.div`
  margin-top: 10px;
  a {
    display: block;
    margin-bottom: 5px;
    text-decoration: none;
    color: #1890ff;
    &:hover {
      text-decoration: underline;
    }
  }
`;
