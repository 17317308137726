import { useMutation } from "@apollo/client";
import client from "../gql/api";
import { useAuth } from "./useAuth";
import { gqlSchema } from "../gql/schema";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useGoogleRoleUpdater = () => {
  const { setActiveRole: setActiveRoleAuth } = useAuth();
  const [updatePreferredRoleMutation] = useMutation(gqlSchema.AccountsSchema.mutation.ACCOUNT.PROFILE.updatePreferredRole);
  const history = useHistory();

  const updateRoleLocally = setActiveRoleAuth;

  const updatePreferredRoleTeacher = useCallback(async () => {
    try {
      const { data } = await updatePreferredRoleMutation({
        variables: { role: 'teacher' },
        refetchQueries: ['MeQuery'],
      });

      if (data && data.updatePreferredRole) {
        await client.resetStore();
        updateRoleLocally('teacher');
        history.push('/teacher-dashboard');
      }
    } catch (error) {
      console.error('Error updating preferred role to teacher:', error);
    }
  }, [updatePreferredRoleMutation, updateRoleLocally, history]);

   const updatePreferredRoleToGoogleTeacher = useCallback(async () => {
    try {
      const { data } = await updatePreferredRoleMutation({
        variables: { role: 'google_teacher' },
        refetchQueries: ['MeQuery'],
      });

      if (data && data.updatePreferredRole) {
        await client.resetStore();
        updateRoleLocally('google_teacher');
        history.push('/googleclassroom-teacher-dashboard');
      }
    } catch (error) {
      console.error('Error updating preferred role to google_teacher:', error);
    }
  }, [updatePreferredRoleMutation, updateRoleLocally, history]);

  const updatePreferredRoleToGoogleStudent = useCallback(async () => {
    try {
      const { data } = await updatePreferredRoleMutation({
        variables: { role: 'google_student' },
        refetchQueries: ['MeQuery'],
      });

      if (data && data.updatePreferredRole) {
        await client.resetStore();
        updateRoleLocally('google_student');
        history.push('/googleclassroom-students-dashboard');
      }
    } catch (error) {
      console.error('Error updating preferred role to google_student:', error);
    }
  }, [updatePreferredRoleMutation, updateRoleLocally, history]);

  return {
    updatePreferredRoleToGoogleTeacher,
    updatePreferredRoleTeacher,
    updatePreferredRoleToGoogleStudent,
  };
};

export default useGoogleRoleUpdater;
