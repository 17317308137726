import { useLazyQuery } from '@apollo/client';
import { Col, message, Row, Tooltip } from 'antd';
import { startCase } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiDownloadCloud } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { gqlSchema } from '../../../gql/schema';
import { useAuth } from '../../../hooks/useAuth';
import Button from '../../../shared/Button';
import DashboardRow from '../../../shared/DashboardRow';
import InvestigationIcon from '../../../shared/InvestigationIcon';
import { GQL_ClassInvestigationWithoutGrade } from '../../../types/class';
import DownloadSubmissionsModal from '../../InvestigationSummary/DownloadSubmissionsModal';
import AddInvestigationButton from './AddInvestigationButton';
import Item from './Item';
import DashboardLoadingCard from '../../../shared/DashboardLoadingCard';

interface IClassInvestigationsRow {
  isAssessment?: boolean;
  className?: string;
  classId: string;
  shouldLoad?: boolean;
  loaded: () => void;
}

const ClassInvestigationsRow = (props: IClassInvestigationsRow) => {
  const { classId, isAssessment } = props;
  const { isTeacherOrFacilitator, isTeacher, isStudent, isTeacherAssistant } = useAuth();
  const [sortedInvestigations, setSortedInvestigations] = useState<GQL_ClassInvestigationWithoutGrade[]>([]);
  const [sortedAssessments, setSortedAssessments] = useState<GQL_ClassInvestigationWithoutGrade[]>([]);
  const [downloadSubmissionsVisible, setDownloadSubmissionsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const isClassDashboard = history.location.pathname.includes('teacher-dashboard');

  const [getInvestigationsByClassId] = useLazyQuery<
    { getInvestigationsByClassId: GQL_ClassInvestigationWithoutGrade[] },
    { classId: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationsByClassIdWithoutGrade, {
    variables: {
      classId: props.classId,
    },
    onCompleted: (data) => {
      setLoading(false);

      // notify parent of load complete
      props.loaded();

      let investigations: GQL_ClassInvestigationWithoutGrade[] = [];
      let assessments: GQL_ClassInvestigationWithoutGrade[] = [];

      data.getInvestigationsByClassId.forEach((each) => {
        if (each.isAssessment) {
          assessments.push(each);
        } else {
          investigations.push(each);
        }
      });

      setSortedInvestigations([...investigations].sort((a, b) => b.dueDate - a.dueDate));
      setSortedAssessments([...assessments].sort((a, b) => b.dueDate - a.dueDate));
    },
    onError: (err) => {
      setLoading(false);

      // notify parent of load complete
      props.loaded();

      message.error(
        `There was an error loading the ${isAssessment ? 'assessment' : 'investigation'}: ` + err.message ||
          'Unexpected Error',
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (props.shouldLoad) {
      getInvestigationsByClassId();
    }
  }, [props.shouldLoad, getInvestigationsByClassId]);

  const localType = useCallback(
    (item: GQL_ClassInvestigationWithoutGrade) => {
      if (isClassDashboard) {
        return isAssessment ? 'Assessment' : 'Investigation';
      }

      return item.discipline?.subject === 'Engineering'
        ? 'Design Challenge'
        : isAssessment
        ? 'Assessment'
        : 'Investigation';
    },
    [isAssessment, isClassDashboard],
  );

  const activeData = useMemo(() => {
    const data = isAssessment ? sortedAssessments : sortedInvestigations;

    return (
      <Row gutter={[24, 24]}>
        {isTeacherOrFacilitator && (
          <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24}>
            <AddInvestigationButton isAssessment={isAssessment} />
          </Col>
        )}
        {data.slice(0, isTeacherAssistant ? 3 : 2).map((item) => {
          return (
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
              <Item
                description={item.description}
                timeLeft={item.dueDate}
                classId={classId}
                investigationName={`${startCase(item.discipline?.name?.toLowerCase())}: ${item.title}`}
                investigationId={item.id}
                timeWarning={false}
                progress={(item?.completion || 0) * 100}
                type={localType(item)}
                isAssessment={isAssessment}
                icon={
                  <InvestigationIcon
                    subject={item.discipline?.subject || ''}
                    discipline={item.discipline?.name || ''}
                  />
                }
                version={item.submissionVersion}
              />
            </Col>
          );
        })}

        {loading ? (
          <>
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key="loading-1">
              <DashboardLoadingCard />
            </Col>
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key="loading-2">
              <DashboardLoadingCard />
            </Col>
          </>
        ) : null}
      </Row>
    );
  }, [
    isAssessment,
    sortedAssessments,
    sortedInvestigations,
    isTeacherOrFacilitator,
    isTeacherAssistant,
    classId,
    localType,
    loading,
  ]);

  const extraData = useMemo(() => {
    const data = isAssessment ? sortedAssessments : sortedInvestigations;

    return (
      <Row gutter={[24, 24]}>
        {data.slice(isTeacherOrFacilitator ? 2 : 3).map((item) => {
          return (
            <Col xxl={8} xl={8} lg={12} md={24} sm={24} xs={24} key={item.id}>
              <Item
                description={item.description}
                timeLeft={item.dueDate}
                investigationName={`${startCase(item.discipline?.subject?.toLowerCase())}: ${item.title}`}
                investigationId={item.id}
                progress={(item?.completion || 0) * 100}
                timeWarning={false}
                type={localType(item)}
                classId={classId}
                isAssessment={isAssessment}
                icon={
                  <InvestigationIcon
                    subject={item.discipline?.subject || ''}
                    discipline={item.discipline?.name || ''}
                  />
                }
                version={item.submissionVersion}
              />
            </Col>
          );
        })}
      </Row>
    );
  }, [classId, isTeacherOrFacilitator, sortedInvestigations, sortedAssessments, isAssessment, localType]);
  const dataToCheck = isAssessment ? sortedAssessments : sortedInvestigations;
  const needsViewMore = isStudent ? dataToCheck.length > 3 : dataToCheck.length > 2;

  return (
    <>
      <DashboardRow
        title={`${props.className || 'Class'} ${props.isAssessment ? 'Assessments' : 'Investigations'}`}
        items={activeData}
        extraItems={extraData}
        showViewMore={needsViewMore}
        rightElement={
          isTeacher ? (
            <Tooltip title="Download Student Submissions">
              <Button
                shape="circle"
                onClick={() => setDownloadSubmissionsVisible(true)}
                style={{ marginLeft: 'auto', top: 12 }}
                minWidth={40}
                minHeight={40}
                icon={<FiDownloadCloud size={22} />}
              />
            </Tooltip>
          ) : undefined
        }
      />
      {isTeacher && (
        <DownloadSubmissionsModal
          setVisible={setDownloadSubmissionsVisible}
          visible={downloadSubmissionsVisible}
          classId={classId}
        />
      )}
    </>
  );
};

export default ClassInvestigationsRow;
