import React, { useState } from 'react';
import { Col, Row, Card, Badge, Progress, message, Spin } from 'antd';
import { useAuth } from '../../../hooks/useAuth';
import * as S from './styles';
import { GQL_GoogleClassroomCourses } from '../../../types/googleClassroom';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface IClassInfosProps {
  classInfo?: GQL_GoogleClassroomCourses;
  className: string;
  studentNames: { userId: string; fullName: string }[];
  onDeleteSuccess: () => void;
  dueDate?: { year: number; month: number; day: number };
  alternateLink: string;
}

const ClassInfos: React.FC<IClassInfosProps> = ({ classInfo, className, studentNames, onDeleteSuccess, dueDate, alternateLink }) => {
  console.log('ClassInfos - courseId:', classInfo?.courseId);
  console.log('ClassInfos - course work id:', classInfo?.id);
  const { user } = useAuth();
  const history = useHistory();
  const [progress, setProgress] = useState<number>(0);
  const [totalStudents, setTotalStudents] = useState<number>(0);

  const formatDate = (date: string) => {
    return moment(date).format('DD MMM YYYY');
  };

  const openDate = classInfo?.creationTime ? formatDate(classInfo.creationTime) : 'Not provided';
  const closedDate = dueDate
    ? `${dueDate.day} ${moment().month(dueDate.month - 1).format('MMM')} ${dueDate.year}`
    : 'Not provided';

  const [deleteAssignment, { loading: deleteLoading }] = useMutation(
    gqlSchema.GoogleClassroomSchema.mutations.GCLASS.DeleteCourseWork,
    {
      onCompleted: (data) => {
        if (data && data.deleteCourseWork) {
          message.success('Assignment deleted successfully');
          onDeleteSuccess();
          history.push(`/googleclassroom-teacher-dashboard/class/${classInfo?.courseId}`);
        } else {
          message.error(
            <span>
              Insufficient Permissions, please use this link: <a href={alternateLink} target="_blank" rel="noopener noreferrer">{alternateLink}</a>
            </span>
          );
        }
      },
      onError: (error) => {
        message.error('Insufficient Permissions, please use this link');
        console.error('Error deleting assignment:', error);
      },
    }
  );

  const { loading: progressLoading } = useQuery<any>(
    gqlSchema.GoogleClassroomSchema.queries.GOOGLEDASHBOARD.GetCourseStudentsTurnedIn,
    {
      variables: { expiredCourseWork: [{ id: classInfo?.id, courseId: classInfo?.courseId }] },
      skip: !classInfo?.id || !classInfo?.courseId,
      onCompleted: (data) => {
        const turnedIn = data.getCourseStudentsTurnedIn.find((item: any) => item.id === classInfo?.id);
        if (turnedIn) {
          setProgress(turnedIn.complete);
          setTotalStudents(turnedIn.totalStudent);
        }
      },
      onError: (error) => {
        console.error('Error fetching students turned in data:', error);
      },
    }
  );

  const handleDelete = () => {
    if (classInfo?.courseId && classInfo?.id) {
      deleteAssignment({
        variables: {
          id: classInfo.id,
          courseId: classInfo.courseId,
        },
      }).catch(err => {
        console.error('Delete assignment error:', err);
      });
    } else {
      message.error('Course ID or Course Work ID is missing');
    }
  };

  return (
    <S.ClassInfosContainer>
      <Card style={{ marginBottom: '16px', width: '100%' }}>
        <Row>
          <Col span={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {progressLoading ? (
              <Spin />
            ) : (
              <Progress type="circle" percent={progress} format={percent => `${percent}%`} />
            )}
          </Col>
          <Col span={16}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <S.Title>Class Name</S.Title>
                <p>{className}</p>
              </Col>
              <Col span={8}>
                <S.Title>Assignment Name</S.Title>
                <Badge
                  count={
                    <div style={{ maxWidth: '100%', overflowWrap: 'break-word', padding: '4px 8px', color: 'white' }}>
                      {classInfo?.title}
                    </div>
                  }
                  style={{ backgroundColor: '#52c41a', borderRadius: '16px' }}
                />
              </Col>

              <Col span={8}>
                <S.Title>Assignment Owner</S.Title>
                <p>{user ? `${user.name} - ${user.email}` : '-'}</p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <S.Title>No of Students</S.Title>
                <p>{totalStudents ? `${totalStudents} Students` : '-'}</p>
              </Col>
              <Col span={8}>
                <S.Title>Open Date & Closed Date</S.Title>
                <p>{`${openDate} - ${closedDate}`}</p>
              </Col>
            </Row>
            <Row justify="end">
              <S.CircularButton 
                type="primary" 
                danger 
                icon={<DeleteOutlined />} 
                onClick={handleDelete} 
                loading={deleteLoading}
              />
            </Row>
          </Col>
        </Row>
      </Card>
    </S.ClassInfosContainer>
  );
};

export default ClassInfos;
