import React, { useState } from 'react';
import { Modal, Form, Select, Row, Col, message } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { FiX } from 'react-icons/fi';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import * as S from './styles';
import { GQL_InvestigationCatalog } from '../../types/investigation';
import Button from '../../shared/Button';
import { themeConfig } from '../../utils/theme';
import { GQL_ClassInvestigation, GQL_ClassResponse } from '../../types/class';
import AssignAssessmentClassDatePicker from './AssignAssessmentClassDatePicker/AssignAssessmentClassDatePicker';
import { gqlSchema } from '../../gql/schema';
import { AssessmentTypes } from '../../types/assessments';
import useInvestigationMode from '../../hooks/useInvestigationMode';

interface IAssessmentAssignmentPopup {
  assessment: GQL_InvestigationCatalog | null | undefined;
  hidePopup: () => void;
  classes: GQL_ClassResponse[];
}

const AssessmentAssignmentPopup: React.FC<IAssessmentAssignmentPopup> = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const showPopup = props.assessment !== null && props.assessment !== undefined;
  const [formState, setFormState] = useState<'classes' | 'dates'>('classes');
  const [selectedClasses, setSelectedClasses] = useState<string[]>([]);
  const [selectedDateTimes, setSelectedDateTimes] = useState<{
    [key: string]: [startDate: any, endDate: any];
  }>({});

  const [assessmentMode, setAssessmentMode] = useState<AssessmentTypes>(AssessmentTypes.PAPERLESS);
  const [assignAssessmentMutation] = useMutation<
    {
      assignAssessmentToClasses: GQL_ClassInvestigation[];
    },
    {
      classIds: string[];
      assessmentId: string;
      duration: {
        startDate: number;
        endDate: number;
      };
      mode: AssessmentTypes;
    }
  >(gqlSchema.ClassSchema.mutation.CLASS.EDIT.assignAssessments, {
    onCompleted: () => {
      message.success('Assessment assigned successfully to all classes!');
      history.push('/teacher-dashboard');
    },
    onError: (error) => {
      message.error(`An error occurred while assigning assessment: ${error.message || 'Unknown error occurred.'}`);
    },
    refetchQueries: [{ query: gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.getUserAssigmentsLimits }],
  });

  const assessmentType = props.assessment?.steps?.[0]?.mode;
  const { AssessmentTypeOptions } = useInvestigationMode(assessmentType || '');

  const updateSelectedClasses = (values: SelectValue) => {
    setSelectedClasses(values as string[]);
  };

  const updateSelectAssessmentMode = (value: AssessmentTypes) => {
    setAssessmentMode(value);
  };

  const closePopup = () => {
    // reset state before closing popup
    form.resetFields(['classes']);
    setFormState('classes');

    // call method to close popup from parent
    props.hidePopup();
  };

  const handleFormNextState = () => {
    if (selectedClasses !== undefined && selectedClasses !== null && selectedClasses.length > 0) {
      setFormState('dates');
    }
  };

  const handleFormPreviousState = () => {
    setFormState('classes');
  };

  const handleFormSubmission = async () => {
    // validate if dates are set for all classes
    const doAllClassesHaveDates = selectedClasses.every((cId) => {
      const value = selectedDateTimes[cId];
      const isNotEmpty =
        cId !== undefined && value !== undefined && value !== null && Array.isArray(value) && value.length === 2;
      return isNotEmpty;
    });

    if (!doAllClassesHaveDates) {
      message.error('Please select start and end dates for all classes before submitting.');
      return;
    }

    // call query to assign assessment
    if (props.assessment?.id) {
      try {
        for (const cId of selectedClasses) {
          await assignAssessmentMutation({
            variables: {
              assessmentId: props.assessment?.id,
              classIds: [cId],
              duration: {
                startDate: selectedDateTimes[cId][0].valueOf(),
                endDate: selectedDateTimes[cId][1].valueOf(),
              },
              mode: assessmentMode,
            },
          });
        }

        closePopup();
      } catch (error: any) {
        message.error(`An error occured while assigning assessment: ${error.message}`);
      }
    }
  };

  return (
    <Modal closeIcon={<FiX />} footer={null} visible={showPopup} onCancel={closePopup}>
      <S.Heading>{props.assessment?.title}</S.Heading>
      <S.Description>{props.assessment?.description}</S.Description>

      <Form form={form} name="assign-assessment">
        {formState === 'classes' && (
          <>
            <S.Label>Which classes should take this assessment?</S.Label>
            <Form.Item name="classes">
              <Select
                showArrow
                placeholder="Select classes"
                mode="multiple"
                allowClear
                onChange={updateSelectedClasses}
              >
                {props.classes !== undefined &&
                  props.classes.map((c) => {
                    return (
                      <Select.Option key={c.id} value={c.id}>
                        <S.OptionContent>
                          <span role="none" >{c.name}</span>
                        </S.OptionContent>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </>
        )}

        {formState === 'dates' && (
          <>
            <AssignAssessmentClassDatePicker
              classes={props.classes}
              selectedClasses={selectedClasses}
              selectedDateTimes={selectedDateTimes}
              setSelectedDateTimes={setSelectedDateTimes}
            />

            <S.LabelOptions>Assessment Mode:</S.LabelOptions>
            <Form.Item name="mode">
              <Select
                showArrow
                placeholder="Assessment Mode"
                onChange={updateSelectAssessmentMode}
              >
                {AssessmentTypeOptions.map((t, index) => {
                  return (
                    <Select.Option key={`assessment-mode-${t.value}-${index}`} value={t.value}>
                      <S.OptionContent>
                        <span role="none" >{t.name}</span>
                      </S.OptionContent>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </>
        )}
      </Form>

      <Row style={{ marginTop: '48px' }} gutter={[16, 0]}>
        <Col span={12}>
          {formState === 'classes' ? (
            <Button block theme={themeConfig.primaryOutlined} text="Close" onClick={closePopup} />
          ) : (
            <Button block theme={themeConfig.primaryOutlined} text="Go Back" onClick={handleFormPreviousState} />
          )}
        </Col>
        <Col span={12}>
          {formState === 'classes' ? (
            <Button
              block
              text="Next"
              onClick={handleFormNextState}
              disabled={selectedClasses === undefined || selectedClasses.length === 0}
            />
          ) : (
            <Button block text="Submit" onClick={handleFormSubmission} />
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default AssessmentAssignmentPopup;
