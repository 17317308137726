/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Editor from '../../../shared/Editor';
import Spacer from '../../../shared/Spacer';
import { ICategoryDragContentValue } from '../../../types/investigation';
import * as S from './styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableCard from './DraggableCard';
import { Card, DragItem } from './DroppableCard';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { message } from 'antd';
import { useAuth } from '../../../hooks/useAuth';
import DroppableContainer from './DroppableContainer';

interface IAssessmentCategoryBoardProps {
  value: ICategoryDragContentValue;
  onBoardSorted: (value: boolean) => void;
  currentStepId?: string;
  investigationId?: string;
  activityId: string;
  userId?: string;
  presentationMode?: boolean;
  isLocked?: boolean;
  isImageVersion?: boolean;
}

export interface Item {
  id: number;
  text: string;
  isAnswer: boolean;
  targetIndex: number;
}

const AssessmentCategoryBoard = ({
  value,
  activityId,
  currentStepId,
  onBoardSorted,
  isLocked = false,
  presentationMode,
  isImageVersion = false,
}: IAssessmentCategoryBoardProps) => {
  const { isTeacher, isTeacherOrFacilitator } = useAuth();
  const showSuccess = !presentationMode && (isTeacherOrFacilitator || isTeacher);
  const [submitJsonSubmission] = useMutation<
    { submitJsonSubmission: { id: string } },
    { activityId: string; stepId: string; json: { data: { value: string[] }[] } }
  >(gqlSchema.InvestigationSchema.mutations.PROCESS_BOARD.submitProcessBoard, {
    onError: (err) => message.error('There was an error submitting the comment: ' + err.message),
  });

  const [options] = useState<Item[]>(() => {
    const targetValues = value.targets?.map((target, i) => target.values?.map((v, i) => ({ text: v, targetIndex: i })));
    if (!targetValues) return [];
    const filteredUndefined = targetValues.flatMap((v) => (v ? v : []));

    return filteredUndefined
      ?.sort(() => Math.random() - 0.5)
      .flat()
      .map((v, i) => ({ ...v, id: i, isAnswer: false }));
  });

  const [answers, setAnswers] = useState<Item[][]>(() => {
    if (value.answers && value.answers.length)
      return value.answers.map((answer, columnIndex) =>
        answer?.value?.map((a, i) => ({ text: a, id: i, targetIndex: columnIndex, isAnswer: true } as Item)),
      );
    return [];
  });

  const targetAnswers: string[][] | undefined = useMemo(() => {
    return value.targets?.map((answerColumn) => answerColumn?.values?.map((v) => v) || []);
  }, [value.targets]);

  const [areAnswersCorrect, setAreAnswersCorrect] = useState<boolean[]>(() => {
    return value.targets?.map(() => false) || [false];
  });

  const optionRowsAmount = Math.ceil(options.length / 4);
  const minHeightOptions = optionRowsAmount * 36 + optionRowsAmount * 8;

  const onDropElement = useCallback((item: DragItem, index: number) => {
    if (!item.isAnswer) {
      setAnswers((oldAnswers) => {
        const newAnswers = [...oldAnswers];
        if (Array.isArray(newAnswers[index])) {
          newAnswers[index].push({ ...item, targetIndex: index, isAnswer: true } as Item);
        } else {
          newAnswers[index] = [{ ...item, targetIndex: index, isAnswer: true }];
        }
        return newAnswers;
      });
    } else {
      // Set answers but check if the item exists in the array
      setAnswers((oldAnswers) => {
        const newAnswers = [...oldAnswers];
        const answerColumnIndex = newAnswers.findIndex((answerColumn) =>
          Boolean(answerColumn.find((answer) => answer.text === item.text)),
        );
        if (answerColumnIndex !== -1) {
          const answerIndex = newAnswers[answerColumnIndex].findIndex((answer) => answer.text === item.text);
          newAnswers[answerColumnIndex].splice(answerIndex, 1);
          if (Array.isArray(newAnswers[index])) {
            newAnswers[index].push({ ...item, targetIndex: index, isAnswer: true } as Item);
          } else {
            newAnswers[index] = [{ ...item, targetIndex: index, isAnswer: true }];
          }
        }
        return newAnswers;
      });
    }
  }, []);

  const renderCard = useCallback(
    (card: { id: number; text: string }, index: number, targetIndex: number) => {
      return (
        <Card
          key={`category-drag-drop-${card.id}-${index}`}
          index={index}
          id={card.id}
          text={card.text}
          canDragElement={!showSuccess}
          showSuccess={showSuccess}
          isCorrectAnswer={Boolean(targetAnswers?.[targetIndex].find((a) => a === card.text))}
          isImageVersion={isImageVersion}
        />
      );
    },
    [isImageVersion, showSuccess, targetAnswers],
  );

  useEffect(() => {
    if (activityId && currentStepId && !isLocked) {
      submitJsonSubmission({
        variables: {
          activityId: activityId,
          json: {
            data: answers.map((answerColumns) => ({ value: answerColumns?.map((answer) => answer.text) || [] })),
          },
          stepId: currentStepId,
        },
      });
    }
  }, [activityId, answers, currentStepId, isLocked, options, submitJsonSubmission]);

  useEffect(() => {
    if (activityId && currentStepId && !isLocked) {
      const answerLength = answers?.flat()?.length;
      const optionsLength = options?.length;
      if (answerLength === optionsLength) {
        onBoardSorted(true);
        setAreAnswersCorrect(
          answers.map(
            (answerColumn, answerIndex) =>
              answerColumn?.every((answer) => targetAnswers?.[answer.targetIndex].includes(answer.text)) &&
              answerColumn.length === targetAnswers?.[answerIndex].length,
          ),
        );
      }
    }
  }, [activityId, answers, currentStepId, isLocked, onBoardSorted, options.length, targetAnswers]);

  return (
    <S.ContainerCategoryBoard id="process-drag-and-drop-board-wrapper">
      <S.TitleInput>Questions</S.TitleInput>
      <Editor
        editable={false}
        value={(value as ICategoryDragContentValue).questionTitle}
        data-cy="student-view-sorting-board-title"
      />
      <DndProvider backend={HTML5Backend}>
        <S.ObjectContainers $minHeightOptions={minHeightOptions + 10}>
          {options.map((option, idx) => {
            return (
              <DraggableCard
                index={idx}
                text={option?.text}
                key={`draggable-dnd-process-${idx}`}
                isImageVersion={isImageVersion}
                hasTarget={Boolean(
                  answers.find((answerColumn) => answerColumn?.find((answer) => answer.text === option.text)),
                )}
              />
            );
          })}
        </S.ObjectContainers>
        <S.ContainerTargets>
          {value.targets?.map((target, targetIndex) => (
            <DroppableContainer
              key={`draggable-container-${targetIndex}`}
              index={targetIndex}
              title={target.title}
              onDropElement={onDropElement}
              showSuccess={showSuccess}
              isCorrectAnswer={areAnswersCorrect[targetIndex]}
            >
              {(answers[targetIndex] || []).map((answer, answerIndex) => renderCard(answer, answerIndex, targetIndex))}
            </DroppableContainer>
          ))}
        </S.ContainerTargets>
      </DndProvider>
      <Spacer />
    </S.ContainerCategoryBoard>
  );
};

export default AssessmentCategoryBoard;
