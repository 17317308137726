/* eslint-disable complexity, max-lines */
import { useQuery, useMutation } from '@apollo/client';
import {
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Progress,
  Popover,
  Rate,
  Row,
  Skeleton,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType, FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { FiChevronDown, FiDownload, FiSearch } from 'react-icons/fi';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Papa from 'papaparse';

import { gqlSchema } from '../../gql/schema';
import { useAuth } from '../../hooks/useAuth';
import Avatar from '../../shared/Avatar';
import Button from '../../shared/Button';
import PageWithTitle from '../../shared/PageWithTitle';
import Spacer from '../../shared/Spacer';
import TableSearchBox from '../../shared/TableSearchBox';
import {
  GQL_InvestigationSummaryResponse,
  GQL_AllMaterialsResponse,
  GQL_investigationTeacherSummary_PerStudent,
} from '../../types/teacher';
import { centerAlign } from '../../utils/antd';
import { groupConfig } from '../../utils/class';
import { formatDateTime } from '../../utils/date';
import { getProgressColor, themeConfig } from '../../utils/theme';
import { downloadCsv } from '../../utils/files';
import * as S from './styles';
import DownloadSubmissionsModal from './DownloadSubmissionsModal';
import { SubscriptionPackageType } from '../../constants/enums/SubscriptionPackageType';

type Props = RouteComponentProps<
  {
    investigationId: string;
  },
  any,
  {
    classId?: string;
  }
>;

const InvestigationSummary: React.FC<Props> = (props) => {
  const { investigationId } = props.match.params;
  const { classId } = props.location.state || {};
  const ref = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const {
    isTeacherOrFacilitator,
    isFacilitator,
    isTeacherAssistant,
    user,
    isSubscriptionUser,
    subscriptionPackageType,
  } = useAuth();
  const [searchVisible, setSearchVisible] = useState(false);
  const [openMaterials, setOpenMaterials] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [downloadSubmissionsVisible, setDownloadSubmissionsVisible] = useState(false);
  const classTitle = useMemo(() => (isFacilitator ? 'Course or Event' : 'Class'), [isFacilitator]);

  const downloadAllDisabled = useMemo(() => {
    if (!isSubscriptionUser) {
      return false;
    } else {
      // If STARTER plan disable feature
      if (subscriptionPackageType === SubscriptionPackageType.STARTER) {
        return true;
      }

      return false;
    }
  }, [isSubscriptionUser, subscriptionPackageType]);

  const { data: investigationData, loading } = useQuery<
    { getInvestigationProgressSummary: GQL_InvestigationSummaryResponse },
    { id: string }
  >(gqlSchema.InvestigationSchema.queries.CLASS.getInvestigationSummary, {
    variables: {
      id: investigationId,
    },
    onError: () => {
      message.error(
        'There was an error loading the investigation summary, please check your connection and try again later',
      );
    },
  });

  const { data: initialCacheQuery, startPolling, stopPolling } = useQuery(
    gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationMaterialsDownloadCache,
    {
      variables: { id: investigationId },
    },
  );

  const initialCacheStatus = initialCacheQuery?.getInvestigationMaterialsDownloadCache;
  const cacheUrl = initialCacheStatus?.fileUrl;

  const [getAllMaterials] = useMutation<{ getAllInvestigationMaterials: GQL_AllMaterialsResponse }, { id: string }>(
    gqlSchema.InvestigationSchema.mutations.SETTINGS.getAllInvestigationMaterials,
    {
      onError: (error) => {
        message.error(error.message || 'There was an error downloading all materials, please check your connection and try again later');
      },
      fetchPolicy: 'network-only',
    },
  );

  const letterHomeUrl = investigationData?.getInvestigationProgressSummary?.letterHomeUrl;
  const standardsUrl = investigationData?.getInvestigationProgressSummary?.standardsUrl;

  const type =
    investigationData?.getInvestigationProgressSummary?.discipline?.subject === 'Engineering'
      ? 'Design Challenge'
      : 'Investigation';
  const investigationSummary = investigationData?.getInvestigationProgressSummary;
  const investigationOpen = Date.now() < (investigationSummary?.dueDate ?? 0);
  const allowDownloadGrades = useMemo(() => isTeacherOrFacilitator && investigationSummary, [
    investigationSummary,
    isTeacherOrFacilitator,
  ]);
  const statusToText = (
    status: 'IN_PROGRESS' | 'READY_FOR_GRADING' | 'OVERDUE' | 'COMPLETED' | 'NOT_STARTED',
    dueDate: number,
  ) => {
    if (status === 'COMPLETED') return 'Completed';
    else if (status === 'IN_PROGRESS') return 'In Progress';
    else if (status === 'NOT_STARTED') return formatDateTime(dueDate);
    else if (status === 'OVERDUE') return formatDateTime(dueDate);
    else if (status === 'READY_FOR_GRADING') return 'Ready for Grading';
  };

  const navigateToInvestigation = useCallback(
    (payload: { investigationId: String; userId: string; stepName?: string; stepOrder?: number }) => {
      history.push(`/teacher-dashboard/investigation-details/${payload.investigationId}/students/${payload.userId}`, {
        stepName: payload.stepName,
        stepOrder: payload.stepOrder,
      });
    },
    [history],
  );

  const downloadGrades = useCallback(() => {
    const headers = [
      [
        'First Name',
        'Last Name',
        'Group',
        'Engagement Score (out of 5)',
        'Raw Report Score (out of 38)',
        'Percentage Report Score',
      ],
    ];
    const csvData =
      investigationSummary?.perStudents.map((students: GQL_investigationTeacherSummary_PerStudent) => {
        return [
          students.firstName,
          students.lastName,
          students.group,
          (students?.rating / 20).toPrecision(2) || 0,
          students?.reportScore?.toFixed(2) || 0,
          students.reportAverage ? `${Math.round(students.reportAverage)}%` : 0,
        ];
      }) || [];

    downloadCsv(
      Papa.unparse([...headers, ...csvData]),
      `Students Grades for ${type} ${investigationSummary?.title}.csv`,
    );
  }, [investigationSummary, type]);

  const downloadAllMaterials = useCallback(() => {
    if (!cacheUrl) {
      startPolling(5000);
      getAllMaterials({ variables: { id: investigationId } });
      setIsPolling(true);
    } else {
      window.location.href = cacheUrl;
    }
  }, [investigationId, cacheUrl, startPolling, getAllMaterials]);

  useEffect(() => {
    if (isPolling && cacheUrl) {
      setIsPolling(false);
      stopPolling();
      // Downloads the file automatically
      window.location.href = cacheUrl;
    }
  }, [cacheUrl, isPolling, stopPolling]);

  const subscriptionPage = useCallback(() => {
    history.push('/change-subscription');
  }, [history]);

  const exportMenu = useMemo(
    () => (
      <Menu>
        {isTeacherOrFacilitator && (
          <Menu.Item
            key="2"
            onClick={() => history.push(`/teacher-dashboard/investigation-presentation/${investigationId}`)}
          >
            Presentation Mode
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => history.push(`/teacher-dashboard/investigation-details/${investigationId}`)}>
          View Submissions
        </Menu.Item>
      </Menu>
    ),
    [investigationId, history, isTeacherOrFacilitator],
  );

  const downloadMenu = useMemo(
    () => (
      <Menu>
        {isTeacherOrFacilitator && (
          <Menu.Item key="2" onClick={downloadGrades}>
            Grades
          </Menu.Item>
        )}
        <Menu.Item key="1" onClick={() => setDownloadSubmissionsVisible(true)}>
          Submissions
        </Menu.Item>
      </Menu>
    ),
    [isTeacherOrFacilitator, downloadGrades, setDownloadSubmissionsVisible],
  );

  const columns: ColumnsType<GQL_investigationTeacherSummary_PerStudent> = useMemo(() => {
    if (investigationOpen)
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          width: 275,
          fixed: 'left',
          sorter: (a: GQL_investigationTeacherSummary_PerStudent, b: GQL_investigationTeacherSummary_PerStudent) =>
            `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent, index: number) => {
            return (
              <S.TableNameContainer>
                <S.StudentInvestigationStatus status={record.status} />
                <Avatar margin="5px" size={40} backgroundColor="#FFFFFF" src={record.avatar} />
                <p>
                  {record.firstName} {record.lastName}
                </p>
              </S.TableNameContainer>
            );
          },
          filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchVisible}>
              <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilter: (value: string | number | boolean, record: GQL_investigationTeacherSummary_PerStudent) => {
            if (!value) return true;
            return (
              record.firstName.toLowerCase().includes(value.toString().toLowerCase()) ||
              record.lastName.toLowerCase().includes(value.toString().toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchVisible(visible);
            if (visible) {
              setTimeout(() => {
                if (ref && ref.current) {
                  ref.current.select();
                }
              }, 100);
            }
          },
        },
        {
          title: `${type} Status`,
          width: 175,
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => (
            <Tooltip title={`Open ${type}`} overlayInnerStyle={{ width: 'max-content' }}>
              <S.StatusChip
                status={record.status}
                onClick={() => navigateToInvestigation({ investigationId, userId: record.userId })}
              >
                {statusToText(record.status, investigationSummary?.dueDate || 0)}
              </S.StatusChip>
            </Tooltip>
          ),
        },
        ...((investigationSummary?.perStudents.length &&
          investigationSummary?.perStudents[0].steps
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((step, i) => ({
              title: step.name,
              key: i,
              width: 150,
              align: centerAlign,
              render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => {
                const recordStep = record.steps.find((s) => s.id === step.id);

                return (
                  <Tooltip
                    title={`Open ${type} from ${record.firstName.toUpperCase()} ${record.lastName.toUpperCase()} at ${step.name.toUpperCase()}`}
                    overlayInnerStyle={{ width: 'max-content' }}
                  >
                    <S.StatusChip
                      status={recordStep?.status || 'COMPLETED'}
                      onClick={() =>
                        navigateToInvestigation({
                          investigationId,
                          userId: record.userId,
                          stepName: step.name,
                          stepOrder: step.order,
                        })
                      }
                    >
                      {statusToText(
                        recordStep?.status || 'COMPLETED',
                        recordStep?.dueDate || investigationSummary?.dueDate || 0,
                      )}
                      <Spacer axis="horizontal" size={8} />
                      <FaEye />
                    </S.StatusChip>
                  </Tooltip>
                );
              },
            }))) ||
          []),
        {
          title: 'Submissions',
          width: 150,
          fixed: 'right',
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => {
            const isReadyToGrade = record.steps.slice().sort((a, b) => a.order - b.order)[record.steps.length - 1]
              .completed;

            const allowedToGrade =
              isReadyToGrade && (isTeacherOrFacilitator || (isTeacherAssistant && user.isAllowedGrading));
            return (
              <Button
                text={allowedToGrade ? 'Grade' : 'View'}
                block
                onClick={() =>
                  history.push(
                    `/teacher-dashboard/investigation-details/${investigationId}/${allowedToGrade ? 'grade/' : ''}${
                      record.userId
                    }`,
                  )
                }
              />
            );
          },
        },
      ];
    else
      return [
        {
          title: 'Name',
          dataIndex: 'name',
          width: 230,
          fixed: 'left',
          sorter: (a: GQL_investigationTeacherSummary_PerStudent, b: GQL_investigationTeacherSummary_PerStudent) =>
            `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent, index: number) => {
            return (
              <S.TableNameContainer>
                <S.StudentInvestigationStatus status={record.status} />
                <Avatar margin="5px" size={40} backgroundColor="#FFFFFF" src={record.avatar} />
                <p>
                  {record.firstName} {record.lastName}
                </p>
              </S.TableNameContainer>
            );
          },
          filterDropdown: (filterProps: FilterDropdownProps) => <TableSearchBox ref={ref} {...filterProps} />,
          filterIcon: (filtered: boolean) => (
            <S.SearchIcon $searchVisible={searchVisible}>
              <FiSearch size={16} style={{ color: filtered ? '#1890ff' : undefined }} />
            </S.SearchIcon>
          ),
          onFilter: (value: string | number | boolean, record: GQL_investigationTeacherSummary_PerStudent) => {
            if (!value) return true;
            return (
              record.firstName.toLowerCase().includes(value.toString().toLowerCase()) ||
              record.lastName.toLowerCase().includes(value.toString().toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible: boolean) => {
            setSearchVisible(visible);
            if (visible) {
              setTimeout(() => {
                if (ref && ref.current) {
                  ref.current.select();
                }
              }, 100);
            }
          },
        },
        {
          title: () => (
            <Tooltip title="The feedback score is the score given to the student by their peers.">
              <Row align="middle" gutter={[4, 0]}>
                <AiOutlineInfoCircle style={{ marginTop: -2, marginRight: 4 }} />
                <Typography.Text>Feedback Score</Typography.Text>
              </Row>
            </Tooltip>
          ),
          width: 150,
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => (
            <S.TableRating>
              {(record.feedbackGrade / 20).toPrecision(2)} / 5
              <Rate value={record.feedbackGrade / 20} disabled allowHalf />
            </S.TableRating>
          ),
        },
        {
          title: 'Engagement Score',
          width: 170,
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => (
            <S.TableRating>
              {(record.rating / 20).toPrecision(2)} / 5
              <Rate value={record.rating / 20} disabled allowHalf />
            </S.TableRating>
          ),
        },
        {
          title: 'Report Score',
          width: 150,
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => {
            return (
              <S.GradeRating grade={record.reportAverage}>
                {record.reportAverage === null
                  ? '-'
                  : `${record.reportScore?.toFixed(2) || 0} / ${record.maxReportScore?.toFixed(2) || 0}`}
              </S.GradeRating>
            );
          },
        },
        {
          title: () => (
            <Tooltip title="Any score at the 100% mark indicates the student has met performance expectations. Any score past the 100% mark indicates the student has exceeded performance expectations.">
              <Row align="middle" gutter={[4, 0]}>
                <AiOutlineInfoCircle style={{ marginTop: -2, marginRight: 4 }} />
                <Typography.Text>% Report Score</Typography.Text>
              </Row>
            </Tooltip>
          ),
          width: 150,
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => {
            return (
              <S.GradeRating grade={record.reportAverage}>
                {record.reportAverage === null ? '-' : `${Math.round(record.reportAverage)}%`}
              </S.GradeRating>
            );
          },
        },
        {
          title: 'Group',
          align: centerAlign,
          width: 130,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) =>
            record.group ? (
              <S.ClassTag $backgroundColor={groupConfig.find((group) => group.group === record.group)?.color || 'blue'}>
                Group {record.group}
              </S.ClassTag>
            ) : (
              <S.ClassTag $backgroundColor="#c4c4c4">No group assigned</S.ClassTag>
            ),
        },
        {
          title: 'Report Summary',
          width: 150,
          fixed: 'right',
          align: centerAlign,
          render: (text: string, record: GQL_investigationTeacherSummary_PerStudent) => {
            return (
              <Button
                text={record.reportAverage === null ? 'Grade Report' : 'View Report'}
                onClick={() =>
                  history.push(`/teacher-dashboard/investigation-details/${investigationId}/grade/${record.userId}`)
                }
                block
              />
            );
          },
        },
      ];
  }, [
    searchVisible,
    investigationSummary,
    investigationOpen,
    history,
    investigationId,
    navigateToInvestigation,
    type,
    isTeacherAssistant,
    isTeacherOrFacilitator,
    user.isAllowedGrading,
  ]);

  return (
    <>
      <PageWithTitle
        title={
          <S.TitleContainer>{investigationSummary ? investigationSummary.title : `${type} Settings`}</S.TitleContainer>
        }
        extra={
          isTeacherOrFacilitator ? (
            <Button
              data-cy="components-investigationsummary-settings-button"
              text={`${type} Settings`}
              style={{ float: 'right' }}
              theme={themeConfig.primaryColor}
              onClick={() => history.push(`/teacher-dashboard/investigation-summary/${investigationId}/settings`)}
            />
          ) : null
        }
        backPageUrl={classId ? `/teacher-dashboard/class/${classId}` : '/teacher-dashboard'}
      >
        <Row>
          <S.Body span={24}>
            <Progress
              strokeLinecap="round"
              strokeWidth={9}
              strokeColor={getProgressColor((investigationSummary?.completion || 0) * 100)}
              format={(percent) => {
                return (
                  <>
                    <S.Info $bold>{Math.round(percent ?? 0)}%</S.Info>
                    <S.InfoProgress>
                      Average
                      <br />
                      Progress
                    </S.InfoProgress>
                  </>
                );
              }}
              width={145}
              type="circle"
              percent={(investigationSummary?.completion || 0) * 100}
            />
            <S.InfosContainer>
              <Row gutter={24}>
                <Col span={8}>
                  <div>
                    <h3>{type} Owner</h3>
                    {loading ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <p>{`${investigationSummary?.teacherName} - ${investigationSummary?.teacherEmail}`}</p>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <h3>Number of Students</h3>
                    {loading ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <p>{investigationSummary?.perStudents?.length} students</p>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <h3>{type} Type</h3>
                    {loading ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <S.ColouredSummaryText color="#4367E9">
                        {investigationSummary?.discipline?.subject}
                      </S.ColouredSummaryText>
                    )}
                  </div>
                </Col>
              </Row>
              <S.Divider />
              <Row gutter={24}>
                <Col span={8}>
                  <div>
                    <h3>Investigation {classTitle}</h3>
                    {loading ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <S.ClassChip background={investigationSummary?.classColorHex || ''}>
                        {investigationSummary?.class}
                      </S.ClassChip>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <h3>{type} Duration</h3>
                    {loading ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <p>
                        {`${formatDateTime(investigationSummary?.startDate)} - ${formatDateTime(
                          investigationSummary?.dueDate,
                        )}`}
                      </p>
                    )}
                  </div>
                </Col>
                <Col span={8}>
                  <div>
                    <h3>{type} Status</h3>
                    {loading ? (
                      <Skeleton.Input active size="small" />
                    ) : (
                      <S.ColouredSummaryText color={investigationOpen ? '#20BC89' : '#767676'}>
                        {investigationOpen ? 'Open' : 'Closed'}
                      </S.ColouredSummaryText>
                    )}
                  </div>
                </Col>
              </Row>
            </S.InfosContainer>
          </S.Body>
          <S.ActionsContainer>
            <span role="none" >
              {(isTeacherOrFacilitator || isTeacherAssistant) && !!investigationSummary?.materials?.length && (
                <Button
                  text="Investigation Downloads"
                  theme={themeConfig.secondaryColor}
                  icon={<FiDownload />}
                  onClick={() => setOpenMaterials(true)}
                />
              )}
              {allowDownloadGrades && (
                <Dropdown overlay={downloadMenu} arrow placement="bottomRight" trigger={['click']}>
                  <Button
                    theme={themeConfig.noColor}
                    text={
                      <>
                        Student Work
                        <FiChevronDown style={{ marginLeft: 15 }} />
                      </>
                    }
                    padding="4px 5px"
                    icon={<FiDownload />}
                    block
                  />
                </Dropdown>
              )}
            </span>
            <Row>
              {isTeacherAssistant ? (
                <Button
                  text={'Presentation Mode'}
                  theme={themeConfig.secondaryOutlined}
                  onClick={() => history.push(`/teacher-dashboard/investigation-presentation/${investigationId}`)}
                />
              ) : (
                <Button
                  text={`Edit ${type}`}
                  theme={themeConfig.secondaryOutlined}
                  onClick={() =>
                    history.push(`/teacher-investigation/null/${investigationId}/settings`, {
                      backUrl: history.location.pathname,
                    })
                  }
                />
              )}

              <Spacer axis="horizontal" />
              {isTeacherAssistant ? (
                <Button
                  text={'View Submissions'}
                  theme={themeConfig.primaryColor}
                  onClick={() => history.push(`/teacher-dashboard/investigation-details/${investigationId}`)}
                />
              ) : (
                <Dropdown overlay={exportMenu} arrow placement="bottomRight" trigger={['click']}>
                  <Button
                    data-cy="components-investigationsummary-view-type"
                    text={'View ' + type}
                    suffix={<FiChevronDown style={{ marginLeft: 4, marginRight: 0 }} />}
                  />
                </Dropdown>
              )}
            </Row>
          </S.ActionsContainer>
          <Col span={24}>
            <S.TableWrapper>
              <Table
                columns={columns}
                bordered
                className="remove-horizontal-scroll"
                loading={loading}
                dataSource={investigationSummary?.perStudents}
                scroll={{ x: 'max-content' }}
                rowKey={(record) => record.userId}
                sticky
              />
            </S.TableWrapper>
          </Col>
        </Row>
      </PageWithTitle>
      {isTeacherOrFacilitator && (
        <DownloadSubmissionsModal
          setVisible={setDownloadSubmissionsVisible}
          visible={downloadSubmissionsVisible}
          investigationId={investigationId}
        />
      )}
      <Modal visible={openMaterials} onCancel={() => setOpenMaterials(false)} footer={null} width={700}>
        <S.MaterialsModalContainer>
          <h1>Investigation Downloads</h1>
          <Row>
            <Col span={12} offset={6}>
              Here you can find the downloadable materials for each stage
            </Col>
            <Col span={6}>
              <Popover
                placement="top"
                trigger={downloadAllDisabled ? 'hover' : ''}
                content={
                  <>
                    <p style={{ marginBottom: 10 }}>Upgrade your subscription to enable this feature</p>
                    <Button text="Upgrade" onClick={subscriptionPage} theme={themeConfig.secondaryColor} block />
                  </>
                }
              >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                  <Button
                    text="Download all"
                    theme={themeConfig.primaryColor}
                    icon={<FiDownload />}
                    onClick={downloadAllMaterials}
                    disabled={isPolling || downloadAllDisabled}
                  />
                </div>
              </Popover>
            </Col>
          </Row>
          {isTeacherOrFacilitator && (letterHomeUrl || standardsUrl) && (
            <>
              <Spacer />
              <Row gutter={8}>
                <Col span={9}>
                  <h2>Supporting documents</h2>
                </Col>
              </Row>
              {letterHomeUrl && (
                <Row gutter={8}>
                  <Col span={9}>
                    <S.BorderedContainer>Letter Home</S.BorderedContainer>
                  </Col>
                  <Col span={6}>
                    <S.DownloadLink href={letterHomeUrl} download target="_blank">
                      Download
                    </S.DownloadLink>
                  </Col>
                  <Spacer size={8} />
                </Row>
              )}
              <Spacer axis="vertical" size={8} />
              {standardsUrl && (
                <Row gutter={8}>
                  <Col span={9}>
                    <S.BorderedContainer>Standards Alignment</S.BorderedContainer>
                  </Col>
                  <Col span={6}>
                    <S.DownloadLink href={standardsUrl} download target="_blank">
                      Download
                    </S.DownloadLink>
                  </Col>
                  <Spacer size={8} />
                </Row>
              )}
            </>
          )}
          <Spacer />
          <Row gutter={8}>
            <Col span={6}>
              <h2>Stage Name</h2>
            </Col>
            <Col span={12}>
              <h2>Material Name</h2>
            </Col>
          </Row>
          {investigationSummary?.materials?.map((material) => {
            return (
              <Row gutter={8} key={material.stepname}>
                <Col span={6}>
                  <S.BorderedContainer>{material.stepname}</S.BorderedContainer>
                </Col>
                <Col span={12}>
                  {material.materials?.map((m) => (
                    <React.Fragment key={m.id}>
                      {m.filename.length > 37 ? (
                        <Tooltip title={m.filename}>
                          <S.BorderedContainer>
                            <p>{m.filename}</p>
                          </S.BorderedContainer>
                        </Tooltip>
                      ) : (
                        <S.BorderedContainer>
                          <p>{m.filename}</p>
                        </S.BorderedContainer>
                      )}
                      <Spacer size={8} />
                    </React.Fragment>
                  ))}
                </Col>
                <Col span={6}>
                  {material.materials?.map((m) => (
                    <React.Fragment key={m.id}>
                      <S.DownloadLink href={m.url} download target="_blank">
                        Download
                      </S.DownloadLink>
                      <Spacer size={8} />
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            );
          })}
        </S.MaterialsModalContainer>
      </Modal>
    </>
  );
};

export default withRouter(InvestigationSummary);
