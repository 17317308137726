import { useMutation } from '@apollo/client';
import { Row, message, Typography, Spin } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useState, useMemo } from 'react';
import { ClassProps, CreateClassFlowStep } from '../../types/class';
import ClassInfo from './ClassInfo';
import { gqlSchema } from '../../gql/schema';
import * as S from './styles';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { StudentDataProps } from '../../types/students';

const { Title } = Typography;

interface Props {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const initialInvite: StudentDataProps[] = [];

const GCreateClass: React.FC<Props> = (props) => {
  const { visible, setVisible } = props;

  const [loading, setLoading] = useState(false); // State for loading indicator

  const steps: CreateClassFlowStep[] = useMemo(
    () => [
      { title: 'Create a Class', step: 'classInfo' },
    ],
    [],
  );

  const [classInfo, setClassInfo] = useState<ClassProps | null>();
  const [form] = useForm<ClassProps>();
  const history = useHistory();

  const [createClass] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.AddCourse, {
    onCompleted: (data) => {
      const { addCourse } = data;

      console.log('Class created:', addCourse);

      // Execute the EditCourse mutation after AddCourse is successful
      editCourse({
        variables: {
          courseId: addCourse.id,
          data: {
            name: addCourse.name,
            courseState: 'ACTIVE',
            section: addCourse.section, 
            description: addCourse.description, 
            room: addCourse.room, 
          },
        },
      });

      history.push('/googleclassroom-teacher-dashboard/class/' + addCourse.id, {
        className: addCourse.name,
      });
      setVisible(false);
      setLoading(false); // Turn off loading indicator on success
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      message.error('Error creating class: ' + error?.message);
      setLoading(false); // Turn off loading indicator on error
    },
  });

  const [editCourse] = useMutation(gqlSchema.GoogleClassroomSchema.mutations.GCLASS.EditCourse, {
    onCompleted: (data) => {
      const { editCourse } = data;

      console.log('Course edited:', editCourse);
      message.success('Class Created successfully');
    },
    onError: (error) => {
      console.error('Edit mutation error:', error);
      message.error('Error editing class: ' + error?.message);
    },
  });

  const onFinishClassInfo = useCallback(
    (data: ClassProps) => {
      if (data) {
        setLoading(true); // Set loading indicator on form submission
        createClass({
          variables: {
            data: {
              ...data,
            },
          },
        });
      }
    },
    [createClass],
  );

  const onCloseModal = () => {
    setVisible(false);
    setClassInfo(null);
    form.resetFields();
  };

  return (
    <S.CModal
      visible={visible}
      maskClosable={false}
      destroyOnClose
      onCancel={onCloseModal}
      width={800}
      footer={null}
    >
      <Row justify="center" style={{ marginBottom: '20px', paddingTop: '20px' }}>
        <Title level={3}>{steps[0].title}</Title>
      </Row>
      <ClassInfo
        data-cy="components-classes-row-create-class-modal-step-1"
        form={form}
        initialInvite={initialInvite}
        onFinishClass={onFinishClassInfo}
        classInfo={classInfo}
      />
      {loading && ( // Conditionally render the loader
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Spin size="large" />
        </div>
      )}
    </S.CModal>
  );
};

export default React.memo(GCreateClass);
